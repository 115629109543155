import './Header.scss';
import React, { AriaAttributes, DOMAttributes, useContext } from 'react';
import { useParams } from 'react-router';
import { useHistory, useLocation } from 'react-router-dom';
import { Render } from 'types';
import { AppContext, Paths, PathsMake, PathsMatch, RouteParams } from 'App';

import { Country, SupportedCountries } from '../types/IAppContext';
import AdminContent from './AdminContent';

const Header = (): Render => {
  const context = useContext(AppContext);
  const location = useLocation();
  const routeParams = useParams<RouteParams>();
  const history = useHistory();

  function selected(path: string, altPath?: string | RegExp) {
    const props: DOMAttributes<HTMLAnchorElement> & AriaAttributes = {
      onClick(e) {
        e.preventDefault();
        history.push(path);
      },
    };

    if (location.pathname === path || (altPath && location.pathname.match(altPath))) {
      props['aria-selected'] = true;
    }

    return props;
  }

  const showLastViewed = false;

  return (
    <nav className='if block dark'>
      <img className='if logo' src='If_Logotype_Blue_RGB.svg' />
      <span className='if'>Workshop Directory</span>

      <div className='expand' />
      <div className='if tabs'>
        <ul className='if tab-list is-overflown' role='tablist'>
          <li className='if'>
            <a data-cy='searchWorkshopLink' className='if' {...selected(PathsMake.Search(), PathsMatch.Search)}>
              Search
            </a>
          </li>
          <AdminContent>
            <li className='if'>
              <a data-cy='newWorkshopLink' className='if' {...selected(Paths.NewWorkshop)}>
                New workshop
              </a>
            </li>
          </AdminContent>
          {showLastViewed && context.lastViewedWorkshop && (
            <li className='if'>
              <a className='if' {...selected('/' + context.lastViewedWorkshop)}>
                Recent workshop
              </a>
            </li>
          )}
        </ul>
      </div>
      <div className='expand' />
      <label>
        Country
        <select
          className='if dropdown-select'
          data-cy='selectCountry'
          value={context.locale.country}
          onChange={(e) => context.setCountry(e.target.value as Country)}
        >
          {SupportedCountries.map((country, i) => (
            <option value={country} key={i}>
              {country}
            </option>
          ))}
        </select>
      </label>
    </nav>
  );
};

export default Header;
