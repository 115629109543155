import { ManufacturerRelevancy, Manufacturer as VehicleInfoManufacturer } from 'api-clients/VehicleInfoApi';
import { Manufacturer, VehicleType } from 'types';

type ManufacturerCode = string;

type RelevancyCountry = 'se' | 'fi' | 'no';
type RelevancyMap = Record<RelevancyCountry, Record<VehicleType, ManufacturerCode[]>>;

export const AllManufacturersCode = 'AllManufacturersRating';
export const AllManufacturersManufacturer: Manufacturer = {
  internationalManufacturerCode: AllManufacturersCode,
  name: 'All manufacturers',
};

export abstract class ManufacturerRepo {
  private static readonly manufacturesByCode: Record<string, Manufacturer> = {};

  private static readonly relevancies: RelevancyMap = {} as RelevancyMap;
  public static initialized = false;

  public static init(manufacturers: VehicleInfoManufacturer[], relevancies: ManufacturerRelevancy[]): void {
    manufacturers.forEach((m) => {
      const manufacturer =
        ManufacturerRepo.manufacturesByCode[m.internationalManufacturerCode] ||
        ({
          internationalManufacturerCode: m.internationalManufacturerCode,
          name: m.name,
          nationalCodes: {},
        } as Manufacturer);
      manufacturer.nationalCodes![m.countryCode] = m.code;
      ManufacturerRepo.manufacturesByCode[manufacturer.internationalManufacturerCode] = manufacturer;
    });

    relevancies?.forEach((relevancy) =>
      (['se', 'fi', 'no'] as RelevancyCountry[])
        .filter((country) => relevancy[country])
        .forEach((country) => this.addRelevancy(country, relevancy.vehicleType, relevancy.manufacturerCode))
    );
    if (manufacturers?.length) {
      (window as any).ManufacturerRepo = ManufacturerRepo;
      window._workshopsadmin.perfMetrics.manufacturers = window._workshopsadmin.perfMetrics.manufacturers ?? new Date();
      ManufacturerRepo.initialized = true;
    }
  }

  public static getByCode(code: string): Manufacturer | undefined {
    if (code === AllManufacturersCode) return AllManufacturersManufacturer;

    const manufacturer = ManufacturerRepo.manufacturesByCode[code.toLowerCase()];
    if (!manufacturer) {
      console.warn('No manufacturer found for code ' + code);
    }
    return manufacturer;
  }

  public static getByName(name: string): Manufacturer | undefined {
    return Object.values(ManufacturerRepo.manufacturesByCode).find((m) => m.name.toLowerCase() === name.toLowerCase());
  }

  public static getName(thing: { internationalManufacturerCode?: string }): string {
    const code = thing.internationalManufacturerCode;
    if (code) {
      return ManufacturerRepo.getByCode(code)?.name || code;
    } else {
      return 'n/a';
    }
  }

  public static getAll(): Manufacturer[] {
    function compareByName(left: Manufacturer, right: Manufacturer) {
      return left.name.localeCompare(right.name);
    }

    const all = Object.values(ManufacturerRepo.manufacturesByCode);
    return all.sort(compareByName);
  }

  public static getExcludingCodes(codesToExclude?: string[]): Manufacturer[] {
    return ManufacturerRepo.filterByCodes(this.getAll(), codesToExclude);
  }

  public static filterByCodes(manufacturers: Manufacturer[], codes?: string[], excludeMode = true): Manufacturer[] {
    const formattedCodes = (codes ?? []).map((x) => x.toLowerCase());

    manufacturers = manufacturers.filter((m) => {
      const included = !formattedCodes.includes(m.internationalManufacturerCode.toLowerCase());
      return excludeMode ? included : !included;
    });
    return manufacturers;
  }

  public static getRelevancies(country: string, vehicleTypes: VehicleType[]): ManufacturerCode[] {
    country = country.toLowerCase();
    return vehicleTypes.reduce(
      (list, vehicleType) => list.concat(this.relevancies[country as RelevancyCountry][vehicleType]),
      [] as ManufacturerCode[]
    );
  }

  public static hasNationalCode(country: string, imc: ManufacturerCode): boolean {
    return Boolean(this.manufacturesByCode[imc]?.nationalCodes?.[country.toLocaleUpperCase()]);
  }

  public static isAllManufacturer(manufacturer: Manufacturer): boolean {
    return manufacturer.internationalManufacturerCode === AllManufacturersCode;
  }

  // noinspection JSUnusedGlobalSymbols
  public static isAllManufacturerCode(code: string): boolean {
    return code === AllManufacturersCode;
  }

  private static addRelevancy(country: RelevancyCountry, vehicleType: VehicleType, imc: ManufacturerCode) {
    const countryMap = ManufacturerRepo.relevancies[country] ?? {};
    const vehicleTypeList = countryMap[vehicleType] ?? [];
    vehicleTypeList.push(imc);
    countryMap[vehicleType] = vehicleTypeList;
    ManufacturerRepo.relevancies[country] = countryMap;
  }
}

export const ManufacturerRepoKey = 'If.WorkshopPartners.Manufacturers';
interface ManufacturerCacheType {
  manufacturers: VehicleInfoManufacturer[];
  relevancies: ManufacturerRelevancy[];
}

export function setManufacturersLocalStorage(manufactures: ManufacturerCacheType): void {
  window.localStorage.setItem(ManufacturerRepoKey, JSON.stringify(manufactures));
}

export function tryInitFromLocalStorage(): void {
  try {
    const json = window.localStorage.getItem(ManufacturerRepoKey);
    if (!json) return;
    const { relevancies, manufacturers } = JSON.parse(json) as ManufacturerCacheType;
    if (relevancies?.length && manufacturers?.length) {
      ManufacturerRepo.init(manufacturers, relevancies);
      return;
    }
  } catch (e) {
    console.warn('Could not load manufacturers from local storage');
  }
  window.localStorage.removeItem(ManufacturerRepoKey);
}
