export interface IPerformanceMetrics {
  head: Date;
  app: Date;
  manufacturers: Date;
  workshop: Date;
  report(): void;
}

window._workshopsadmin = window._workshopsadmin ?? ({ perfMetrics: {} } as any);
window._workshopsadmin.perfMetrics.report = function () {
  const perf = window._workshopsadmin.perfMetrics;
  function log(name: string, timestamp: Date) {
    if (timestamp) console.log(name, Number(timestamp) - Number(perf.head));
  }
  log('app', perf.app);
  log('manufacturers', perf.manufacturers);
  log('workshop', perf.workshop);
};
export const perfMetrics = window._workshopsadmin.perfMetrics;
