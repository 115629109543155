import React, { MouseEvent } from 'react';
import Loader from './UI/Loader';

interface Props {
  disabled?: boolean;
  loading: boolean;
  suggestions: [string, any][];
  handleOptionClick: (value: any) => void;
  suggestionsHeading?: string;
}

const TextInputFilterList = ({
  disabled,
  loading,
  suggestions,
  handleOptionClick,
  suggestionsHeading,
}: Props): JSX.Element => {
  return (
    <ul
      id='search-suggestions-list'
      className={`if list suggestions${disabled ? ' no-drop-down' : ''}`}
      data-cy='textInputFilterList'
    >
      {loading ? (
        <div className='flex-column loader-wrapper'>
          <Loader dotColor='#0054f0' loaderText='loading' />
        </div>
      ) : (
        <>
          {suggestionsHeading && <li className='if list-item suggestion heading'>{suggestionsHeading}</li>}
          {suggestions.length > 0 &&
            suggestions.map(
              (value, key) =>
                value && (
                  <li className='if list-item' key={key}>
                    <button
                      className='suggestion'
                      onClick={(e: MouseEvent) => {
                        e.preventDefault();
                        handleOptionClick(value[1]);
                      }}
                      aria-label='suggestion item'
                    >
                      {value[0]}
                    </button>
                  </li>
                )
            )}
        </>
      )}
    </ul>
  );
};

export default TextInputFilterList;
