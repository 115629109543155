import React, { useEffect, useState } from 'react';
import AdminContent from 'components/AdminContent';
import { useAppDispatch, useStateSelector } from 'store';
import { clearError, editWorkshop, setSaving } from 'store/reducers/workshop';
import { Render } from 'types';
import { useFormikContext, getIn } from 'formik';
import { useConfirmModal, Button, useTooltip } from 'components/shared';
import { useHistory } from 'react-router-dom';
import { useWorkshopField } from 'types/formik';
import { PathsMake } from 'App';
import './EditButtons.scss';
import { WorkshopSchema } from '../../types/workshopValidationSchema';
import { IdsIconSpriteDefinition } from '@ids/react-icon';

export const EditButtons = (): Render => {
  const editing = useStateSelector((state) => state.workshop.editing);
  const [{ value: id }] = useWorkshopField('id');
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { resetForm, dirty, errors, touched, values } = useFormikContext();
  const { confirmElement, confirm } = useConfirmModal();
  const [disabled, setDisabled] = useState(true);
  const workshopErrors = getIn(errors, 'workshop');
  const { tooltipElement, onTooltip } = useTooltip();
  const listIconProps = { group: 'ui', size: 24, icon: 'recipe' } as IdsIconSpriteDefinition;
  const editIconProps = { group: 'ui', size: 24, icon: 'edit' } as IdsIconSpriteDefinition;
  const backArrowIconProps = { group: 'ui', size: 32, icon: 'back-arrow' } as IdsIconSpriteDefinition;
  const checkCircleIconProps = { group: 'ui', size: 24, icon: 'check-circle' } as IdsIconSpriteDefinition;

  useEffect(() => {
    setDisabled(!dirty);
  }, [dirty]);

  function renderErrorKeys() {
    if (!workshopErrors) return '';
    return `in: ${Object.keys(workshopErrors)
      .map((e) => e.replace(/([A-Z])/g, ' $1').trim())
      .toString()
      .toLocaleLowerCase()
      .replace(/,/g, ', ')}`;
  }

  return (
    <div className='fixed-on-top-wrapper'>
      {confirmElement}
      <div className='fixed-on-top flex-column responsive-menu'>
        <Button
          type='outlined'
          cy='history'
          onClick={() => {
            history.push(PathsMake.WorkshopHistory(id));
          }}
          icon={listIconProps}
          text='History'
        />
        <AdminContent>
          {!editing && (
            <Button
              type='outlined'
              cy='edit'
              onClick={() => {
                dispatch(editWorkshop(true));
              }}
              icon={editIconProps}
              text='Edit'
            />
          )}
          {editing && (
            <Button
              type='outlined'
              text='Cancel'
              cy='cancel'
              icon={backArrowIconProps}
              onClick={() => {
                if (dirty) {
                  confirm(
                    'Reset changes?',
                    'You have edited the workshop. Are you sure you want to abandon these changes?',
                    () => {
                      resetForm();
                      dispatch(editWorkshop(false));
                    }
                  );
                } else {
                  resetForm();
                  dispatch(editWorkshop(false));
                }
              }}
            />
          )}
          {editing && (
            <>
              {workshopErrors && tooltipElement}
              <Button
                disabled={disabled}
                text='Save changes'
                cy='save'
                icon={checkCircleIconProps}
                {...onTooltip(`The form has errors ${renderErrorKeys()}`)}
                onClick={(e) => {
                  e.preventDefault();
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  WorkshopSchema.validate(values.workshop)
                    .then(() => {
                      dispatch(clearError());
                      dispatch(setSaving(true));
                    })
                    .catch((err) => console.error(err));
                }}
              />
            </>
          )}
        </AdminContent>
      </div>
    </div>
  );
};
