import React, { useRef } from 'react';
import './ContractPriority.scss';
import { useStateSelector } from 'store';
import { formatContractType, formatPriorityType } from 'util/index';
import { ContractType, PriorityType, Render } from 'types';
import classnames from 'classnames';

type HasContractPriority = { contractType: ContractType; priorityType: PriorityType; allManufacturers?: boolean };

// eslint-disable-next-line complexity
export const ContractPriority = (props: {
  show?: boolean;
  rating: HasContractPriority;
  setRating?(value: HasContractPriority): void;
}): Render => {
  const editing = useStateSelector((state) => state.workshop.editing);
  const refContractPriority = useRef<HTMLSelectElement>(null);

  if (props.show === false) {
    return null;
  }

  const rating = props.rating;
  const allOff = rating.allManufacturers === false;
  let contract = rating.contractType;
  let priority = rating.priorityType;

  if (allOff) {
    contract = ContractType.NotDecided;
    priority = PriorityType.NotPrioritized;
  }

  if (!editing || !props.setRating || allOff) {
    return (
      <>
        <div
          className={classnames('contract-type', allOff && 'no-rating-text')}
          data-color={contract}
          data-cy='contractType'
          data-cy-value={contract}
        >
          {formatContractType(contract)}
        </div>
        <div
          className={classnames('priority-type', allOff && 'no-rating-text')}
          data-color={contract}
          data-cy='priorityType'
          data-cy-value={priority}
        >
          {formatPriorityType(priority)}
        </div>
      </>
    );
  }

  const contractPriorities: Record<string, [ContractType, PriorityType]> = {};
  let selectedValue = '--';

  Object.values(ContractType).forEach((contract) =>
    Object.values(PriorityType).forEach((priority) => {
      if (contract === ContractType.NotDecided) {
        if (priority !== PriorityType.NotPrioritized) {
          return;
        }
      } else if (priority === PriorityType.NotPrioritized) {
        return;
      }
      const name = formatContractType(contract) + formatPriorityType(priority);

      contractPriorities[name] = [contract, priority];
      if (rating.contractType === contract && rating.priorityType === priority) {
        selectedValue = name;
      }
    })
  );

  return (
    <div className='contract-priority-select-wrapper'>
      <div className='contract-priority-indicator' data-contract={contract} data-priority={priority} />
      <select
        className='if select single-select contract-priority-select dropdown-select'
        ref={refContractPriority}
        value={selectedValue}
        onChange={(e) => {
          const [contractType, priorityType] = contractPriorities[e.target.value];
          props.setRating!({ ...rating, contractType, priorityType });
        }}
        data-cy='contractPrioritySelect'
        data-contract={contract}
        data-priority={priority}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {Object.keys(contractPriorities).map((v) => (
          <option key={v} value={v}>
            {v[0]}&ensp;{v[1]}
          </option>
        ))}
      </select>
    </div>
  );
};
