import { ContractPriorities, ManufacturerRepo, SearchFilters, WorkshopServiceType } from 'types';
import { AllManufacturersManufacturer } from '../../types/ManufacturerRepo';

export function parseSearchTerms(filters: SearchFilters): void {
  const consumeTerm = (term: string) => (filters.terms = filters.terms.filter((x) => x !== term));

  for (const term of [...filters.terms]) {
    const workshopServiceType = Object.values(WorkshopServiceType).find((wst) => wst.toLowerCase() === term);
    if (workshopServiceType) {
      consumeTerm(term);
      filters.termsRaw = filters.termsRaw.replace(term, '').trim();
      filters.workshopServiceTypes.push(workshopServiceType);
    }
  }
  for (const term of [...filters.terms]) {
    const consume = (search: RegExp | string) => {
      const matches = typeof search === 'string' ? term === search : term.match(search);
      if (matches) {
        consumeTerm(term);
        console.debug('consuming term', term, 'matches', search);
      }
      return matches;
    };
    if (consume(/^[a-c][+-]$/)) {
      const contractIndex = ContractPriorities.findIndex((cp) => cp[1][0] === term[0].toUpperCase());
      if (contractIndex >= 0) {
        if (term[1] === '+') {
          filters.minRatingRaw = contractIndex;
        } else {
          filters.maxRatingRaw = contractIndex - 1;
        }
      }
    }
    if (consume('bad_address')) {
      filters.badGeocodePrecision = true;
    }
    if (consume('has_address')) {
      filters.hasAddress = true;
    }
    if (consume('all')) {
      filters.manufacturers.push(AllManufacturersManufacturer);
    }
    if (consume('mixed_ratings')) {
      filters.mixedRatings = true;
    }
    if (consume('show_empty')) {
      filters.showEmpty = true;
    }
  }
}
