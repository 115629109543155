import { CachedWorkshop, Workshop, WorkshopServiceType } from 'types';
import { AnyRating, IHasLoadingAndError, VehicleType, WorkshopAddress } from './index';
import { ratingContractPriorityAsNumber } from '../util';

type FindPlaceFromQueryRequest = google.maps.places.FindPlaceFromQueryRequest;
type GeocoderRequest = google.maps.GeocoderRequest;

export abstract class WorkshopHandler {
  public static clone(workshop: Workshop): Workshop {
    //https://stackoverflow.com/questions/122102/what-is-the-most-efficient-way-to-deep-clone-an-object-in-javascript
    return <Workshop>JSON.parse(JSON.stringify(workshop));
  }
  public static addressDiffers(left: Workshop, right: Workshop): boolean {
    const leftAddress = WorkshopHandler.formatAddress(left).toLocaleLowerCase();
    const rightAddress = WorkshopHandler.formatAddress(right).toLocaleLowerCase();
    return leftAddress !== rightAddress;
  }
  public static get empty(): Workshop {
    return {} as Workshop;
  }
  public static formatAddress(workshop: Workshop): string {
    const address = [workshop.city ?? '', workshop.postalCode ?? '', workshop.street ?? ''].filter(Boolean).join(', ');
    return address || '(no address)';
  }

  public static formatAddressForGeocoding = (address: WorkshopAddress): GeocoderRequest => ({
    address: `${address.street}, ${address.postalCode} ${address.city}`,
  });

  public static formatAddressForFindPlace = (address: WorkshopAddress): FindPlaceFromQueryRequest => ({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    query: WorkshopHandler.formatAddressForGeocoding(address).address!,
    fields: ['geometry.location'],
  });

  public static addressOf(workshop: Workshop): WorkshopAddress;
  // noinspection JSUnusedGlobalSymbols
  public static addressOf(workshop: Workshop | undefined): WorkshopAddress | undefined;
  public static addressOf(workshop: Workshop | undefined): WorkshopAddress | undefined {
    if (workshop) {
      return {
        city: workshop.city,
        postalCode: workshop.postalCode,
        street: workshop.street,
      };
    }
  }

  // noinspection JSUnusedGlobalSymbols
  public static compareRatings(a: AnyRating, b: AnyRating): number {
    return ratingContractPriorityAsNumber(a) - ratingContractPriorityAsNumber(b);
  }

  public static toggleExcludeCustomerReception(list: string[] | undefined, imc: string, value: boolean): string[] {
    const exclude = [...(list ?? [])];
    const i = exclude.indexOf(imc);

    if (value) {
      if (i < 0) {
        exclude.push(imc);
      }
    } else {
      if (i >= 0) {
        exclude.splice(i, 1);
      }
    }

    return exclude;
  }
}

export interface WorkshopState extends IHasLoadingAndError {
  workshop: Readonly<CachedWorkshop> | undefined;
  editing: boolean;
  saving: boolean;
  key: number;
}

export function getWorkshopServiceVehicleTypes(type: WorkshopServiceType): VehicleType[] {
  return {
    [WorkshopServiceType.Atv]: [VehicleType.Atv],
    [WorkshopServiceType.Body]: [VehicleType.Car], //VehicleType.LightTruck same manufacturers as Car, no need of both
    [WorkshopServiceType.Camper]: [VehicleType.MobileHome],
    [WorkshopServiceType.Caravan]: [VehicleType.LightTrailer],
    [WorkshopServiceType.Glass]: [VehicleType.Car],
    [WorkshopServiceType.HeavyGlass]: [VehicleType.HeavyTruck, VehicleType.HeavyBus],
    [WorkshopServiceType.Mc]: [VehicleType.Motorcycle],
    [WorkshopServiceType.Moped]: [VehicleType.Moped],
    [WorkshopServiceType.MopedCar]: [VehicleType.MopedCar],
    [WorkshopServiceType.Service]: [VehicleType.Car],
    [WorkshopServiceType.Snowmobile]: [VehicleType.Snowmobile],
    [WorkshopServiceType.Trailer]: [VehicleType.LightTrailer],
    [WorkshopServiceType.VintageVehicle]: [VehicleType.Car], //possibly add Motorcycle later
    [WorkshopServiceType.CashPayment]: [],
    [WorkshopServiceType.SmallDentRepair]: [VehicleType.Car],
    [WorkshopServiceType.HeavyTrailer]: [VehicleType.HeavyTrailer],
    [WorkshopServiceType.TruckBody]: [VehicleType.HeavyTruck],
    [WorkshopServiceType.TruckService]: [VehicleType.HeavyTruck],
    [WorkshopServiceType.BusBody]: [VehicleType.HeavyBus],
    [WorkshopServiceType.BusService]: [VehicleType.HeavyBus],
    [WorkshopServiceType.Construction]: [VehicleType.HeavyTruck],
    [WorkshopServiceType.Agricultural]: [VehicleType.HeavyTruck],
    [WorkshopServiceType.Forestry]: [VehicleType.HeavyTruck],
    [WorkshopServiceType.AuxiliaryEquipment]: [VehicleType.HeavyTruck],
    [WorkshopServiceType.ElectricVehicle]: [VehicleType.ElectricVehicle],
    [WorkshopServiceType.Sanitation]: [VehicleType.Car, VehicleType.MopedCar],
    [WorkshopServiceType.MopedCarService]: [VehicleType.MopedCar],
  }[type];
}
