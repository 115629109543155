import React from 'react';
import { WorkshopWithFilterData } from './types';
import { Render, WorkshopServiceType } from 'types';
import { setWorkshop } from 'store/reducers/workshop';
import { WorkshopServiceIcon } from 'components/workshop/services/ServiceRating';
import { ServiceRatings } from 'components/workshop';
import { useAppDispatch } from 'store';
import { useHistory } from 'react-router-dom';
import { PathsMake } from 'App';
import { useFastSearchField } from '../../types/formik';
import { CompareWorkshopServiceType } from '../../components/workshop/services/ServiceRatings';

export function Ratings({ workshop }: { workshop: WorkshopWithFilterData }): Render {
  const hasType = (type: WorkshopServiceType) => workshop.workshopServiceRatings?.find((r) => r.serviceType === type);
  const dispatch = useAppDispatch();
  const serviceRatings = [...(workshop.workshopServiceRatings ?? [])].sort(({ serviceType: a }, { serviceType: b }) =>
    CompareWorkshopServiceType(a, b)
  );
  const [{ value: termsRaw }] = useFastSearchField('termsRaw');

  const history = useHistory();
  return (
    <div className='service-icons'>
      {serviceRatings
        .map((wsr) => wsr.serviceType)
        .filter(hasType)
        .map((type) => (
          <div
            key={type}
            className='service-icon'
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              history.push(PathsMake.WorkshopView(workshop.id, type, termsRaw));
            }}
            onAuxClick={(e) => {
              if (e.button === 1) {
                e.preventDefault();
                e.stopPropagation();
                window.open(PathsMake.WorkshopView(workshop.id, type, termsRaw), '_blank');
              }
            }}
          >
            <WorkshopServiceIcon type={type} props={{ className: 'blue' }} />
            <div className='expand'>
              <ServiceRatings ratings={serviceRatings.filter((r) => r.serviceType === type)} />
            </div>
          </div>
        ))}
    </div>
  );
}
