import { useHistory } from 'react-router-dom';
import { PathsMake } from 'App';
import { useAppDispatch } from 'store';
import { setWorkshop } from 'store/reducers/workshop';
import { Render, WorkshopHandler, GeocodePrecision, WorkshopServiceType, Workshop } from 'types';
import React, { forwardRef } from 'react';
import { Ratings } from './Ratings';
import { WorkshopWithFilterData } from './types';
import { useFastSearchField } from 'types/formik';

export function WorkshopResultInfo({ workshop }: { workshop: Workshop }): Render {
  return (
    <div className='workshop-result-info'>
      <span className='code'>{workshop.code}</span>
      <strong>{workshop.name}</strong>
      <br />
      <span>{WorkshopHandler.formatAddress(workshop)}</span>
    </div>
  );
}

type WorkshopResultProps = {
  workshop: WorkshopWithFilterData;
};

export const WorkshopResult = forwardRef<HTMLAnchorElement, WorkshopResultProps>((props: WorkshopResultProps, ref) => {
  const workshop = props.workshop;

  const history = useHistory();
  const dispatch = useAppDispatch();
  const [{ value: termsRaw }] = useFastSearchField('termsRaw');

  const url = PathsMake.WorkshopView(workshop.id, undefined, termsRaw);

  return (
    <a
      data-cy='workshopResult'
      ref={ref}
      key={workshop.id}
      className='workshop'
      href={url}
      onClick={(e) => {
        e.preventDefault();
        dispatch(setWorkshop({ ...workshop, cached: true }));
        history.push(url);
      }}
    >
      <WorkshopResultInfo workshop={workshop} />
      <Ratings workshop={workshop} />
    </a>
  );
});

WorkshopResult.displayName = 'WorkshopResult';
