import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from 'App';
import Breadcrumbs from 'components/Breadcrumbs';
import CreateWorkshopForm from 'components/Form/CreateWorkshopForm';

const NewWorkshop = (): JSX.Element | null => {
  const [created, setCreated] = useState(false);

  const [newWorkshopCode, setNewWorkshopCode] = useState('');

  const {
    locale: { country },
  } = useContext(AppContext);

  const popUpConfirmRedirect = (text: string, link: string) => {
    if (confirm(text)) {
      window.location.href = link;
    }
  };

  const breadcrumbsProps = {
    breadcrumbs: [
      {
        path: `/${country}`,
        text: 'Start',
      },
    ],
    currentBreadcrumb: `Create ${country} workshop`,
  };

  useEffect(() => {
    if (created) {
      const text = `Workshop created. Go to workshop ${newWorkshopCode}?`;
      const link = `/${country}${newWorkshopCode}`;

      popUpConfirmRedirect(text, link);
    }
  });

  return (
    <div className='if block ws-search'>
      <Breadcrumbs {...breadcrumbsProps} />
      <CreateWorkshopForm setCreated={setCreated} setNewWorkshopCode={setNewWorkshopCode} />
    </div>
  );
};

export default NewWorkshop;
