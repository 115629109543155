import './FastSearch.scss';
import { Render, Workshop } from 'types';
import { Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { AppContext, Paths, PathsMake, RouteParams } from 'App';
import Loader from 'components/UI/Loader';
import { FastSearchTutorial } from './FastSearchTutorial';
import { Terms } from './Terms';
import { Results } from './Results';
import { SearchForm } from './types';
import { CachedWorkshops, getWorkshopsFromApiAndSetCache, getWorkshopsFromCacheOrApi } from './getCachedWorkshops';
import { Country } from 'types/IAppContext';
import { decodeSearchTerms, unique } from 'util/index';
import { Icon } from '@ids/react-icon';
import { cacheIfContacts } from '../../util/users';

export const SearchData: {
  country: string | null;
  promise: Promise<CachedWorkshops> | null;
  promiseTimestamp: number | null;
} = {
  country: null,
  promise: null,
  promiseTimestamp: null,
};

// eslint-disable-next-line max-statements
export function FastSearch(): Render {
  const [helpView, setHelpView] = useState(false);

  const { terms: urlTerms } = useParams<RouteParams>();

  const history = useHistory();
  const routeParams = useParams<RouteParams>();
  const { locale, setCountry, setTitle } = useContext(AppContext);
  const countryCode = locale.country;

  useEffect(() => {
    if (routeParams.countryCode && countryCode !== routeParams.countryCode) {
      if (locale.previousCountry === routeParams.countryCode) {
        //country changed from header
        history.push(Paths.Home);
      } else {
        //validated in the regex of router
        setCountry(routeParams.countryCode as Country);
      }
    }
  }, [countryCode, routeParams]);

  const decodedUrlTerms = decodeSearchTerms(urlTerms);

  const initialValues: SearchForm = {
    countryCode: countryCode,
    workshopServiceTypes: [],
    manufacturers: [],
    termsRaw: decodedUrlTerms,
    minRating: 0,
  };

  const [workshops, setWorkshops] = useState<Workshop[]>([]);

  function handleWorkshopCacheResult(result: CachedWorkshops) {
    cacheIfContacts(
      unique(
        unique(result.workshops.map((w) => w.responsiblePersonUserId)).concat(
          unique(result.workshops.map((w) => w.surveyorUserId))
        )
      )
    ).then(() => {
      setWorkshops(result.workshops);
      SearchData.promiseTimestamp = result.timestamp;
    });
  }

  useEffect(() => {
    if (!SearchData.promise) {
      SearchData.promise = getWorkshopsFromCacheOrApi(countryCode);
    }
    if (SearchData.country != countryCode) {
      SearchData.promise = getWorkshopsFromCacheOrApi(countryCode);
      SearchData.country = countryCode;
      SearchData.promiseTimestamp = null;
    }
    SearchData.promise.then(handleWorkshopCacheResult);

    if (SearchData.promiseTimestamp != null) {
      const timeDiff = new Date().getTime() - SearchData.promiseTimestamp;
      const ageInMinutes = timeDiff / 1000 / 60;
      const isOld = ageInMinutes > 10;
      if (isOld) {
        SearchData.promiseTimestamp = new Date().getTime();
        SearchData.promise = getWorkshopsFromApiAndSetCache(countryCode);
        SearchData.promise.then(handleWorkshopCacheResult);
        SearchData.country = countryCode;
      }
    }
  }, [countryCode]);

  useEffect(() => {
    setTitle('Search');
    const cssClass = 'fast-search-body';
    document.documentElement.classList.add(cssClass);
    return () => {
      document.documentElement.classList.remove(cssClass);
    };
  }, []);

  function termUpdated(terms: string) {
    let title = 'Search';
    if (terms) {
      title = terms + ' - ' + title;
    }

    setTitle(title);

    if (terms) {
      window.history.replaceState(null, title, PathsMake.Search(countryCode, terms));
    } else {
      window.history.replaceState(null, title, Paths.Home);
    }
  }

  if (helpView) {
    return <FastSearchTutorial close={() => setHelpView(false)} />;
  }

  console.debug('Workshops used for search', { workshops });

  return (
    <Formik
      validateOnBlur={false}
      validateOnChange={false}
      initialValues={initialValues}
      onSubmit={() => {
        /*not required*/
      }}
    >
      <form className='fast-search'>
        <h2>
          Search
          <button
            className='if button text help-button'
            onClick={(e) => {
              e.preventDefault();
              setHelpView(true);
            }}
            data-cy='tutorialButton'
          >
            <Icon group='ui' size={24} icon='info' />
            Tutorial
          </button>
        </h2>
        {workshops.length ? (
          <>
            <Terms initial={decodedUrlTerms} termUpdated={termUpdated} />
            <Results workshops={workshops} />
          </>
        ) : (
          <Loader dotColor='#0054f0' loaderText='Loading workshops, please wait...' />
        )}
      </form>
    </Formik>
  );
}
