import React, { CSSProperties } from 'react';
import './Loader.scss';

interface Props {
  dotColor?: string;
  loaderText?: string;
  style?: CSSProperties;
}

const Loader = ({ dotColor, loaderText, style }: Props): JSX.Element => {
  dotColor = dotColor ?? '#faf9f7';
  return (
    <div className='loader-wrapper'>
      <svg
        className='if loader dots horizontal white'
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 64 64'
        style={style}
      >
        <g className='if svg g'>
          <circle className='if svg circle' fill={dotColor} cx='8' cy='32' r='6' />
          <circle className='if svg circle' fill={dotColor} cx='32' cy='32' r='6' />
          <circle className='if svg circle' fill={dotColor} cx='56' cy='32' r='6' />
        </g>
      </svg>
      <span>{loaderText ?? 'Searching'}</span>
    </div>
  );
};

export default Loader;
