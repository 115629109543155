import React from 'react';
import { Render } from 'types';
import { Button } from 'components/shared';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import { PathsMake, RouteParams } from 'App';
import { IdsIconSpriteDefinition } from '@ids/react-icon';

export const InfoButton = (): Render => {
  const history = useHistory();
  const { countryCode, workshopCode } = useParams<RouteParams>();
  const iconProps = { group: 'ui', size: 24, icon: 'info' } as IdsIconSpriteDefinition;

  return (
    <div className='fixed-on-top-wrapper'>
      <div className='flex-column fixed-on-top'>
        <Button
          type='outlined'
          cy='viewButton'
          onClick={() => {
            history.push(PathsMake.WorkshopView(countryCode + workshopCode));
          }}
          icon={iconProps}
          text='Details'
        />
      </div>
    </div>
  );
};
