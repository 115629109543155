/*
 * useField(key) does not validate the key, and the return type is "any"
 * since we know that useField('workshop.name') will return a field for the type of Workshop.name
 * we can create constrained useField variants that check the key ("K extends keyof" part)
 * and sets the appropriate return type (Workshop[K] and WorkshopFormState[K])
 *
 * the result is that useWorkshopField('latitude') is equivalent to
 * useField<Workshop['latitude']>('workshop.' + ('latitude' as keyof Workshop))
 */
import { FieldHelperProps, FieldInputProps, FieldMetaProps } from 'formik/dist/types';
import { ManufacturerRating, Workshop, WorkshopServiceRating } from 'api-clients/WorkshopsApi';
import { useField } from 'formik';
import { WorkshopFormState } from './index';
import { SearchForm } from 'page/Search/types';

type useFieldT<T> = [FieldInputProps<T>, FieldMetaProps<T>, FieldHelperProps<T>];
export { useField } from 'formik';
export const useWorkshopField = <K extends keyof Workshop>(key: K): useFieldT<Workshop[K]> =>
  useField('workshop.' + key);
export const useFastSearchField = <K extends keyof SearchForm>(key: K): useFieldT<SearchForm[K]> => useField(key);
export const useFormField: <K extends keyof WorkshopFormState>(key: K) => useFieldT<WorkshopFormState[K]> =
  useField as any;
export const useWorkshopServiceRatingField = (index: number): useFieldT<WorkshopServiceRating> =>
  useField<WorkshopServiceRating>(`workshop.workshopServiceRatings[${index}]`);
export const useManufacturerRatingField = (serviceIndex: number, mrIndex: number): useFieldT<ManufacturerRating> =>
  useField<ManufacturerRating>(`workshop.workshopServiceRatings[${serviceIndex}].manufacturerRatings[${mrIndex}]`);
