import {
  ApiException,
  ManufacturerRating as WorkshopManufacturerRating,
  ProblemDetails,
  ValidationProblemDetails,
  Workshop,
  WorkshopServiceRating,
  WorkshopServiceType,
  WorkshopUpdateRequest,
} from 'api-clients/WorkshopsApi';
import { SerializedError } from '@reduxjs/toolkit';

import { Leaves } from './utility';

export interface Manufacturer {
  name: string;
  internationalManufacturerCode: string;
  nationalCodes?: Record<string, string>;
}

export function noop(): void {
  void 0;
}
export const ignorePromise = noop;

export interface SearchFilters {
  showEmpty: boolean;
  terms: string[];
  termsRaw: string;
  minRatingRaw: number;
  maxRatingRaw: number;
  hasAddress: boolean;
  badGeocodePrecision: boolean;
  mixedRatings: boolean;
  manufacturers: Manufacturer[];
  workshopServiceTypes: WorkshopServiceType[];
  contact?: { type: 'groupEmail' | 'responsiblePersonUserId' | 'surveyorUserId'; email: string };
}

export interface IHasLoadingAndError {
  loading: boolean;
  error?: AnyError;
}

export type StringOrGetter = string | (() => string);

export interface ErrorWithId extends Error {
  id: string;
}
export type AnyError = SerializedError | ApiException | ProblemDetails | ValidationProblemDetails | ErrorWithId;
export type Render = JSX.Element | null;
export type WorkshopAddress = Pick<Workshop, 'street' | 'postalCode' | 'city'>;
export type WorkshopFormState = WorkshopUpdateRequest & {
  lastAddressGeocoded?: WorkshopAddress;
};
export type AnyRating = WorkshopServiceRating | WorkshopManufacturerRating;

export type WorkshopProperty = keyof Workshop | Leaves<Workshop>;

//RE-EXPORT
export type { IfContact } from './IfContact';
export { ManufacturerRepo } from './ManufacturerRepo';
export type { IAppContext } from './IAppContext';
export { WorkshopHandler } from './WorkshopHandler';
export { ContractPriorities } from './ContractPriorities';
export type { WorkshopState } from './WorkshopHandler';

export {
  GeocodePrecision,
  ContractType,
  PriorityType,
  WorkshopServiceType,
  CustomerServiceType,
} from 'api-clients/WorkshopsApi';
export type {
  Workshop,
  WorkshopServiceRating,
  ManufacturerRating,
  CustomerService,
  WorkshopUpdateRequest,
  WorkshopUpdate,
  ProblemDetails,
  ValidationProblemDetails,
  ContactPerson,
} from 'api-clients/WorkshopsApi';

// if any of these fields is set to empty ("") by the user, it should be set to null
export const WorkshopNullableFields: WorkshopProperty[] = [
  'organizationNumber',
  'phone',
  'email',
  'street',
  'postalCode',
  'city',
  'latitude',
  'longitude',
  'cabasId',
  'maxHeight',
  'responsiblePersonUserId',
  'surveyorUserId',
];

export type CachedWorkshop = Workshop & { cached?: boolean };

export { VehicleType } from 'api-clients/VehicleInfoApi';
