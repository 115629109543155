import { IfContact } from 'types';
import { getADAccessToken } from './auth';
import { environment } from './environment';

const fetchByUrl = async (url: string) => {
  const token = await getADAccessToken();
  if (token) {
    const fetchOptions = {
      headers: {
        Authorization: `Bearer ${token}`,
        ConsistencyLevel: 'eventual',
      },
    };
    return await fetch(url, fetchOptions)
      .then((response) => response.json())
      .catch((err) => console.error(':( ', err));
  }
};

const fetchUsers = async ($filter: string) => {
  const url = new URL(environment.graphUrl + '/users');

  url.search = new URLSearchParams({
    $filter,
    $select: fields,
  }).toString();

  return (await fetchByUrl(url.toString())).value
    .map((contact: IfContact) => {
      contact.displayName = contact.displayName?.trim();
      return contact;
    })
    .sort((a: IfContact, b: IfContact) => a.displayName.localeCompare(b.displayName));
};

const fields = 'displayName,givenName,surname,mail,mailNickname,mobilePhone';

export const fetchUsersByIdNameOrEmail = async (value: string): Promise<IfContact[]> => {
  const toFilter = (name: string) => `startswith(${name},'${value}')`;
  const filter = fields
    .split(',')
    .filter((f) => f !== 'mobilePhone')
    .map(toFilter)
    .join(' OR ');
  return await fetchUsers(filter);
};

const userCache: Record<string, IfContact> = {};

export const fetchIfContactIdNameOrEmail = async (value: string): Promise<IfContact> => {
  if (value in userCache) {
    return userCache[value];
  }

  return await fetchUsers(`mailNickname eq '${value}'`).then((data) => {
    const contact: IfContact = data[0];

    if (!userCache[value]) {
      userCache[value] = contact;
    }
    return contact;
  });
};

export async function cacheIfContacts(ids: string[]): Promise<void> {
  const chunk = (arr: string[], size: number) =>
    Array.from({ length: Math.ceil(arr.length / size) }, (_: any, i: number) => arr.slice(i * size, i * size + size));

  const unCachedIds = ids.filter((id) => !(id in userCache));
  const chunks = chunk(unCachedIds, 15);

  await Promise.all(
    chunks.map(async (chunk) => {
      const filterValues = chunk.map((value) => `'${value}'`).join(', ');
      const contacts = await fetchUsers(`mailNickname in (${filterValues})`);
      contacts.forEach((contact: IfContact) => {
        if (contact.mailNickname && !userCache[contact.mailNickname]) {
          userCache[contact.mailNickname] = contact;
        }
      });
    })
  );
}
export function getCachedIfContact(id: string): IfContact {
  return userCache[id];
}
