import React from 'react';
import { useField, FieldAttributes } from 'formik';

export const TextInputField = ({ sizing, ...props }: FieldAttributes<any>): JSX.Element => {
  const [field, meta] = useField(props.name);
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and alse replace ErrorMessage entirely.
  field.value = field.value ?? '';
  return (
    <div className={'if form-group' + (sizing ? ' ' + sizing : '')}>
      <label className='if label' htmlFor={props.name}>
        {props.label}
      </label>
      <input {...field} {...props} className={`if input-field ${meta.touched && meta.error ? 'is-invalid' : ''}`} />
      {meta.touched && meta.error ? <div className='if input-error'>{meta.error}</div> : null}
    </div>
  );
};
