import { combineReducers } from '@reduxjs/toolkit';
import userSlice from './user';
import workshopSlice from './workshop';
import manufacturersSlice from './manufacturers';

const rootReducer = combineReducers({
  workshop: workshopSlice,
  manufacturers: manufacturersSlice,
  user: userSlice,
});

export default rootReducer;
