import React, { useEffect, useState } from 'react';
import { Marker } from '@react-google-maps/api';

import { useStateSelector } from 'store';
import { GeocodePrecision, Render, WorkshopHandler } from 'types';
import { useFormField, useWorkshopField } from 'types/formik';

import { fromGoogleLocationTypeToGeocodePrecision, roundTo10cm } from 'util/index';
import { WorkshopField } from './shared/WorkshopField';
import WorkshopMap from 'components/workshop/WorkshopMap';
import { WorkshopSection } from './shared/WorkshopSection';
import { Button } from 'components/shared';
import classnames from 'classnames';
import AdminContent from '../AdminContent';
import { useDebounce } from 'use-debounce';
import { IdsIconSpriteDefinition } from '@ids/react-icon';

type LatLng = google.maps.LatLngLiteral;

type APIStatus = google.maps.places.PlacesServiceStatus | google.maps.GeocoderStatus;
type APIResult = google.maps.places.PlaceResult | google.maps.GeocoderResult;

const useGeocodingAPI = true;

export function WorkshopAddress(): Render {
  const workshopState = useStateSelector((state) => state.workshop);
  const editing = workshopState.editing;

  const [{ value: lat }, , { setValue: setLat }] = useWorkshopField('latitude');
  const [{ value: lng }, , { setValue: setLng }] = useWorkshopField('longitude');
  const [, , { setValue: setPrecision }] = useWorkshopField('geocodePrecision');
  const [{ value: lastAddressGeocoded }, , { setValue: setLastAddressGeocoded }] = useFormField('lastAddressGeocoded');

  const [{ value: workshop }] = useFormField('workshop');

  const [debouncedAddress] = useDebounce(WorkshopHandler.formatAddress(workshop), 600);

  const [geolocatedAddress, setGeolocatedAddress] = useState<string | undefined>();

  const Field = WorkshopField(editing);

  const iconProps = { group: 'ui', size: 24, icon: 'map-pin' } as IdsIconSpriteDefinition;

  let position: LatLng | undefined = undefined;
  if (lat && lng) {
    position = {
      lat,
      lng,
    } as LatLng;
  }

  const findLocationAction = async () => {
    const address = WorkshopHandler.addressOf(workshop);

    const setLatLong = (lat: number, long: number): void => {
      setLat(lat);
      setLng(long);
    };
    const handleResponse = (results: APIResult[] | null, status: APIStatus) => {
      console.debug({ results, status });
      const APIOKStatus = google.maps.places.PlacesServiceStatus.OK || google.maps.GeocoderStatus.OK;

      if (status !== APIOKStatus || !results || !results[0]) {
        setGeolocatedAddress(undefined);
        setLatLong(0, 0);
        return;
      }

      const geometry = results[0]?.geometry;
      const location = geometry?.location;

      if (!location || !geometry) {
        return;
      }

      setLatLong(location.lat(), location.lng());
      setLastAddressGeocoded(address);
      setGeolocatedAddress(results[0]?.formatted_address);

      if ('location_type' in geometry) {
        setPrecision(fromGoogleLocationTypeToGeocodePrecision(geometry.location_type));
      }
    };

    if (!useGeocodingAPI) {
      const service = new google.maps.places.PlacesService(document.createElement('div'));
      service.findPlaceFromQuery(WorkshopHandler.formatAddressForFindPlace(address), handleResponse);
    } else {
      new google.maps.Geocoder().geocode(WorkshopHandler.formatAddressForGeocoding(address), handleResponse);
    }
  };
  const currentAddress = WorkshopHandler.addressOf(workshop);
  const geocodedUnchanged =
    lastAddressGeocoded?.city === currentAddress.city &&
    lastAddressGeocoded?.postalCode === currentAddress.postalCode &&
    lastAddressGeocoded?.street === currentAddress.street;

  useEffect(() => {
    if (!geocodedUnchanged) {
      findLocationAction();
    }
  }, [debouncedAddress]);

  return (
    <WorkshopSection title='Address' icon={iconProps} contentClass='flex-column' anchorTag='address'>
      <div className='flex-row section-content flex-end'>
        <Field property='street' />
        <Field property='postalCode' />
        <Field property='city' />
        <AdminContent>
          <Button
            text='Find on a map'
            type='outlined'
            cy='geolocate'
            onClick={() => findLocationAction()}
            style={{ margin: '0 3em 0.9em 0.6em' }}
            disabled={
              ((!workshop.street && !workshop.postalCode && !workshop.city) || geocodedUnchanged) &&
              workshop.geocodePrecision !== GeocodePrecision.Manual
            }
            hide={!editing || workshop.geocodePrecision !== GeocodePrecision.Manual}
          />
        </AdminContent>
        <div className='flex-break' />
        <Field
          property='latitude'
          setFormatter={(e) => {
            setPrecision(GeocodePrecision.Manual);
            return Number(e.target.value);
          }}
          hide={!editing}
        />
        <Field
          property='longitude'
          setFormatter={(e) => {
            setPrecision(GeocodePrecision.Manual);
            return Number(e.target.value);
          }}
          hide={!editing}
        />
        <Field
          readonly
          property='latitude'
          value={roundTo10cm(workshop.latitude) + '; ' + roundTo10cm(workshop.longitude)}
          label='Coordinates'
          hide={editing}
        />
        <Field
          readonly
          label='Coordinates precision'
          property='geocodePrecision'
          value={[workshop.geocodePrecision, geolocatedAddress].filter(Boolean).join(', ')}
          fullWidth
        />
      </div>
      <div className={classnames('map-container', !position && 'no-position')}>
        {position ? (
          <WorkshopMap
            position={position}
            isLocationPrecise={
              workshop.geocodePrecision === GeocodePrecision.InterpolatedOrTrueRooftop ||
              workshop.geocodePrecision === GeocodePrecision.Manual
            }
          ></WorkshopMap>
        ) : (
          <>no location</>
        )}
      </div>
    </WorkshopSection>
  );
}
