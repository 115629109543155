import {
  ContractType,
  GeocodePrecision,
  ManufacturerRating,
  PriorityType,
  Workshop,
  WorkshopServiceRating,
} from 'types';
import { ContractPriorities, ManufacturerRepo, SearchFilters } from 'types';
import { WorkshopWithFilterData } from './types';

export function filterWorkshops(
  workshops: Workshop[],
  {
    manufacturers,
    maxRatingRaw,
    minRatingRaw,
    badGeocodePrecision,
    hasAddress,
    terms,
    termsRaw,
    workshopServiceTypes,
    mixedRatings,
    showEmpty,
    contact,
  }: SearchFilters,
  extraShown: number
): { filtered: Workshop[]; shownWorkshops: WorkshopWithFilterData[] } {
  const [minContractType] = ContractPriorities[minRatingRaw][1];
  const validManufacturerCodes = manufacturers
    .filter((m) => !ManufacturerRepo.isAllManufacturer(m))
    .map((m) => m.internationalManufacturerCode!);
  const allManufacturers = manufacturers.filter(ManufacturerRepo.isAllManufacturer).length > 0;

  let filtered = workshops.map((w) => ({ ...w, score: 0, normalizedName: normalizedName(w.name) }));

  function normalizedName(name: string) {
    return (
      name
        //non-letters to spaces
        .replace(/[^\p{L}]+/gu, ' ')
        //multiple spaces, tabs, etc to spaces
        .replace(/\s+/g, ' ')
        .trim()
        .toLowerCase()
    );
  }

  const normalizedTerms = normalizedName(termsRaw);

  function pushScore(amount: number, filter: (w: (typeof filtered)[number]) => boolean) {
    filtered.forEach((w) => {
      if (filter(w)) {
        w.score += amount;
      }
    });
  }

  pushScore(100, (w) => w.normalizedName.startsWith(normalizedTerms));
  pushScore(50, (w) => w.normalizedName.includes(normalizedTerms));

  // const startsWithTermsRaw = filtered
  //   .filter((w) => {
  //     if (terms.length === 0) return false;
  //     return normalizedName(w.name).startsWith(termsRaw);
  //   })
  //   .sort((a, b) => a.name.localeCompare(b.name));
  //
  // filtered = filtered.filter((w) => !startsWithTermsRaw.includes(w));
  //
  // const containsTermsRaw = filtered
  //   .filter((w) => {
  //     if (terms.length === 0) return false;
  //     return normalizedName(w.name).includes(termsRaw);
  //   })
  //   .sort((a, b) => a.name.localeCompare(b.name));
  //
  // filtered = filtered.filter((w) => !containsTermsRaw.includes(w));
  //
  // const containsAllTerms = filtered
  //   .filter((w) => {
  //     if (terms.length === 0) return true;
  //     const name = normalizedName(w.name);
  //     return terms.every((term) => name.includes(term));
  //   })
  //   .sort((a, b) => a.name.localeCompare(b.name));
  //
  // filtered = filtered.filter((w) => !containsAllTerms.includes(w));

  function matchesTerms(values: (string | undefined)[]) {
    if (terms.length === 0) return true;
    let consumableTerms = [...terms];
    const searchValues = values.filter(Boolean).map((x) => x!.toLowerCase());
    for (const term of terms) {
      for (const searchValue of searchValues) {
        if (searchValue.includes(term)) {
          consumableTerms = consumableTerms.filter((x) => x !== term);
        }
      }
    }
    return consumableTerms.length === 0;
  }
  function matchesContractPriority(rating: { contractType: ContractType; priorityType: PriorityType }) {
    let minMatches;
    let maxMatches;
    const ratingNumber = ContractPriorities.findIndex((cp) => {
      const [contract, priority] = cp[1];
      return rating.contractType === contract && rating.priorityType == priority;
    });
    if (minContractType === ContractType.NotDecided) minMatches = true;
    else {
      minMatches = ratingNumber >= minRatingRaw;
    }
    if (maxRatingRaw == ContractPriorities.length - 1) {
      maxMatches = true;
    } else {
      maxMatches = ratingNumber <= maxRatingRaw;
    }
    return minMatches && maxMatches;
  }
  function matchesManufacturerRating(rating: ManufacturerRating) {
    return validManufacturerCodes.includes(rating.internationalManufacturerCode!) && matchesContractPriority(rating);
  }
  function matchingServiceRatingType(serviceRating: WorkshopServiceRating) {
    const typeMatches = workshopServiceTypes.includes(serviceRating.serviceType);
    if (manufacturers.length > 0) {
      return typeMatches && matchesServiceRatingManufacturer(serviceRating);
    }
    return typeMatches;
  }
  function matchesServiceRatingManufacturer(rating: WorkshopServiceRating) {
    if (allManufacturers && rating.allManufacturers && matchesContractPriority(rating)) return true;
    return rating.manufacturerRatings?.some(matchesManufacturerRating);
  }

  filtered = filtered.filter((w) => matchesTerms([w.code, w.name, w.city, w.postalCode, w.street]));

  if (showEmpty) {
    filtered = filtered.filter((w) => !w.workshopServiceRatings?.length && !w.customerServices?.length);
  } else if (terms.length === 0 && !contact) {
    //don't show "empty" workshops unless searched for
    filtered = filtered.filter((w) => w.workshopServiceRatings?.length || w.customerServices?.length);
  }

  if (workshopServiceTypes.length > 0) {
    filtered = filtered.filter((w) => w.workshopServiceRatings?.some(matchingServiceRatingType));
  } else if (manufacturers.length > 0) {
    filtered = filtered.filter((w) => w.workshopServiceRatings?.some(matchesServiceRatingManufacturer));
  }

  if (badGeocodePrecision) {
    filtered = filtered.filter((w) => w.geocodePrecision !== GeocodePrecision.InterpolatedOrTrueRooftop);
  }
  if (hasAddress) {
    filtered = filtered.filter((w) => w.street);
  }
  if (mixedRatings) {
    filtered = filtered.filter((w) =>
      w.workshopServiceRatings?.some((wsr) => wsr.allManufacturers && wsr.manufacturerRatings?.length)
    );
  }

  if (contact) {
    filtered = filtered.filter((w) => w[contact.type] === contact.email);
  }

  filtered.sort((a, b) => a.normalizedName.localeCompare(b.normalizedName)).sort((a, b) => b.score - a.score);

  const shownWorkshops: WorkshopWithFilterData[] = filtered
    .slice(0, badGeocodePrecision ? filtered.length : 20 + extraShown)
    .map((w) => ({
      ...w,
      matchingServiceRatings:
        w.workshopServiceRatings?.filter(matchingServiceRatingType).map((rating) => ({
          ...rating,
          matchingManufacturerRatings: rating.manufacturerRatings?.filter(matchesManufacturerRating) ?? [],
        })) ?? [],
    }));

  return { filtered, shownWorkshops };

  /////////// FILTER EMPTY WORKSHOPS
}
