import { Render, WorkshopServiceType } from 'types';
import React, { Fragment, useEffect, useState } from 'react';
import classnames from 'classnames';
import { Icon } from '@ids/react-icon';

export function FastSearchTutorial(props: { close(): void }): Render {
  const [helpIndex, setHelpIndex] = useState(0);

  useEffect(() => {
    document.documentElement.classList.add('fast-search-help-body');

    return () => document.documentElement.classList.remove('fast-search-help-body');
  }, []);

  const helpCards: [string, JSX.Element][] = [
    [
      '01-address',
      <>
        <h3>Address</h3>
        You can search by the workshops city, street or post code. Examples: <span>riksvägen</span>,&nbsp;
        <span>oslo</span>, <span>00100</span>
        <br />
        Search is not case sensitive, <span>STOCKholm</span> and <span>stockHOLM</span> will give same results
      </>,
    ],
    [
      '02-name',
      <>
        <h3>Name</h3>
        You can search in the workshops name
        <br />
        For example <span>bilbolaget gävle</span> will find the second workshop in the screenshot
      </>,
    ],
    [
      '03-code',
      <>
        <h3>Code</h3>
        You can search by the workshops code, full or only part
      </>,
    ],
    [
      '04-manufacturer',
      <>
        <h3>Manufacturer</h3>
        Enter a manufacturer code (or multiple) to search for workshops with ratings for that manufacturer
        <br />
        Examples: &nbsp;<span>astonmartin</span> for Aston Martin, <span>burstner</span> for Bürstner,{' '}
        <span>volvo</span> for Volvo
      </>,
    ],
    [
      '05-service',
      <>
        <h3>Service</h3>
        Use <span>body</span> or <span>glass</span> for example, to search for workshops with those service ratings
        <br />
        Available:{' '}
        {Object.values(WorkshopServiceType).map((wst) => (
          <Fragment key={wst}>
            <span>{wst.toLowerCase()}</span>{' '}
          </Fragment>
        ))}
      </>,
    ],
    [
      '06-contract',
      <>
        <h3>Contract and Priority</h3>
        Use <span>a+</span> for example to search workshops that have a contract A4 or better. Enter <span>b-</span> to
        search for contracts lower than B1 (C4, C3, etc.)
        <br />
        <br />
        Note that you can combine different search terms freely
      </>,
    ],
    [
      '07-pin',
      <>
        <h3>Pins</h3>
        Zoom in on the map with <span>Ctrl + Scroll</span> and click on a pin to view the workshops information. Click
        the information bubble to go to the workshop
      </>,
    ],
  ];

  if (helpIndex >= helpCards.length) setHelpIndex(0);

  if (helpIndex < 0) setHelpIndex(helpCards.length - 1);
  return (
    <div className='fast-search'>
      <div className='search-help'>
        <div className='help-title'>
          <Icon className='if icon ui' group='ui' size={32} icon='info' />
          <h1>Search Tutorial</h1>
        </div>
        <strong>
          {helpIndex + 1} / {helpCards.length}
        </strong>
        <button className='if button text back-button' onClick={props.close}>
          <Icon group='ui' size={24} icon='arrow-sm-left' />
          Back
        </button>
        {helpCards.map(([url, text], i) => (
          <div key={url} className={classnames('card', helpIndex === i && 'on', helpIndex > i && 'past')}>
            <span className='p'>{text}</span>
            <img src={`/search-help/${url}.png`} />
          </div>
        ))}
        <div
          className='prev'
          onClick={() => {
            setHelpIndex((x) => x - 1);
          }}
        >
          <Icon className='if icon ui' group='ui' size={32} icon='arrow-sm-left' />
        </div>
        <div
          className='next'
          onClick={() => {
            setHelpIndex((x) => x + 1);
          }}
        >
          <Icon className='if icon ui' group='ui' size={32} icon='arrow-sm-right' />
        </div>
      </div>
    </div>
  );
}
