import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from 'models/User';

const initialState: User = {
  userRoles: [],
  name: '',
  username: '',
};

const userSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    setUserInfo(state, action: PayloadAction<User>): User {
      return { ...state, ...action.payload };
    },
  },
});

export const { setUserInfo } = userSlice.actions;

export default userSlice.reducer;
