import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';

import './WorkshopHistory.scss';

import { AppContext, RouteParams } from 'App';
import { useAppDispatch, useStateSelector } from 'store';

import { AnyError, WorkshopState, WorkshopUpdate } from 'types';

import { getWorkshopsApiClient } from 'util/apiClients';
import { InfoButton } from 'components/workshop/InfoButton';
import Loader from 'components/UI/Loader';
import { getDateTime } from 'util/stringValues';
import { ErrorDisplay } from '../shared';
import { useAccount, useMsal } from '@azure/msal-react';
import { AccountInfo } from '@azure/msal-browser';
import { loadWorkshop } from '../../store/reducers/workshop';

const WorkshopHistory = (): JSX.Element => {
  const context = useContext(AppContext);
  const locale = context.locale.language + '-' + context.locale.country;

  const { countryCode, workshopCode } = useParams<RouteParams>();
  const workshopId = countryCode + workshopCode;

  const [error, setError] = useState<AnyError | undefined>(undefined);

  const dispatch = useAppDispatch();

  const workshopState: WorkshopState = useStateSelector((state) => state.workshop);
  const workshop = workshopState.workshop;

  useEffect(() => {
    if (workshopState.loading) return;
    dispatch(loadWorkshop(workshopId));
  }, [workshopId]);

  const [workshopUpdates, setWorkshopUpdates] = useState<WorkshopUpdate[] | null>(null);

  const { accounts } = useMsal();
  const account = useAccount(accounts[0]);

  const fetchWorkshop = async (account: AccountInfo | null) => {
    if (!account) return;
    const client = await getWorkshopsApiClient();
    client
      .getWorkshopUpdatesByWorkshopId(workshopId)
      .then((updates) => setWorkshopUpdates(updates))
      .catch((error) => setError(error));
  };

  useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    fetchWorkshop(account);
  }, [workshopId, workshop]);

  const loader = (
    <div className='if container workshop'>
      <div className='flex-column loader-wrapper'>
        <Loader dotColor='#0054f0' loaderText='Loading' />
      </div>
    </div>
  );

  if (workshopState.loading) return loader;

  if (!workshop) {
    return <>(workshop not found)</>;
  }
  if (!workshopUpdates && !error) {
    return loader;
  }
  function formatDate(dt: Date | string | undefined) {
    const dateTime = getDateTime(dt, locale, true);

    return dateTime ? `${dateTime[0]} ${dateTime[1]}` : '';
  }

  context.setTitle(`${workshop.id} ${workshop.name} - Edit History`);

  return (
    <div className='workshop-history'>
      {error && <ErrorDisplay error={error} />}
      <h1>
        {workshop.id} {workshop.name}
      </h1>
      {workshopUpdates?.length === 0 && 'This workshop has not been updated yet.'}
      {workshopUpdates?.map((update, i) => (
        <div className='workshop-history-row' key={i} data-cy='workshopHistoryRow'>
          <div className='title'>
            <div>{formatDate(update.dateUtc)}</div>
            <div>{update.userIdentity}</div>
          </div>
          <div className='description'>{update.description}</div>
          {update.propertyDifferences && update.propertyDifferences.length > 0 && (
            <ul className='workshop-update-differences' data-cy='workshopUpdateDifference'>
              {update.propertyDifferences?.map((d, i) => (
                <li key={i}>
                  {d.propertyName} changed from {d.previousValue} to {d.newValue}
                </li>
              ))}
            </ul>
          )}
        </div>
      ))}
      <InfoButton />
    </div>
  );
};

export default WorkshopHistory;
