import React, { useContext } from 'react';
import { ApiException, ProblemDetails, ValidationProblemDetails } from 'api-clients/WorkshopsApi';
import { AnyError, Render } from 'types';
import { AppContext } from '../../App';

const isValidationProblemDetails = (
  error: AnyError | Record<string, unknown> | ValidationProblemDetails
): error is ValidationProblemDetails => {
  return typeof error === 'object' && 'errors' in error;
};

const isProblemDetails = (error: AnyError | Record<string, unknown> | ProblemDetails): error is ProblemDetails => {
  return typeof error === 'object' && 'title' in error;
};

const isApiException = (error: AnyError | Record<string, unknown> | ApiException): error is ApiException => {
  return typeof error === 'object' && 'status' in error;
};

export const ErrorDisplay = ({
  error,
}: {
  error: AnyError | Record<string, unknown> | ValidationProblemDetails | ProblemDetails | ApiException;
}): Render => {
  const defaultMessage = 'Something went wrong. Please try again.';
  console.log({ error });
  let title = defaultMessage;
  let messages: string[] = [];
  const context = useContext(AppContext);

  if (isValidationProblemDetails(error)) {
    messages = Object.values(error.errors || {}).map((x) => x.join(', ')) || [];

    title = error.title || title;
  } else if (isProblemDetails(error)) {
    title = error.title || title;
  } else if (isApiException(error)) {
    if (error.status === 204) {
      title = 'Error';
      messages = ['Workshop not found'];
      context.setTitle(messages[0]);
    } else {
      title = 'Error';
      messages = [error.message, error.response];
    }
  } else if (error) {
    if (typeof error === 'string') title = error;
  }

  return (
    <div className='error-display'>
      <div className='if alert-banner error'>
        <span className='if heading smallest'>{title}</span>
        <p>{messages && messages.map((m, i) => <p key={i}>{m}</p>)}</p>
      </div>
    </div>
  );
};
