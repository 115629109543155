import { Circle, GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import React from 'react';

const containerStyle = {
  width: '100%',
  height: '100%',
};

type InputProps = {
  position: google.maps.LatLngLiteral;
  isLocationPrecise: boolean;
};

const WorkshopMap = ({ position, isLocationPrecise }: InputProps): JSX.Element => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyBbtnce-qPEw8VxKf-SNYPC5QZdEtsDRLo',
  });

  const [, setMap] = React.useState<google.maps.Map | null>(null);

  const onLoad = React.useCallback((map: google.maps.Map) => {
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(() => {
    setMap(null);
  }, []);

  const options: google.maps.MapOptions = {
    zoom: 13,
    styles: [
      {
        featureType: 'administrative',
        elementType: 'geometry',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'poi',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'road',
        elementType: 'labels.icon',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
    ],
  };

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={{
        lat: position.lat,
        lng: position.lng,
      }}
      options={options}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      <Marker position={position} visible={isLocationPrecise}>
        {!isLocationPrecise && (
          <Circle
            center={position}
            options={{
              fillColor: 'red',
              strokeColor: 'red',
              fillOpacity: 0.1,
              strokeOpacity: 0.5,
              strokePosition: 2,
              strokeWeight: 2,
            }}
            radius={400}
          />
        )}
      </Marker>
    </GoogleMap>
  ) : (
    <></>
  );
};

export default React.memo(WorkshopMap);
