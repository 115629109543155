/* eslint-disable @typescript-eslint/no-non-null-assertion */
export const environment = {
  wsApiUrl: process.env.REACT_APP_WS_API_URL!,
  authScope: process.env.REACT_APP_AUTH_SCOPE!,
  authority: process.env.REACT_APP_AUTHORITY!,
  tenantId: process.env.REACT_APP_TENANT_ID!,
  clientId: process.env.REACT_APP_CLIENT_ID!,
  redirectUrl: process.env.REACT_APP_REDIRECT_URL!,
  graphUrl: process.env.REACT_APP_GRAPH_URL!,
  vehicleInfoApiUrl: process.env.REACT_APP_VEHICLEINFO_API_URL!,
  vehicleInfoApiScope: process.env.REACT_APP_VEHICLEINFO_API_SCOPE!,
  launchDarklyID: process.env.REACT_APP_LAUNCHDARKLY_ID!,
  appEnv: process.env.REACT_APP_ENV!,
  workshopsApiIdUri: process.env.REACT_APP_WORKSHOPS_API_ID!,
  vehicleInfoApiIdUri: process.env.REACT_APP_VEHICLE_IFO_API_ID!,
  clientSecret: process.env.REACT_APP_CLIENT_SECRET,
  testUserPassword: process.env.REACT_APP_TEST_USER_PASSWORD,
  workshopLocatorApiUrl: process.env.REACT_APP_WORKSHOPLOCATOR_API_URL!,
  workshopLocatorApiScope: process.env.REACT_APP_WORKSHOPLOCATOR_API_SCOPE!,
};
