import { configureStore } from '@reduxjs/toolkit';
import { enableMapSet } from 'immer';
import reducer from './reducers';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

enableMapSet();

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type AppDispatch = typeof store.dispatch;
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useAppDispatch = () => useDispatch<AppDispatch>();

export type RootState = ReturnType<typeof reducer>;
export const useStateSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
