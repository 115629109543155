import React, { PropsWithChildren, useEffect, useState } from 'react';
import './Authenticate.scss';
import { InteractionRequiredAuthError, InteractionType } from '@azure/msal-browser';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
  useMsalAuthentication,
} from '@azure/msal-react';
import { ignorePromise, Render } from 'types';
import { defaultRequest, getScopeAccessToken, parseRoles } from 'util/auth';
import { isCypress } from 'util/index';
import Loader from '../UI/Loader';
import { setUserInfo } from '../../store/reducers/user';
import { useDispatch } from 'react-redux';
import { loadManufacturers } from '../../store/reducers/manufacturers';
import { useStateSelector } from '../../store';
import { environment } from '../../util/environment';

// eslint-disable-next-line complexity,max-statements
function Authenticate({ children }: PropsWithChildren<any>): Render {
  const inCypressTest = isCypress();
  if (inCypressTest) return children;

  const isAuthenticated = useIsAuthenticated();
  const dispatch = useDispatch();
  const user = useStateSelector((state) => state.user);
  const manufacturers = useStateSelector((state) => state.manufacturers);
  const msal = useMsal();
  const account = msal?.accounts?.[0];

  const [workshopsTokenError, setWorkshopsTokenError] = useState<any>(null);

  // logs in if we are not logged in
  const { login, result, error } = useMsalAuthentication(InteractionType.Silent, defaultRequest);

  // logs in if our page is not logged in AND user wasn't logged into AAD at all
  useEffect(() => {
    if (error instanceof InteractionRequiredAuthError || workshopsTokenError instanceof InteractionRequiredAuthError) {
      login(InteractionType.Redirect, defaultRequest).then(ignorePromise);
    }
  }, [error]);

  const userLoaded = user.name || user.username || user.userRoles.length;

  useEffect(() => {
    if (!isAuthenticated) return;
    if (userLoaded) return;

    // we are logged in, we need to load user data
    // getWorkshopsApiAccessToken and not getADAccessToken because we need the roles
    // associated with api://workshops-api-xxx scope
    getScopeAccessToken(environment.authScope)
      .then((accessToken) => {
        dispatch(
          setUserInfo({
            name: account?.name,
            username: account?.username,
            userRoles: parseRoles(accessToken),
          })
        );
      })
      .catch(setWorkshopsTokenError);
  }, [isAuthenticated, user]);

  // we are logged in, we need to load manufacturer data
  useEffect(() => {
    if (!isAuthenticated) return;
    if (manufacturers.loading || manufacturers.repository.initialized) return;
    dispatch(loadManufacturers());
  }, [isAuthenticated, manufacturers]);

  const loading = !userLoaded || manufacturers.loading || !manufacturers.repository.initialized;

  // quite noisy, intentionally behind if(false)
  if (0) {
    console.debug('auth state', {
      'manufacturers.loading': manufacturers.loading,
      'manufacturers.repository.initialized': manufacturers.repository.initialized,
      isAuthenticated: isAuthenticated,
      'state.user': user,
      'useMsalAuthentication.error': error,
      'useMsalAuthentication.result': result,
      'msal.accounts': msal.accounts,
      'msal.inProgress': msal.inProgress,
    });
  }

  if (error) {
    console.error(error);
    return (
      <p>
        Login error: {error.errorCode}
        <br />
        <br />
        {error.errorMessage}
      </p>
    );
  }

  if (workshopsTokenError) {
    console.error({ error: workshopsTokenError });
    return <p>Login error: {workshopsTokenError.toString()}</p>;
  }

  function AuthenticatedContent() {
    if (loading) {
      return (
        <div className='if container auth-container' style={{ padding: '0 2em' }}>
          <Loader dotColor='#0054f0' loaderText='Loading manufacturers, please wait...' />
        </div>
      );
    }
    return children;
  }

  return (
    <React.StrictMode>
      <AuthenticatedTemplate>
        <AuthenticatedContent />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <div className='if container auth-container' style={{ padding: '0 2em' }}>
          <Loader dotColor='#0054f0' loaderText='Logging in, please wait...' />
        </div>
      </UnauthenticatedTemplate>
    </React.StrictMode>
  );
}

export default Authenticate;
