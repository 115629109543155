import react, { useEffect } from 'react';
import { Render } from 'types';

export const HashNavigator = (): Render => {
  useEffect(() => {
    const anchor = window.location.hash.slice(1);
    if (anchor) {
      const anchorEl = document.getElementById(anchor);
      if (anchorEl) {
        anchorEl.scrollIntoView();
      }
    }
  }, []);

  return null;
};
