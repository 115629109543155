import React, { useState } from 'react';
import { getWorkshopLocatorApiClient } from '../util/apiClients';
import { useWorkshopField } from '../types/formik';
import { GlassDeductibleResult, VehicleType } from '../api-clients/WorkshopLocatorApi';
import { useStateSelector } from '../store';
import { Render } from '../types';
import Loader from './UI/Loader';
import './DeductibleSearch.scss';

async function getDeductibleFromWorkshopLocatorApi(
  countryCode: string,
  registrationNumber: string,
  workshopId: string,
  isBranded: boolean
): Promise<GlassDeductibleResult> {
  const client = await getWorkshopLocatorApiClient();
  const result = await client.glassDeductible(
    countryCode,
    VehicleType.Car,
    registrationNumber,
    undefined, // workshop org number
    undefined, // workshop postal code
    workshopId,
    0, // base deductible
    isBranded,
    '1' // api version
  );
  if (result == null) {
    throw new Error('Empty result');
  }
  return result;
}

function parseError(e: any, registrationNumber: string) {
  let message = 'Internal error';

  if (e.status == 400) {
    message = Object.values(e.errors).join(', ');
  } else if (e.status == 404) {
    message = 'Not found: ' + registrationNumber;
    try {
      message = JSON.parse(e.response).message + ' ' + registrationNumber;
    } catch {}
  } else {
    try {
      message = e.message;
      message = JSON.parse(e.response).message;
    } catch {}
  }

  return message;
}

export function DeductibleSearch() {
  const editing = useStateSelector((state) => state.workshop.editing);
  const [{ value: countryCode }] = useWorkshopField('countryCode');

  const [show, setShow] = useState(false);

  const modalElement = <Modal show={show} close={() => setShow(false)} />;

  if (editing || countryCode !== 'FI') return null;

  return (
    <div className='deductible-search'>
      {modalElement}
      <button className='if button outlined' onClick={() => setShow(true)} data-cy='check-deductible'>
        Check deductible and invoicing
      </button>
    </div>
  );
}

function DeductibleResult({ result }: { result: GlassDeductibleResult & { regNumber?: string } }) {
  if (result?.lowerDeductible === undefined) return null;

  const boolToText = (x: boolean | undefined) => (x ? '✓ Yes' : '✕ No');

  return (
    <div className='if form-group result' data-cy='deductible-result'>
      <strong>Deductible information for {result.regNumber}:</strong>
      <span>Allowed to invoice: {boolToText(result.allowedToInvoice)}</span>
      <span>Reduced deductible: {boolToText(result.lowerDeductible)}</span>
    </div>
  );
}

function Modal(props: { show?: boolean; close: () => void }): Render {
  const [{ value: countryCode }] = useWorkshopField('countryCode');

  const [{ value: id }] = useWorkshopField('id');

  const [registrationNumber, setRegistrationNumber] = useState('');
  const [isBranded, setIsBranded] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [deductibleAnswer, setDeductibleAnswer] = useState({} as GlassDeductibleResult & { regNumber?: string });

  async function CheckDeductible() {
    setDeductibleAnswer({});
    if (!registrationNumber) {
      return;
    }
    setError('');
    setLoading(true);
    try {
      const result = await getDeductibleFromWorkshopLocatorApi(countryCode, registrationNumber, id, isBranded);
      setDeductibleAnswer({ ...result, regNumber: registrationNumber });
      setError('');
      setLoading(false);
    } catch (e) {
      setDeductibleAnswer({});
      setError(parseError(e, registrationNumber));
      setLoading(false);
      console.error(e);
    }
  }

  if (!props.show) return null;
  return (
    <>
      <div className='if backdrop is-open' />
      <div className='if deductible modal'>
        <div className='if title'>Check deductible and invoicing</div>
        <div className='if content'>
          <div className='if form-group'>
            <label className='if label' htmlFor='registrationNumber'>
              Registration number
            </label>
            <input
              placeholder='registration number'
              value={registrationNumber}
              onChange={(e) => setRegistrationNumber(e.target.value)}
              className='if input-field'
              id='registrationNumber'
              data-cy='deductible-registration-number'
            />
            <div className='if checkboxes'>
              <input
                checked={isBranded}
                onChange={() => setIsBranded(!isBranded)}
                type='checkbox'
                className='if checkbox'
              />
              <label className='if label' onClick={() => setIsBranded(!isBranded)}>
                Branded insurance
              </label>
            </div>
            <button
              className='search-button if button filled'
              onClick={CheckDeductible}
              disabled={!registrationNumber}
              data-cy='deductible-search'
            >
              Search
            </button>
            <div className='if input-error'>{error}</div>
            {loading && <Loader dotColor='#0054f0' loaderText='Searching...' />}
          </div>
          <DeductibleResult result={deductibleAnswer} />
        </div>
        <div className='if footer'>
          <button type='button' className='if button outlined' onClick={() => props.close()}>
            Close
          </button>
        </div>
      </div>
    </>
  );
}
