import React, { PropsWithChildren } from 'react';
import { Render } from 'types';
import classnames from 'classnames';
import { IdsIconSpriteDefinition } from '@ids/react-icon';
import { IconDisplay } from '../../shared/IconDisplay';

export function WorkshopSection(
  props: PropsWithChildren<{
    icon?: IdsIconSpriteDefinition;
    title: string;
    contentClass?: string;
    header?: string;
    anchorTag: string;
  }>
): Render {
  return (
    <div className='if section' data-cy={'section' + props.title.replace(' ', '')}>
      <div className='if section-content'>
        <div className='if grid no-gap'>
          <div className='if col-1 md-col-2 lg-col-2'></div>
          <div className='if col-2 md-col-8 lg-col-8'>
            {props.header && <h1>{props.header}</h1>}
            <h2 id={props.anchorTag} className='if heading small flex align-items-center'>
              {props.icon && <IconDisplay iconProp={props.icon} />}
              {props.title}
            </h2>

            <div className={classnames('section-content', props.contentClass)}>{props.children}</div>
          </div>
          <div className='if col-1 md-col-2 lg-col-2'></div>
        </div>
      </div>
    </div>
  );
}
