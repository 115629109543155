import React, { ChangeEvent, ReactNode, useRef } from 'react';
import { Field, useField } from 'formik';
import classnames from 'classnames';
import { Render, WorkshopNullableFields, WorkshopProperty } from 'types';
import { formatDate, formatLabelFromProperty } from 'util/index';
import { AdminContentWrapper } from 'components/AdminContent';
import Loader from '../../UI/Loader';
import './WorkshopField.scss';
import { Icon } from '@ids/react-icon';

type Props = {
  label?: string;
  property: WorkshopProperty;
  readonly?: boolean;
  value?: string;
  setFormatter?(e: React.ChangeEvent<any>): unknown;
  hide?: boolean;
  fullWidth?: boolean;
};

// eslint-disable-next-line complexity
function _EditField(props: Props): Render {
  const property = 'workshop.' + props.property;
  const [field, { touched, error: fieldError }, { setValue }] = useField(property);
  const hasError = touched && fieldError;
  const isCorrect = touched && !fieldError;

  if (props.hide) {
    return null;
  }

  const isDate = field.name.includes('disabledStartDate') || field.name.includes('disabledEndDate');

  let value = props.value ?? field.value ?? '';

  value = isDate === true ? formatDate(value) : value;

  const fieldRef = useRef<HTMLInputElement>(null);

  (field as any).id = 'edit-' + property;

  const openDatePicker = () => {
    const datePicker = document.getElementById('edit-' + property) as HTMLInputElement;
    datePicker.showPicker();
  };

  return (
    <div className={classnames('if form-group component', props.fullWidth && 'full-width', 'workshop-field')}>
      <label className='if label' htmlFor={'edit-' + property} data-cy-label={property}>
        {props.label ?? formatLabelFromProperty(props.property)}
        {isCorrect && <span className='if icon ui check blue' />}
      </label>

      {isDate !== true ? (
        <Field
          //https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete#browser_compatibility
          //https://bugs.chromium.org/p/chromium/issues/detail?id=587466
          // :(
          autoComplete='chrome-off'
          {...field}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            if (props.setFormatter) {
              setValue(props.setFormatter(e));
            } else {
              if (!e.target.value) {
                setValue(null);
              } else {
                field.onChange(e);
              }
            }
          }}
          value={value}
          className={classnames('if input-field', hasError && 'is-invalid')}
          data-cy={property}
          readOnly={props.readonly}
          innerRef={fieldRef}
        />
      ) : (
        <input
          type='date'
          onClick={openDatePicker}
          {...field}
          value={value}
          className={classnames('if input-field date', hasError && 'is-invalid')}
          data-cy={property}
          readOnly={props.readonly}
          onBlur={(e: ChangeEvent<HTMLInputElement>) => {
            if (e.target.value === '') setValue(null);
          }}
        />
      )}
      {fieldError && <div className='if input-error'>{fieldError}</div>}
    </div>
  );
}
const EditField = AdminContentWrapper(_EditField);

const MiniLoader = () => {
  return (
    <div className={classnames('mini-loader')}>
      <Loader dotColor='#0054f0' loaderText='' style={{ position: 'absolute', top: '-0.5rem', height: '2em' }} />
    </div>
  );
};

// eslint-disable-next-line complexity
export const IfField = (props: {
  value?: string | ReactNode;
  label: string;
  property: WorkshopProperty;
  loading?: boolean;
  onDelete?(): void;
  fullWidth?: boolean;
}): Render => {
  return (
    <div className={classnames('field component', props.fullWidth && 'full-width')}>
      <div className={classnames('if label', !props.value && 'no-content-label')}>{props.label}</div>
      <div data-cy={'workshop.' + props.property}>
        {props.onDelete && (
          <span
            style={{ cursor: 'pointer' }}
            onClick={() => {
              props.onDelete!();
            }}
          >
            <Icon className='if mr-8 size-16 close-icon' group='ui' size={16} icon='cross' />
          </span>
        )}
        {props.value || <span className='no-content-text-lighter'>none</span>}
        {props.loading && <MiniLoader />}
      </div>
    </div>
  );
};

export const ReadField = (props: Props): Render => {
  const [{ value }] = useField('workshop.' + props.property);

  const isDate = props.property.includes('disabledStartDate') || props.property.includes('disabledEndDate');

  if (props.hide) {
    return null;
  }

  let displayValue = props.value ?? value;

  displayValue = isDate === true ? formatDate(displayValue) : displayValue;

  return (
    <IfField
      value={displayValue}
      label={props.label ?? formatLabelFromProperty(props.property)}
      property={props.property}
    />
  );
};
export function WorkshopField(editing: boolean): typeof EditField | typeof ReadField {
  return editing ? EditField : ReadField;
}
