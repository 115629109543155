export const SupportedCountries = ['SE', 'FI', 'NO'] as const;
export const SupportedLanguages = ['sv', 'fi', 'nb', 'en', 'id'] as const;
export const SupportedLanguagesNames: Record<Language, string> = {
  sv: 'Swedish',
  fi: 'Finnish',
  nb: 'Norwegian',
  en: 'English',
  id: 'ID',
} as const;
export type Country = typeof SupportedCountries[number];
export type Language = typeof SupportedLanguages[number];
export interface IAppContext {
  locale: {
    country: Country;
    previousCountry?: Country;
    language: Language;
  };
  lastViewedWorkshop?: string;
  set(newContext: IAppContext | ((previous: IAppContext) => IAppContext)): void;
  setLanguage(lang: Language): void;
  setCountry(country: Country): void;
  setTitle(...subtitles: string[]): void;
}
