import React, { ReactNode } from 'react';
import { Workshop } from '../api-clients/WorkshopsApi';
import { validateWorkshops } from '../types/workshopValidationSchema';
import { useEffect, useState } from 'react';
import { getWorkshopsApiClient } from '../util/apiClients';

export const Validate = () => {
  const [validationResults, setValidationResults] = useState<Record<string, string[]>>({});
  const [workshops, setWorkshops] = useState<Workshop[]>([]);

  useEffect(() => {
    (async () => {
      const client = await getWorkshopsApiClient();
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const workshops = await client.getWorkshops();
      setWorkshops(workshops);
      setValidationResults(validateWorkshops(workshops));
    })();
  }, []);

  const results: ReactNode[] = [];

  for (const workshopId of Object.keys(validationResults)) {
    const workshop = workshops.filter((w) => w.id == workshopId)[0];
    results.push(<br />);
    results.push(
      <a href={'/' + workshopId}>
        {workshopId} {workshop.name}
      </a>
    );
    for (const err of validationResults[workshopId]) {
      results.push(<div> {err}</div>);
    }
  }
  if (!results.length) return <>Please wait...</>;
  return <>{results}</>;
};
