import React, { ReactNode, useEffect, useState } from 'react';
import { useStateSelector } from 'store';
import { fetchIfContactIdNameOrEmail, fetchUsersByIdNameOrEmail } from 'util/users';
import { Render, IfContact } from 'types';
import { useWorkshopField } from 'types/formik';
import { IfField } from './shared/WorkshopField';
import { useDebounce } from 'use-debounce';
import Loader from '../UI/Loader';
import { WorkshopSection } from './shared/WorkshopSection';
import { useAccount, useMsal } from '@azure/msal-react';
import { IdsIconSpriteDefinition } from '@ids/react-icon';

type userIdProperty = 'responsiblePersonUserId' | 'surveyorUserId';

// eslint-disable-next-line complexity
function formatGraphUser(userId: string | undefined, user?: IfContact | null): ReactNode | undefined {
  if (!userId || !user) {
    return undefined;
  }
  const displayName = user.displayName ? (
    <>
      <strong>{user.displayName}</strong> ({userId})
    </>
  ) : (
    userId
  );
  return userId ? (
    <>
      {displayName}
      {user.mobilePhone ? (
        <>
          , <a href={'tel:' + user.mobilePhone}>{user.mobilePhone}</a>
        </>
      ) : null}
      {user.mail ? (
        <>
          , <a href={'mailto:' + user.mail}>{user.mail}</a>
        </>
      ) : null}
    </>
  ) : undefined;
}

const IfContactView = (props: { userIdProperty: userIdProperty; label: string }): Render => {
  const [{ value: userId }] = useWorkshopField(props.userIdProperty);

  const [loading, setLoading] = useState(true);
  const [contact, setContact] = useState<IfContact | undefined>(undefined);

  const { accounts } = useMsal();
  const account = useAccount(accounts[0]);

  useEffect(() => {
    if (!account) return;
    if (userId) {
      fetchIfContactIdNameOrEmail(userId).then((contact) => {
        setContact(contact);
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, [userId]);

  return (
    <div className='if form-group full-width'>
      <IfField
        value={formatGraphUser(userId, contact)}
        label={props.label}
        property={props.userIdProperty}
        loading={loading}
      />
    </div>
  );
};

const IfContactEdit = (props: { userIdProperty: userIdProperty; label: string }): Render => {
  const [{ value: userId }, , { setValue: setUserId }] = useWorkshopField(props.userIdProperty);

  const [suggestionListLoading, setSuggestionListLoading] = useState(false);
  const [suggestionList, setSuggestionList] = useState<IfContact[]>([]);

  const [loading, setLoading] = useState(true);
  const [contact, setContact] = useState<IfContact | undefined | null>(undefined);

  useEffect(() => {
    if (userId === '') {
      setContact({ displayName: '<not set>' } as IfContact);
      setLoading(false);
    } else if (userId) {
      fetchIfContactIdNameOrEmail(userId).then((contact) => {
        setContact(contact);
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, [userId]);

  const [search, setSearch] = useState<string | undefined>(undefined);
  const [debouncedSearch] = useDebounce(search, 300);

  const [showSuggestionsList, setShowSuggestionsList] = useState(false);

  // eslint-disable-next-line complexity
  useEffect(() => {
    if (debouncedSearch === '') {
      setShowSuggestionsList(false);
    } else if (debouncedSearch) {
      if (debouncedSearch === contact?.displayName) {
        setShowSuggestionsList(false);
      } else if (debouncedSearch.length > 2 && debouncedSearch !== contact?.displayName) {
        setShowSuggestionsList(true);
        setSuggestionListLoading(true);
        fetchUsersByIdNameOrEmail(debouncedSearch).then((contacts) => {
          setSuggestionListLoading(false);
          setSuggestionList(contacts);
        });
      }
    }
  }, [debouncedSearch]);

  return (
    <div className='if form-group full-width'>
      <IfField
        value={formatGraphUser(userId, contact)}
        label={props.label}
        property={props.userIdProperty}
        loading={loading}
        onDelete={
          userId
            ? () => {
                setUserId(undefined);
              }
            : undefined
        }
      />
      <input
        data-cy={props.userIdProperty + 'Search'}
        className='if input-field field-value'
        value={search ?? ''}
        onChange={(e) => {
          setSearch(e.target.value);
        }}
        autoComplete='off'
        placeholder='start typing to search'
      />
      <span data-cy-only-label={props.userIdProperty + 'Search'} />
      {showSuggestionsList && (
        <ul id='search-suggestions-list' className='if list suggestions' style={{ top: '122px', maxHeight: '170px' }}>
          {suggestionListLoading && (
            <div className='flex-column loader-wrapper' style={{ marginLeft: '1em' }}>
              <Loader dotColor='#0054f0' loaderText='' />
            </div>
          )}
          <li className='if list-item'>
            <button
              className='suggestion'
              onClick={(e) => {
                e.preventDefault();
                setShowSuggestionsList(false);
                setUserId('');
                setSearch('');
              }}
            >
              &lt;not set&gt;
            </button>
          </li>
          {suggestionList?.map((contact, i) => (
            <li className='if list-item' key={i}>
              <button
                className='suggestion'
                onClick={(e) => {
                  e.preventDefault();
                  setShowSuggestionsList(false);
                  setContact(contact);
                  setUserId(contact.mailNickname);
                  setSearch('');
                }}
              >
                {contact.displayName}
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export const WorkshopIfContacts = (): Render => {
  const workshopState = useStateSelector((state) => state.workshop);
  const editing = workshopState.editing;

  const ContactComponent = editing ? IfContactEdit : IfContactView;
  const iconProps = { group: 'symbol', size: 24, icon: 'heart-person' } as IdsIconSpriteDefinition;

  return (
    <WorkshopSection title='If Contacts' icon={iconProps} contentClass='flex-row' anchorTag='IF-contacts'>
      <ContactComponent userIdProperty='responsiblePersonUserId' label='Agreement responsible' />
      <ContactComponent userIdProperty='surveyorUserId' label='Inspector' />
    </WorkshopSection>
  );
};
