import { createAsyncThunk, createSlice, PayloadAction as Action } from '@reduxjs/toolkit';
import { CachedWorkshop, WorkshopState } from 'types';
import { getWorkshopsApiClient } from 'util/apiClients';
import { WorkshopUpdateRequest } from 'api-clients/WorkshopsApi';
import { setCachedWorkshop } from '../../page/Search/getCachedWorkshops';
import { RootState } from '../index';

const getInitialState = (): WorkshopState => ({
  workshop: undefined,
  editing: false,
  loading: false,
  error: undefined,
  saving: false,
  key: 0,
});

export const loadWorkshop = createAsyncThunk(
  'workshop/loadWorkshop',
  async (workshopId: string, api) => {
    try {
      const client = await getWorkshopsApiClient();
      return await client.getById(workshopId);
    } catch (e) {
      return api.rejectWithValue(e);
    }
  },
  {
    condition(arg, api) {
      return !(api.getState() as RootState).workshop.loading;
    },
  }
);

interface DeleteWorkshopPayload {
  workshopId: string;
  redirectUrl: string;
}

export const deleteWorkshop = createAsyncThunk(
  'workshop/deleteWorkshop',
  async (payload: DeleteWorkshopPayload, api) => {
    try {
      const client = await getWorkshopsApiClient();
      await client.deleteById(payload.workshopId);
      return payload.redirectUrl;
    } catch (e) {
      return api.rejectWithValue(e);
    }
  }
);

export const updateWorkshop = createAsyncThunk(
  'workshop/updateWorkshop',
  async (payload: WorkshopUpdateRequest, api) => {
    try {
      const client = await getWorkshopsApiClient();
      await client.update(payload);
      await setCachedWorkshop(payload.workshop);
      return payload;
    } catch (e) {
      return api.rejectWithValue(e);
    }
  }
);

const workshopSlice = createSlice({
  name: 'workshop',
  initialState: getInitialState(),
  reducers: {
    editWorkshop(state, action: Action<boolean>) {
      state.editing = action.payload;
    },
    setSaving(state, action: Action<boolean>) {
      state.saving = action.payload;
    },
    setWorkshop(state, action: Action<CachedWorkshop>) {
      state.workshop = action.payload;
    },
    clearError(state) {
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      // ▬▬▬ LOAD ▬▬▬
      .addCase(loadWorkshop.pending, function () {
        return { ...getInitialState(), loading: true };
      })
      .addCase(loadWorkshop.fulfilled, (state, action) => {
        const workshop = action.payload;
        console.debug(loadWorkshop.fulfilled.type, workshop);
        if (workshop) {
          state.loading = false;
          state.workshop = workshop;
        }
      })
      .addCase(loadWorkshop.rejected, (state, action) => {
        state.loading = false;
        state.error = (action.payload as any) || action.error;
        console.debug(action);
        console.debug({ ...state });
      })
      // ▬▬▬ UPDATE ▬▬▬
      .addCase(updateWorkshop.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(updateWorkshop.fulfilled, (state, action) => {
        console.debug('updateWorkshop.fulfilled', action);
        return {
          ...state,
          loading: false,
          workshop: action.payload!.workshop,
          editing: false,
          saving: false,
          error: undefined,
          key: state.key + 1,
        };
      })
      .addCase(updateWorkshop.rejected, (state, action) => {
        state.loading = false;
        state.saving = false;
        state.error = (action.payload as any) || action.error;
        console.debug(action);
        console.debug({ ...state });
      })
      // ▬▬▬ DELETE ▬▬▬
      .addCase(deleteWorkshop.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteWorkshop.fulfilled, (state, action) => {
        if (action.payload) {
          state.loading = false;
          window.location.href = action.payload;
        }
      })
      .addCase(deleteWorkshop.rejected, (state, action) => {
        state.loading = false;
        state.error = (action.payload as any) || action.error;
      });
  },
});

export const { editWorkshop, clearError, setSaving, setWorkshop } = workshopSlice.actions;

export default workshopSlice.reducer;
