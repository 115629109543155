import React from 'react';
import { useStateSelector } from 'store';
import { Render } from 'types';
import { WorkshopField } from './shared/WorkshopField';
import { WorkshopSection } from './shared/WorkshopSection';
import { IdsIconSpriteDefinition } from '@ids/react-icon';

export function WorkshopContactPerson(): Render {
  const editing = useStateSelector((state) => state.workshop.editing);

  const Field = WorkshopField(editing);

  const iconProps = { group: 'ui', size: 24, icon: 'send-message' } as IdsIconSpriteDefinition;

  return (
    <WorkshopSection title='Contact' icon={iconProps} contentClass='flex-row' anchorTag='contact'>
      <Field property='contactPerson.email' label='E-mail' />
      <Field property='contactPerson.mobileNumber' />
      <Field property='contactPerson.phoneNumber' />
      <Field property='contactPerson.name' />
      <Field property='groupEmail' label='Group E-mail' />
    </WorkshopSection>
  );
}
