import React, { useEffect, useLayoutEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { MsalProvider } from '@azure/msal-react';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { pathToRegexp } from 'path-to-regexp';

import { IAppContext, noop } from 'types';
import { perfMetrics } from 'types/perfMetrics';
import { Country, Language, SupportedCountries, SupportedLanguages } from './types/IAppContext';
import { environment } from './util/environment';

import Header from 'components/Header';
import Authenticate from 'components/Views/Authenticate';
import NewWorkshop from 'components/Views/NewWorkshop';
import { WorkshopView } from './components/Views/WorkshopView';
import WorkshopHistory from './components/Views/WorkshopHistory';
import { FastSearch } from './page/Search/FastSearch';
import { Validate } from './page/validate';
import { encodeSearchTerms } from './util';
import { msal } from './util/auth';

perfMetrics.app = perfMetrics.app ?? new Date();

export interface RouteParams {
  countryCode: string;
  workshopCode: string;
  terms?: string;
  serviceType?: string;
}

const defaultContextValue: IAppContext = {
  locale: {
    country: 'SE',
    language: 'en',
  },
  set: noop,
  setLanguage: noop,
  setCountry: noop,
  setTitle(...subtitles: string[]) {
    //TODO: translate
    let title = 'If Workshop Directory';
    if (subtitles.length > 0) {
      title = subtitles.join(' - ') + ' - ' + title;
    }
    document.title = title;
  },
};
const AppContextKey = 'If.WorkshopPartners.AppContext';

export const AppContext = React.createContext(defaultContextValue);

export const Paths = {
  WorkshopView: `/:countryCode(${SupportedCountries.join('|')}):workshopCode(\\d+)/:serviceType?`,
  WorkshopHistory: `/:countryCode(${SupportedCountries.join('|')}):workshopCode(\\d+)/history`,
  Search: '/search/:countryCode/:terms?',
  NewWorkshop: '/new-workshop',
  Validate: '/validate',
  Home: '/',
};
type TPathsMatch = Record<keyof typeof Paths, RegExp>;
export const PathsMatch = Object.fromEntries(
  Object.entries(Paths).map(([k, v]) => [k, pathToRegexp(v)])
) as TPathsMatch;
export const PathsMake = {
  WorkshopView(id: string, serviceType?: string, searchTerm?: string): string {
    const includeSearchTerms = false;
    let url = `/${id}`;
    if (serviceType) {
      url += '/' + serviceType;
    }
    if (searchTerm && includeSearchTerms) {
      url += '?search=' + encodeSearchTerms(searchTerm);
    }
    return url;
  },
  WorkshopHistory(id: string): string {
    return `/${id}/history`;
  },
  Search(countryCode?: string, terms?: string): string {
    let url = '/';
    if (countryCode) {
      url += `search/${countryCode}`;
    }
    if (terms) {
      url += '/' + encodeSearchTerms(terms);
    }
    return url;
  },
};

export const App = (): JSX.Element => {
  const appContextDefault = { ...defaultContextValue };

  try {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const cachedAppContext: IAppContext = JSON.parse(localStorage.getItem(AppContextKey)!);
    if (SupportedCountries.includes(cachedAppContext?.locale?.country)) {
      appContextDefault.locale.country = cachedAppContext.locale.country;
    }
    if (SupportedLanguages.includes(cachedAppContext?.locale?.language)) {
      appContextDefault.locale.language = cachedAppContext.locale.language;
    }
  } catch {}

  const [appContext, _setAppContext] = useState(appContextDefault);

  useEffect(() => {
    appContext.setTitle();
  }, []);

  function setAppContext(newContext: IAppContext | ((previous: IAppContext) => IAppContext)) {
    const newContextValue = typeof newContext === 'function' ? newContext(appContext) : newContext;
    localStorage.setItem(AppContextKey, JSON.stringify(newContextValue));
    _setAppContext(newContextValue);
  }
  const setLanguage = (language: Language) => setAppContext((ctx) => ({ ...ctx, locale: { ...ctx.locale, language } }));
  const setCountry = (country: Country) => {
    setAppContext((ctx) => ({
      ...ctx,
      locale: {
        ...ctx.locale,
        country,
        previousCountry: ctx.locale.country,
      },
    }));
  };

  return (
    <>
      <MsalProvider instance={msal}>
        <AppContext.Provider value={{ ...appContext, set: setAppContext, setCountry, setLanguage }}>
          <Authenticate>
            <Router>
              <Header />
              <main className='if main'>
                <Switch>
                  <Route path={Paths.NewWorkshop}>
                    <NewWorkshop />
                  </Route>
                  <Route path={Paths.WorkshopHistory}>
                    <WorkshopHistory />
                  </Route>
                  <Route path={Paths.WorkshopView}>
                    <WorkshopView />
                  </Route>
                  <Route path={Paths.Search} strict exact>
                    <FastSearch key={appContext.locale.country} />
                  </Route>
                  <Route path={Paths.Home} strict exact>
                    <FastSearch key={appContext.locale.country} />
                  </Route>
                  <Route path={Paths.Validate} strict exact>
                    <Validate />
                  </Route>
                  <Route>Not found</Route>
                </Switch>
              </main>
            </Router>
          </Authenticate>
        </AppContext.Provider>
      </MsalProvider>
    </>
  );
};

export default withLDProvider({
  clientSideID: environment.launchDarklyID,
  reactOptions: {
    useCamelCaseFlagKeys: false,
  },
})(App);
