import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ManufacturerRepo, IHasLoadingAndError } from 'types';
import { getVehicleInfoApiClient } from 'util/apiClients';
import { setManufacturersLocalStorage, tryInitFromLocalStorage } from 'types/ManufacturerRepo';

interface ManufacturersState extends IHasLoadingAndError {
  repository: typeof ManufacturerRepo;
}

const getInitialState = (): ManufacturersState => ({
  repository: ManufacturerRepo,
  loading: false,
  error: undefined,
});

export const loadManufacturers = createAsyncThunk('manufacturers/loadManufacturers', async (_, api) => {
  tryInitFromLocalStorage();
  const client = await getVehicleInfoApiClient();
  try {
    const manufacturers = await client.getAll();
    const relevancies = await client.getAllRelevancies();
    return { manufacturers, relevancies };
  } catch (e) {
    return api.rejectWithValue(e);
  }
});

const manufacturerSlice = createSlice({
  name: 'manufacturer',
  initialState: getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadManufacturers.pending, (state) => {
        return {
          ...state,
          //don't wait for loading if it already was initialized
          loading: !state.repository.initialized,
        };
      })
      .addCase(loadManufacturers.fulfilled, (state, action) => {
        const { manufacturers, relevancies } = action.payload;
        if (manufacturers?.length && relevancies?.length) {
          setManufacturersLocalStorage(action.payload);
          state.repository.init(manufacturers, relevancies);
        }
        state.loading = false;
      })
      .addCase(loadManufacturers.rejected, (state, action) => {
        state.loading = false;
        state.error = (action.payload as any) || action.error;
        console.debug(action);
        console.debug({ ...state });
      });
  },
});

export default manufacturerSlice.reducer;
