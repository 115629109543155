import React from 'react';
import './SaveSummary.scss';
import { useFormikContext } from 'formik';
import { Render, WorkshopFormState, WorkshopProperty } from 'types';
import classnames from 'classnames';
import { deepGet, formatLabelFromProperty } from 'util/index';

export function SaveSummary(): Render {
  const formik = useFormikContext<WorkshopFormState>();

  const values = formik.values.workshop ?? {};
  const initial = formik.initialValues.workshop ?? {};

  function sameId(a: { id: string }, b: { id: string }) {
    if (!a.id || !b.id) return false;
    return a.id === b.id;
  }

  function stringify(value: any, prop: WorkshopProperty): Render | string {
    if (value === null) return '<empty>';
    if (typeof value === 'string') {
      return value;
    }
    if (value === false) return 'No';
    if (value === true) return 'Yes';
    if (Array.isArray(value) && value.length === 0) return '<none>';
    if (Array.isArray(value)) {
      const initialSet: [] = deepGet(initial, prop.toString()) ?? [];
      return (
        <table className='if table'>
          <tbody>
            {value.map((v, i) => {
              if (initialSet.find((x) => sameId(v, x))) return null;
              return (
                <tr key={i}>
                  <td className='if'>{stringify(v, (prop + `[${i}]`) as WorkshopProperty)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      );
    }
    if (typeof value === 'object') {
      const initialObject: Record<string, any> = deepGet(initial, prop.toString());
      return <table className='if table'>{objectToTable(value, initialObject)}</table>;
    }
    return JSON.stringify(value);
  }

  function objectToTable(newObject: any, oldObject: any) {
    return (
      <tbody>
        {Object.keys(newObject).map((key) => {
          const newValue = (newObject as any)[key];
          const oldValue = oldObject && (oldObject as any)[key];
          const differ = different(oldValue, newValue);
          console.debug(key, oldValue, newValue, differ);
          if (!differ) return null;
          return (
            <tr key={key}>
              <td className='if'>{formatLabelFromProperty(key)}</td>
              {oldObject && (
                <td className={classnames('if', oldValue === null && 'null')}>
                  {stringify(oldValue, key as WorkshopProperty)}
                </td>
              )}
              <td className={classnames('if', newValue === null && 'null')}>
                {stringify(newValue, key as WorkshopProperty)}
              </td>
            </tr>
          );
        })}
      </tbody>
    );
  }

  function different(a: any, b: any) {
    return !Object.is(a, b);
  }

  return (
    <div className='summary'>
      <h3 className='if heading small'>Summary</h3>
      <div className='summary-container'>
        <div className='summary-wrapper'>
          <table className='if table'>
            <thead>
              <tr>
                <th className='if'>Field</th>
                <th className='if'>Old</th>
                <th className='if'>New</th>
              </tr>
            </thead>
            {objectToTable(values, initial)}
          </table>
        </div>
      </div>
    </div>
  );
}
