export const getValue = (input: string | undefined): string | undefined => {
  return input && input.length > 0 ? input : undefined;
};

/**
 * Gets a string containing a date and a time without milliseconds.
 * @param date
 * @param locale The locale to use for converting the date to a locale string.
 * @param isUtc If the input is a UTC date.
 */
export const getDateTime = (date: string | Date | undefined, locale: string, isUtc: boolean): string[] | undefined => {
  if (!date) {
    return;
  }
  let dateString = date.toString();

  if (isUtc) {
    dateString += 'Z';
  }

  date = new Date(dateString);
  const dateTime = date.toLocaleString(locale).split(' ');
  dateTime[1] = dateTime[dateTime.length - 1];

  if (locale === 'sv-SE') {
    dateTime[1] = dateTime[dateTime.length - 1].split('.')[0];
  }

  return dateTime;
};
