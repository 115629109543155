import './ResultsMap.scss';
import { Render, Workshop } from 'types';
import { GoogleMap, InfoBox, Marker, MarkerClusterer } from '@react-google-maps/api';
import { Clusterer, ClusterIconStyle } from '@react-google-maps/marker-clusterer';
import React, { useState } from 'react';
import { WorkshopResultInfo } from './WorkshopResult';
import { lerp } from '../../util';
export function MarkerWithInfo({
  clusterer,
  workshop,
  setInfoBoxId,
  infoBoxId,
}: {
  clusterer: Clusterer;
  workshop: Workshop;
  setInfoBoxId(id: string): void;
  infoBoxId: string;
}): Render {
  return (
    <Marker
      onClick={() => {
        setInfoBoxId(workshop.id);
      }}
      position={{ lat: workshop.latitude!, lng: workshop.longitude! }}
      clusterer={clusterer}
    >
      {infoBoxId === workshop.id && (
        <InfoBox
          onCloseClick={() => setInfoBoxId('')}
          options={{
            enableEventPropagation: true,
            closeBoxURL: '#',
            boxStyle: {
              transform: '',
            },
          }}
        >
          <WorkshopResultInfo workshop={workshop} />
        </InfoBox>
      )}
    </Marker>
  );
}

export function Markers({ clusterer, workshops }: { clusterer: Clusterer; workshops: Workshop[] }): Render {
  const [infoBoxId, setInfoBoxId] = useState<string>('');
  return (
    <>
      {workshops.map((w) => (
        <MarkerWithInfo
          key={w.id}
          clusterer={clusterer}
          workshop={w}
          infoBoxId={infoBoxId}
          setInfoBoxId={setInfoBoxId}
        />
      ))}
    </>
  );
}
export function ResultsMap({ workshops }: { workshops: Workshop[] }): Render {
  const workshopsWithLocation = workshops.filter((w) => w.longitude && w.latitude);
  const nordicCenter = { lat: 63, lng: 20.14 };
  const clusterOptions = {
    maxSize: 80,
    minSize: 30,
    minTextSize: 18,
    maxTextSize: 22,
    sizes: 4,
  };
  const clusterStyles: ClusterIconStyle[] = Array.from({ length: clusterOptions.sizes + 1 }, (_, i) => {
    const amount = i / clusterOptions.sizes;
    const size = lerp(clusterOptions.minSize, clusterOptions.maxSize, amount);
    const textSize = lerp(clusterOptions.minTextSize, clusterOptions.maxTextSize, amount);
    return {
      url: '',
      height: size,
      width: size,
      fontStyle: 'unset',
      fontFamily: '"If Sans"',
      fontWeight: 'normal',
      textColor: 'rgb(51, 30, 17)',
      textSize,
      className: 'map-cluster-' + i,
    };
  });

  return (
    <GoogleMap mapContainerStyle={{ height: '24em', marginTop: '0.5em' }} zoom={3.8} center={nordicCenter}>
      <MarkerClusterer options={{}} clusterClass='map-cluster' styles={clusterStyles}>
        {(c) => <Markers workshops={workshopsWithLocation} clusterer={c} />}
      </MarkerClusterer>
    </GoogleMap>
  );
}
