import { Render } from 'types';
import { useFastSearchField } from 'types/formik';
import React, { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';

export function Terms(props: { initial: string; termUpdated(terms: string): void }): Render {
  const [{ value: termsRaw }, , { setValue: setTerms }] = useFastSearchField('termsRaw');
  const [liveText, setLiveText] = useState(props.initial);

  const [debouncedText] = useDebounce(liveText, 400);

  useEffect(() => {
    if (termsRaw !== debouncedText) {
      console.debug('setTerms', termsRaw, debouncedText);
      props.termUpdated(debouncedText.trim());
      setTerms(debouncedText);
    }
  }, [debouncedText]);

  return (
    <div className='if form-group terms'>
      <input
        onChange={(e) => setLiveText(e.target.value)}
        value={liveText}
        className='if input-field'
        data-cy='termsRaw'
        placeholder='search in name or address'
        onKeyDownCapture={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}
      />
    </div>
  );
}
