import React, { Dispatch, SetStateAction, ChangeEvent, useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router';
import { Workshop } from 'api-clients/WorkshopsApi';
import InputError from '../../InputError';
import { AppContext, RouteParams } from 'App';
import { TextInputField } from '../TextInputField';
import TextInputFilterList from '../../TextInputFilterList';
import { getWorkshopsFromCacheOrApi } from '../../../page/Search/getCachedWorkshops';

interface Props {
  code: string;
  setCode: Dispatch<SetStateAction<string>>;
  setCodeValid: Dispatch<SetStateAction<boolean>>;
}

// eslint-disable-next-line complexity,max-statements
const CodeField = ({ code, setCode, setCodeValid }: Props): JSX.Element => {
  const params = useParams<RouteParams>();
  const {
    locale: { country: contextCountry },
  } = useContext(AppContext);

  const country = params.countryCode || contextCountry;

  const [available, setAvailable] = useState(true);

  const [suggestedCodes, setSuggestedCodes] = useState<string[]>([]);

  const [showSuggestedCodes, setShowSuggestedCodes] = useState(false);

  const [workshopsByCountry, setWorkshopsByCountry] = useState<Workshop[]>([]);

  const [loading, setLoading] = useState(false);

  const isFinland = country.toUpperCase() === 'FI';

  const isNorway = country.toUpperCase() === 'NO';

  const disabled = isFinland;

  const codeFieldName = 'code';

  let run = false;

  let minAmountOfDigits = 0;

  let maxAmountOfDigits = 0;

  switch (country.toUpperCase()) {
    case 'NO':
      minAmountOfDigits = 3;
      maxAmountOfDigits = 7;
      break;
    case 'FI':
      minAmountOfDigits = 2;
      maxAmountOfDigits = 4;
      break;
    case 'SE':
      minAmountOfDigits = 3;
      maxAmountOfDigits = 6;
      break;
  }

  const workshopExist = (data: Workshop | undefined) => {
    if (data) {
      setAvailable(false);

      return true;
    } else {
      setAvailable(true);
    }

    return false;
  };

  const generateFinlandCode = (data: Workshop[]) => {
    let wsCode: string;

    const usedCodes = data.map((workshop) => workshop.code).sort();

    const lastIndex = usedCodes.length - 1;

    wsCode = String(Number(usedCodes[lastIndex]) + 1);

    const unitsCalculation = Math.pow(10, maxAmountOfDigits - wsCode.length);

    const zeros = String(unitsCalculation).slice(1);

    wsCode = zeros + wsCode;

    setCode(wsCode);
    setShowSuggestedCodes(false);
  };

  const createSuggestions = (data: Workshop[], userInput: string) => {
    const unitsCalculation = Math.pow(10, maxAmountOfDigits - userInput.length);

    const setMinSuggestedValue = (suggestion: number) => {
      let zeros = '';

      if (String(suggestion).length < maxAmountOfDigits) {
        zeros = String(unitsCalculation).slice(1);
      }

      return Number(zeros + suggestion + zeros);
    };
    const setMaxSuggestedValues = (suggestion: number) => {
      let nines = 0;
      let maxSuggestion = suggestion;

      if (String(userInput).length < maxAmountOfDigits) {
        nines = unitsCalculation - 1;

        maxSuggestion = Number(String(suggestion) + String(nines));
      }

      return maxSuggestion;
    };

    const usedWorkshops = data
      .map((workshop) => workshop.code)
      .sort()
      .filter((code) => code.indexOf(userInput) > -1);

    const createSuggestionsFromStringValue = () => {
      const suggestion = userInput;

      let minSuggested = setMinSuggestedValue(Number(suggestion));

      const maxSuggested = setMaxSuggestedValues(Number(suggestion));

      const stringSuggestions: string[] = [];

      while (minSuggested <= maxSuggested) {
        let suggestion = String(minSuggested);

        if (String(minSuggested).length < maxAmountOfDigits) {
          const zeros = String(Math.pow(10, maxAmountOfDigits - String(minSuggested).length)).slice(1);

          suggestion = zeros + suggestion;
        }

        if (usedWorkshops.indexOf(String(suggestion)) < 0) {
          stringSuggestions.push(suggestion);
        }

        minSuggested++;
      }

      return stringSuggestions;
    };

    return createSuggestionsFromStringValue();
  };

  function filterWorkshopsByCountry(workshops: Workshop[]) {
    if (!workshops || workshops.length < 1) return [] as Workshop[];
    const filtered = workshops.filter((w) => w.countryCode == country);
    setWorkshopsByCountry(filtered);
  }

  const getWorkshopById = (id: string): Workshop | undefined => workshopsByCountry.filter((w) => w.id === id)[0];

  const handleFocus = () => {
    if (suggestedCodes.length > 0) {
      setShowSuggestedCodes(true);
    }
  };

  const handleBlur = (event: FocusEvent) => {
    const target = event.target as HTMLInputElement;

    if (params.workshopCode && params.workshopCode === target.value) {
      setAvailable(true);
      return;
    }

    const id = `${country.toUpperCase()}${target.value}`;

    workshopExist(getWorkshopById(id));
  };

  // eslint-disable-next-line max-statements
  const handleChange = async (event: ChangeEvent) => {
    const target = event.target as HTMLInputElement;

    const userInput = target.value;

    setCode(userInput);

    setAvailable(true);

    // eslint-disable-next-line complexity
    const processWorkshopsByCountry = (processedWorkshops: Workshop[]) => {
      run = userInput === target.value && userInput.length > minAmountOfDigits;

      if (run) {
        run = false;

        const availableCodes = createSuggestions(processedWorkshops, userInput);

        if (availableCodes.length > 3) {
          setSuggestedCodes(availableCodes.slice(0, 3));
        } else if (availableCodes.length > 0) {
          setSuggestedCodes(availableCodes);
        } else if (availableCodes.length === 0) {
          setSuggestedCodes([]);
          setShowSuggestedCodes(false);
          setAvailable(false);
        }
      }
    };

    if (userInput.length <= minAmountOfDigits) {
      setSuggestedCodes([]);
      setShowSuggestedCodes(false);

      run = false;
    } else if (userInput.length > minAmountOfDigits) {
      if (userInput.length < maxAmountOfDigits) {
        setShowSuggestedCodes(true);

        run = true;

        processWorkshopsByCountry(workshopsByCountry);
      } else {
        const id = `${country.toUpperCase()}${userInput}`;

        run = false;

        setSuggestedCodes([]);

        setShowSuggestedCodes(true);

        const exist = workshopExist(getWorkshopById(id));
        if (!exist) {
          setSuggestedCodes([target.value]);
        } else {
          setShowSuggestedCodes(false);
        }
      }
    }
  };

  const handleOptionClick = (value: string) => {
    setShowSuggestedCodes(false);
    setCode(value);
    setSuggestedCodes([]);
    setAvailable(true);
  };

  const checkIsInputClick = (target: HTMLElement) =>
    target.tagName === 'INPUT' && target.getAttribute('name') === codeFieldName;

  const checkIsListItemClick = (target: HTMLElement) => target.tagName !== 'LI';

  // eslint-disable-next-line complexity
  const showHideSuggestedCodesOnClick = (e: MouseEvent) => {
    const target = e.target as HTMLElement;
    const isInputClick = checkIsInputClick(target);
    const suggestionsExist = suggestedCodes.length > 0;
    const showSuggestions = code.length > minAmountOfDigits;
    const isClickOnList = checkIsListItemClick(target);

    if (isInputClick && suggestionsExist && showSuggestions) {
      setShowSuggestedCodes(true);
    } else if (isClickOnList && suggestionsExist) {
      setShowSuggestedCodes(false);
    }
  };

  const handleWindowClick = (ev: MouseEvent) => {
    showHideSuggestedCodesOnClick(ev);
  };

  const isAllNumbersWithLength = (input: string, length: number) => {
    return !isNaN(Number(input)) && input.length == length;
  };

  function validateCode() {
    if (isNorway) {
      if (!isAllNumbersWithLength(code, 7)) {
        setCodeValid(false);
      } else {
        setCodeValid(true);
      }
    }
  }

  useEffect(() => {
    window.addEventListener('click', handleWindowClick);

    if (loading && suggestedCodes.length > 0) {
      setLoading(false);
    }

    validateCode();

    return () => {
      window.removeEventListener('click', handleWindowClick);
    };
  });

  useEffect(() => {
    setCode('');
    setLoading(true);
    if (isFinland) {
      setShowSuggestedCodes(true);
    }
    getWorkshopsFromCacheOrApi(contextCountry).then((w) => filterWorkshopsByCountry(w.workshops));
  }, [contextCountry]);

  useEffect(() => {
    if (workshopsByCountry.length > 0) {
      if (isFinland && code.length < 1) {
        generateFinlandCode(workshopsByCountry);
      }
      setLoading(false);
    }
  }, [workshopsByCountry]);

  return (
    <div className='flex-column code-wrapper'>
      <TextInputField
        data-cy='codeField'
        id={codeFieldName}
        name={codeFieldName}
        value={code}
        label='Code'
        onBlur={handleBlur}
        onChange={handleChange}
        onFocus={handleFocus}
        autoComplete='off'
        disabled={disabled}
      />
      {!isNorway && showSuggestedCodes && (
        <TextInputFilterList
          disabled={disabled}
          loading={loading}
          suggestions={suggestedCodes.map((code) => [code, code])}
          handleOptionClick={handleOptionClick}
          suggestionsHeading='Available codes:'
        />
      )}
      <InputError mainText='Norwegian workshop code is 7 digits' show={isNorway && !isAllNumbersWithLength(code, 7)} />
      <InputError
        mainText='The code is already assign to a workshop.'
        linkText='Go to workshop'
        linkUrl={`/${country}/${code}`}
        show={isFinland && !available}
      />
    </div>
  );
};

export default CodeField;
