import React, { Fragment, useEffect, useRef, useState } from 'react';
import './ServiceRating.scss';
import classnames from 'classnames';
import {
  ContractType,
  ManufacturerRating as WorkshopManufacturerRating,
  ManufacturerRepo,
  PriorityType,
  Render,
  WorkshopServiceRating,
  WorkshopServiceType,
} from 'types';
import { useField, useWorkshopField, useWorkshopServiceRatingField } from 'types/formik';
import { useStateSelector } from 'store';
import { formatServiceType } from 'util/index';
import { ContractPriority } from './ContractPriority';
import { AllManufacturersRating, ManufacturerRating } from './ManufacturerRating';
import { Button, CloseButton, useTooltip } from 'components/shared';
import { getWorkshopServiceVehicleTypes } from 'types/WorkshopHandler';
import { useParams } from 'react-router';
import { RouteParams } from 'App';
import { AllManufacturersManufacturer } from 'types/ManufacturerRepo';
import { DeductibleSearch } from '../../DeductibleSearch';
import { Icon, IdsIconSpriteDefinition } from '@ids/react-icon';

const collapseEnabled = false;

// eslint-disable-next-line complexity,max-statements
export function ServiceRating(props: { index: number; rating?: WorkshopServiceRating }): Render {
  const { serviceType } = useParams<RouteParams>();
  const index = props.index;
  const [{ value: formRating }, { error }, { setValue: setRating }] = useField<WorkshopServiceRating>(
    `workshop.workshopServiceRatings[${index}]`
  );
  const viewOnly = Boolean(props.rating);
  const rating = props.rating ?? formRating;
  const editing = useStateSelector((state) => state.workshop.editing);
  const manufacturerRatings = rating.manufacturerRatings ?? [];
  const canBeOpened = (collapseEnabled && Boolean(manufacturerRatings.length)) || editing;
  const [open, setOpen] = useState(!collapseEnabled);
  const ref = useRef<HTMLDivElement>(null);

  function onHeaderClick() {
    if (!canBeOpened || !collapseEnabled) return;
    setOpen(!open);
  }

  useEffect(() => {
    if (ref.current && collapseEnabled) {
      ref.current.style.height = (open ? ref.current.scrollHeight : 0) + 'px';
    }
  });

  const highlighted = serviceType && serviceType === rating.serviceType;

  useEffect(() => {
    if (ref.current && highlighted) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
    }
  }, [ref]);

  // hack to make the element shrink, scrollHeight is max(content height, style height)
  // so we set style height to smaller that content height
  // 70px is about how much the element grows when editing
  useEffect(() => {
    if (editing || !collapseEnabled) return;
    if (ref.current) {
      ref.current.style.height = (open ? ref.current.scrollHeight - 70 : 0) + 'px';
    }
    setTimeout(() => {
      if (ref.current) {
        ref.current.style.height = (open ? ref.current.scrollHeight : 0) + 'px';
      }
    }, 100);
  }, [editing]);

  const showAllManufacturersToggle = getWorkshopServiceVehicleTypes(rating.serviceType).length > 0;

  if (!viewOnly && !showAllManufacturersToggle && !rating.allManufacturers) {
    setRating({ ...rating, allManufacturers: true });
  }

  const manufacturerRatingsWithIndex: [WorkshopManufacturerRating, number][] = (rating.manufacturerRatings ?? []).map(
    (r, i) => [r, i]
  );
  manufacturerRatingsWithIndex.sort((a, b) => a[0].name.localeCompare(b[0].name));

  function renderErrors(errors: string[]) {
    return errors.map((error, i) => (
      <div key={i} className='if input-error'>
        {error}
      </div>
    ));
  }

  function getAndRenderAllManufacturersError() {
    if (!error) return null;
    return renderErrors(Object.values(error).filter((e) => e.includes('All manufacturers')));
  }

  function getAndRenderManufacturerError(i: number) {
    const errors = Object(error);
    if (!errors.manufacturerRatings || !errors.manufacturerRatings[i]) return null;
    return renderErrors(Object.values(errors.manufacturerRatings[i]));
  }

  return (
    <div
      className={classnames('service-rating', canBeOpened && 'can-open', highlighted && 'highlighted')}
      data-cy={'service' + rating.serviceType}
    >
      <div className={classnames('service-rating-header', open && 'is-open')} onClick={onHeaderClick}>
        {collapseEnabled && <div className='if icon ui chevron-right' />}
        <ServiceRatingHeader index={index} open={open} rating={props.rating} />
      </div>
      <div className={classnames('service-rating-container', open && 'is-open')} ref={ref}>
        {editing && <AddManufacturerRating rating={rating} setRating={setRating} />}
        <AllManufacturersRating service={rating} index={index} />
        {getAndRenderAllManufacturersError()}
        {manufacturerRatingsWithIndex.map(([rating, i]) => (
          <Fragment key={i}>
            <ManufacturerRating service={index} ratingIndex={i} rating={rating} viewOnly={viewOnly} />
            {getAndRenderManufacturerError(i)}
          </Fragment>
        ))}
        <div className='manufacturer-rating-spacer' />
        {rating.serviceType == WorkshopServiceType.Glass && <DeductibleSearch />}
      </div>
    </div>
  );
}

// eslint-disable-next-line complexity
function ServiceRatingHeader(props: { index: number; open: boolean; rating?: WorkshopServiceRating }) {
  const editing = useStateSelector((state) => state.workshop.editing);
  const [{ value: formRating }] = useWorkshopServiceRatingField(props.index);
  const [{ value: ratings }, { error, touched }, { setValue: setRatings, setTouched }] =
    useWorkshopField('workshopServiceRatings');
  const { tooltipElement, onTooltip } = useTooltip();

  const rating = props.rating ?? formRating;

  const manufacturerRatings = rating?.manufacturerRatings ?? [];

  const manufacturersList: [string, ContractType, PriorityType][] = manufacturerRatings.map((x) => [
    x.name,
    x.contractType,
    x.priorityType,
  ]);

  function deleteRating() {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const newRatings = [...ratings!];
    newRatings.splice(props.index, 1);
    setRatings(newRatings);
  }

  const isCorrect = touched && !error;

  return (
    <>
      {tooltipElement}
      {editing && (
        <CloseButton
          cy='ServiceRating'
          {...onTooltip(`Delete ${formatServiceType(rating.serviceType)} service rating`)}
          onClick={(e) => {
            e.stopPropagation();
            deleteRating();
            setTouched(true, true);
          }}
        />
      )}
      <h4>
        {WorkshopServiceIcon({ type: rating.serviceType })}&ensp;{formatServiceType(rating.serviceType)}
        {isCorrect && <Icon className='if workshop-icon check' group='ui' size={16} icon='check' />}
      </h4>

      <div className='manufacturers-list'>
        <div>
          {!props.open &&
            manufacturersList.map((mf, i) => {
              const [name, contractType, priorityType] = mf;
              return (
                <Fragment key={i}>
                  {name}
                  <ContractPriority rating={{ contractType, priorityType }} />
                  {i < manufacturersList.length && <>&ensp;</>}
                </Fragment>
              );
            })}
        </div>
      </div>
    </>
  );
}

const serviceTypeIcons: Record<WorkshopServiceType, IdsIconSpriteDefinition> = {
  [WorkshopServiceType.Atv]: { group: 'product', size: 32, icon: 'atv' },
  [WorkshopServiceType.Body]: { group: 'coverage', size: 32, icon: 'car-collision' },
  [WorkshopServiceType.Camper]: { group: 'product', size: 32, icon: 'camper' },
  [WorkshopServiceType.Caravan]: { group: 'product', size: 32, icon: 'caravan' },
  [WorkshopServiceType.Glass]: { group: 'coverage', size: 32, icon: 'windshield-damage' },
  [WorkshopServiceType.HeavyGlass]: { group: 'coverage', size: 32, icon: 'windshield-damage' },
  [WorkshopServiceType.Mc]: { group: 'product', size: 32, icon: 'mc' },
  [WorkshopServiceType.Moped]: { group: 'product', size: 32, icon: 'moped' },
  [WorkshopServiceType.MopedCar]: { group: 'product', size: 32, icon: 'minicar' },
  [WorkshopServiceType.Service]: { group: 'symbol', size: 32, icon: 'wrench-tool' },
  [WorkshopServiceType.Snowmobile]: { group: 'product', size: 32, icon: 'snowmobile' },
  [WorkshopServiceType.Trailer]: { group: 'product', size: 32, icon: 'trailer' },
  [WorkshopServiceType.VintageVehicle]: { group: 'product', size: 32, icon: 'antique-car' },
  [WorkshopServiceType.CashPayment]: { group: 'symbol', size: 24, icon: 'coins' },
  [WorkshopServiceType.SmallDentRepair]: { group: 'product', size: 32, icon: 'patch' },
  [WorkshopServiceType.HeavyTrailer]: { group: 'product', size: 32, icon: 'collection-truck' },
  [WorkshopServiceType.TruckBody]: { group: 'product', size: 32, icon: 'collection-truck' },
  [WorkshopServiceType.TruckService]: { group: 'product', size: 32, icon: 'collection-truck' },
  [WorkshopServiceType.AuxiliaryEquipment]: { group: 'product', size: 32, icon: 'collection-truck' },
  [WorkshopServiceType.BusBody]: { group: 'product', size: 32, icon: 'bus' },
  [WorkshopServiceType.BusService]: { group: 'product', size: 32, icon: 'bus' },
  [WorkshopServiceType.Construction]: { group: 'product', size: 32, icon: 'cement-truck' },
  [WorkshopServiceType.Agricultural]: { group: 'product', size: 32, icon: 'tractor' },
  [WorkshopServiceType.Forestry]: { group: 'product', size: 32, icon: 'forest' },
  [WorkshopServiceType.ElectricVehicle]: { group: 'product', size: 32, icon: 'electric-car' },
  [WorkshopServiceType.Sanitation]: { group: 'product', size: 32, icon: 'decontamination' },
  [WorkshopServiceType.MopedCarService]: { group: 'product', size: 32, icon: 'minicar' },
};

export function WorkshopServiceIcon(props: { type: WorkshopServiceType; props?: any }): Render {
  const iconData = serviceTypeIcons[props.type];
  if (!iconData) return null;
  return <Icon className='if icon' {...iconData} />;
}

function AddManufacturerRating(props: {
  rating: WorkshopServiceRating;
  setRating(value: WorkshopServiceRating): void;
}) {
  const [{ value: countryCode }] = useWorkshopField('countryCode');
  const manufacturerRatings = props.rating.manufacturerRatings ?? [];
  const refManufacturers = useRef<HTMLSelectElement>(null);
  let manufacturers = ManufacturerRepo.getExcludingCodes(
    manufacturerRatings.map((m) => m.internationalManufacturerCode!)
  );

  const { tooltipElement, onTooltip } = useTooltip();

  const vehicleTypes = getWorkshopServiceVehicleTypes(props.rating.serviceType);

  if (!vehicleTypes.length) return null;

  const relevancies = ManufacturerRepo.getRelevancies(countryCode, vehicleTypes);

  manufacturers = manufacturers.filter((manufacturer) =>
    relevancies.includes(manufacturer.internationalManufacturerCode)
  );

  const iconProps = { group: 'ui', size: 16, icon: 'plus' } as IdsIconSpriteDefinition;

  // eslint-disable-next-line complexity
  function addServiceType() {
    if (!refManufacturers.current) return;

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const manufacturer = ManufacturerRepo.getByCode(refManufacturers.current.value)!;

    if (ManufacturerRepo.isAllManufacturer(manufacturer)) {
      props.setRating({
        ...props.rating,
        allManufacturers: true,
      });
    } else {
      props.setRating({
        ...props.rating,
        manufacturerRatings: [
          ...(props.rating.manufacturerRatings ?? []),
          {
            ...manufacturer,
            id: undefined as any,
            steeringCampaign: false,
            priorityType: PriorityType.NotPrioritized,
            contractType: ContractType.NotDecided,
          },
        ],
      });
    }
  }
  if (!props.rating.allManufacturers) manufacturers.unshift(AllManufacturersManufacturer);

  if (!(manufacturers.length > 0)) return null;

  return (
    <div className='add-manufacturer-rating'>
      {tooltipElement}
      <span data-cy-only-label='manufacturerSelect' />
      <select
        className='if select single-select manufacturer-select dropdown-select'
        ref={refManufacturers}
        data-cy='manufacturerSelect'
      >
        {manufacturers.map((manufacturer, i) => {
          const imc = manufacturer.internationalManufacturerCode;
          const hasNationalCode = ManufacturerRepo.hasNationalCode(countryCode, imc);
          const isAll = ManufacturerRepo.isAllManufacturer(manufacturer);

          let name = manufacturer.name;
          if (!hasNationalCode && !isAll) {
            name = `(${name})`;
          }
          return (
            <option value={imc} key={i}>
              {name}
            </option>
          );
        })}
      </select>
      <Button
        {...onTooltip(() => {
          if (!refManufacturers.current) return '';
          const manufacturer = ManufacturerRepo.getByCode(refManufacturers.current.value)!;
          return 'Add manufacturer rating for ' + manufacturer.name;
        })}
        text='Add'
        cy='add'
        onClick={addServiceType}
        type='text add-service-button'
        icon={iconProps}
      />
    </div>
  );
}
