import { getWorkshopsApiClient } from 'util/apiClients';
import { Workshop } from 'types';
import * as localForage from 'localforage';
import { SearchData } from './FastSearch';

export function getCacheKey(countryCode: string): string {
  return 'IfWorkshopPartners.Search.Workshops.' + countryCode.toUpperCase();
}

export interface CachedWorkshops {
  workshops: Workshop[];
  timestamp: number;
}

async function getWorkshops(countryCode: string): Promise<Workshop[]> {
  const client = await getWorkshopsApiClient();
  return await client.search({
    countryCode: countryCode,
    allManufacturers: undefined as unknown as boolean,
  });
}

async function setWorkshopsCache(cacheKey: string, workshops: Workshop[]): Promise<CachedWorkshops> {
  return localForage.setItem(cacheKey, {
    workshops: workshops,
    timestamp: new Date().getTime(),
  } as CachedWorkshops);
}

export async function getWorkshopsFromCache(countryCode: string): Promise<CachedWorkshops | null> {
  const cacheKey = getCacheKey(countryCode);
  try {
    return await localForage.getItem<CachedWorkshops>(cacheKey);
  } catch {}
  return null;
}

export async function getWorkshopsFromApiAndSetCache(countryCode: string): Promise<CachedWorkshops> {
  const cacheKey = getCacheKey(countryCode);

  const workshops = await getWorkshops(countryCode);
  return await setWorkshopsCache(cacheKey, workshops);
}

export async function getWorkshopsFromCacheOrApi(countryCode: string): Promise<CachedWorkshops> {

  const cachedData = await getWorkshopsFromCache(countryCode);

  if (cachedData) {
    const timestamp = new Date(cachedData.timestamp).getTime();
    const timeDiff = new Date().getTime() - timestamp;
    const ageInMinutes = timeDiff / 1000 / 60;
    const isOld = ageInMinutes > 1;
    if (!isOld) return cachedData;
  }

  return await getWorkshopsFromApiAndSetCache(countryCode);
}

export async function setCachedWorkshop(workshop: Workshop): Promise<void> {
  const cacheKey = getCacheKey(workshop.countryCode);
  let cachedData: CachedWorkshops | null = null;

  try {
    cachedData = await localForage.getItem<CachedWorkshops>(cacheKey);
  } catch {}
  if (cachedData) {
    const index = cachedData.workshops.findIndex((w) => w.id === workshop.id);
    if (index >= 0) {
      cachedData.workshops.splice(index, 1, workshop);
      // so search is forced to fetch data from cache
      SearchData.promise = null;
      await setWorkshopsCache(cacheKey, cachedData.workshops);
    }
  }
}
