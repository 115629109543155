import React, { useContext } from 'react';
import { AppContext } from 'App';
import { adminRoles } from 'models/User';
import { useStateSelector } from 'store';
import { Render } from 'types';
import { isCypress } from '../util';

interface Condition {
  condition?: boolean;
}
interface AdminContentProps extends Condition {
  children: React.ReactNode;
}

// set to true or false to override conditional admin display
const AdminOverride = null;

function isAdmin() {
  if (isCypress()) {
    return true;
  }
  const context = useContext(AppContext);

  const currentRegionCode = context.locale.country.toLocaleLowerCase();

  const userRoles = useStateSelector((state) => state.user.userRoles);

  let urlOverride: boolean | null = null;

  try {
    urlOverride = JSON.parse(new URLSearchParams(location.search).get('admin')!);
  } catch {}

  //use admin=* from url if it exists, if not try the admin override above
  //if neither exists, use user roles
  return urlOverride ?? AdminOverride ?? userRoles.includes(adminRoles[currentRegionCode]);
}

function AdminContent(props: AdminContentProps): Render {
  if (isAdmin() && props.condition !== false) {
    return <>{props.children}</>;
  }

  return null;
}

export function AdminContentWrapper<TProps>(
  component: (props: TProps) => Render,
  name?: string
): (props: TProps & Condition) => Render {
  const wrapper = function (props: TProps & Condition) {
    if (isAdmin() && props.condition !== false) {
      return component(props);
    }

    return null;
  };

  const originalName = (component as React.FC).displayName || (component as React.FC).name;
  wrapper.displayName = (name || originalName)?.replace(/^_/, '');

  return wrapper;
}

export default AdminContent;
