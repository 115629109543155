import {
  AnyRating,
  ContractType,
  CustomerServiceType,
  GeocodePrecision,
  IfContact,
  PriorityType,
  StringOrGetter,
  WorkshopServiceType,
} from 'types';
import { environment } from './environment';

declare global {
  interface Array<T> {
    filterOut(o: T): Array<T>;
  }
}
Array.prototype.filterOut = function <T>(predicate: (value: T, index: number, array: T[]) => unknown): T[] {
  return this.filter(function (value, index, array) {
    return !predicate(value, index, array);
  });
};

export function lerp(start: number, end: number, amount: number): number {
  return start * (1 - amount) + end * amount;
}

export function formatPriorityType(type: PriorityType, notPrioritized = '-'): string {
  if (type === PriorityType.NotPrioritized) {
    return notPrioritized;
  }
  return type.replace('Prio', '');
}

export function formatServiceType(type: WorkshopServiceType | ''): string {
  return (
    (
      {
        [WorkshopServiceType.Atv]: 'ATV',
        [WorkshopServiceType.Mc]: 'Motorcycle',
        [WorkshopServiceType.Glass]: 'Light Glass',
        [WorkshopServiceType.MopedCar]: 'Moped Car Body',
      } as any
    )[type] ??
    fromCamelToWords(WorkshopServiceType[type as WorkshopServiceType]) ??
    'Unspecified'
  );
}

export function formatCustomerServiceType(type: CustomerServiceType): string {
  return (
    (
      {
        [CustomerServiceType.PreInspection]: 'On-site inspection',
        [CustomerServiceType.GlassPhotoInspection]: 'Photo Inspection - Volvia Glass',
      } as any
    )[type] ??
    fromCamelToWords(CustomerServiceType[type as CustomerServiceType]) ??
    'Unspecified'
  );
}

export function formatCustomerServiceTypeTooltip(type: CustomerServiceType): string {
  return (
    (
      {
        [CustomerServiceType.PreInspection]: 'Booking function via external partner',
      } as any
    )[type] ?? formatCustomerServiceType(type)
  );
}

export function formatContractType(type: ContractType | '', notDecided = '-'): string {
  if (type === ContractType.NotDecided) return notDecided;
  return ContractType[type as ContractType] ?? 'Unspecified';
}

export function formatDate(date: Date | string | null | undefined): string {
  if (!date) return '-';
  if (!(date instanceof Date)) {
    date = new Date(date);
  }
  return (
    date.getFullYear().toString() +
    '-' +
    (date.getMonth() + 1).toString().padStart(2, '0') +
    '-' +
    date.getDate().toString().padStart(2, '0')
  );
}

export function unique(list: (string | undefined)[]): string[] {
  return [...new Set(list)].filter((x) => !!x).map((x) => x as string);
}

export function compareByEmail(a: IfContact, b: IfContact): number {
  return a.mail!.localeCompare(b.mail!);
}

export function ratingContractPriorityAsNumber(rating: AnyRating): number {
  const contractOrder: ContractType[] = [
    ContractType.NotDecided,
    ContractType.X,
    ContractType.G,
    ContractType.C,
    ContractType.B,
    ContractType.A,
  ];
  const prioOrder: PriorityType[] = [
    PriorityType.NotPrioritized,
    PriorityType.Prio4,
    PriorityType.Prio3,
    PriorityType.Prio2,
    PriorityType.Prio1,
  ];
  return contractOrder.indexOf(rating.contractType) * contractOrder.length + prioOrder.indexOf(rating.priorityType);
}

// SmallDentRepair -> Small Dent Repair
const fromCamelToWords = (text: string) => text.replace(/(\w)([A-Z])/g, '$1 $2');

export function formatLabelFromProperty(property: string): string {
  const dot = property.lastIndexOf('.');
  if (dot >= 0) {
    property = property.slice(dot + 1);
  }
  let text = sentenceCase(property);
  text = text.replace(' id', ' ID');
  text = text.replace(' if', ' If');
  return text;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function titleCase(text: string): string {
  text = fromCamelToWords(text);
  return text.charAt(0).toUpperCase() + text.slice(1);
}

export function sentenceCase(text: string): string {
  text = fromCamelToWords(text);
  text = text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  return text;
}

export function decodeSearchTerms(terms: string | undefined): string {
  return decodeURIComponent(terms?.replace(/\+/g, ' ') || '');
}
export function encodeSearchTerms(terms: string | undefined): string {
  return encodeURIComponent(terms || '').replace(/(%20)+(?!%)/g, '+');
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function deepGet(object: any, property: string, nullPropagate = true): any {
  if (nullPropagate) return property.split('.').reduce((a, b) => (a == null ? null : a[b]), object);
  else return property.split('.').reduce((a, b) => a[b], object);
}

export function fromGoogleLocationTypeToGeocodePrecision(type: google.maps.GeocoderLocationType): GeocodePrecision {
  const GeocoderLocationType = google.maps.GeocoderLocationType;
  switch (type) {
    case GeocoderLocationType.APPROXIMATE:
    case GeocoderLocationType.GEOMETRIC_CENTER:
      return GeocodePrecision.StreetLevel;
    case GeocoderLocationType.RANGE_INTERPOLATED:
    case GeocoderLocationType.ROOFTOP:
      return GeocodePrecision.InterpolatedOrTrueRooftop;
    default:
      return GeocodePrecision.Unknown;
  }
}

export function roundTo10cm(num: number | undefined): number | undefined {
  if (!num) return num;
  //http://wiki.gis.com/wiki/index.php/Decimal_degrees
  return Math.round((num + Number.EPSILON) * 1000000) / 1000000;
}

export function getText(text: StringOrGetter): string {
  return typeof text === 'function' ? text() : text;
}

export function logCall(...args: unknown[]): void {
  const stack = new Error().stack;
  const callerFunc = stack?.split('\n')[2].substr(7);
  console.debug(callerFunc);
  console.debug(...args);
}

export const isCypress = (): boolean => window.hasOwnProperty('Cypress') || environment.appEnv === 'test';
