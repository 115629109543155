import {
  ContractPriorities,
  ManufacturerRepo,
  Render,
  SearchFilters,
  VehicleType,
  Workshop,
  WorkshopServiceType,
} from 'types';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useFormikContext } from 'formik';
import { Explanation } from './Explanation';
import { filterWorkshops } from './filterWorkshops';
import { parseSearchTerms } from './parseSearchTerms';
import { WorkshopResult } from './WorkshopResult';
import { ResultsMap } from './ResultsMap';
import { SearchForm } from './types';
import {
  CategorizedWorkshopServiceTypes,
  CompareWorkshopServiceType,
} from '../../components/workshop/services/ServiceRatings';
import { compareByEmail, formatServiceType, unique } from '../../util';
import { getWorkshopServiceVehicleTypes } from '../../types/WorkshopHandler';
import { AppContext } from '../../App';
import { AllManufacturersManufacturer } from '../../types/ManufacturerRepo';
import { Button } from '../../components/shared';
import { getCachedIfContact } from '../../util/users';
export function Results({ workshops }: { workshops: Workshop[] }): Render {
  const [more, setMore] = useState(0);
  const showMore = () => setMore((more) => Math.min(more + 30, 180));

  const { locale } = useContext(AppContext);
  const { values: formValues } = useFormikContext<SearchForm>();

  const [workshopServiceType, setWorkshopServiceType] = useState<WorkshopServiceType | ''>('');
  const [manufacturer, setManufacturer] = useState<string>('');
  const [rawContact, setRawContact] = useState('');

  const filters: SearchFilters = {
    manufacturers: [...formValues.manufacturers],
    workshopServiceTypes: [...formValues.workshopServiceTypes],
    badGeocodePrecision: false,
    hasAddress: false,
    mixedRatings: false,
    showEmpty: false,
    minRatingRaw: formValues.minRating,
    maxRatingRaw: ContractPriorities.length - 1,
    terms: formValues.termsRaw
      .toLocaleLowerCase()
      .split(' ')
      .filter((t) => t.length > 1),
    termsRaw: formValues.termsRaw.toLocaleLowerCase(),
    contact: undefined,
  };

  parseSearchTerms(filters);

  if (rawContact) {
    const split = rawContact.split(';');
    filters.contact = {
      type: split[0] as any,
      email: split[1],
    };
  }

  const hasServiceTypeInTerms = filters.workshopServiceTypes.length > 0;
  const termsServiceType = filters.workshopServiceTypes[0];

  const hasManufacturerInTerms = filters.manufacturers.length > 0;
  const termsManufacturer = filters.manufacturers[0]?.internationalManufacturerCode;

  if (workshopServiceType) {
    filters.workshopServiceTypes.push(workshopServiceType);
  }

  if (manufacturer) {
    const found = ManufacturerRepo.getByCode(manufacturer);
    if (found) filters.manufacturers.push(found);
  }

  const vehicleTypes: VehicleType[] = getWorkshopServiceVehicleTypes(filters.workshopServiceTypes[0]) ?? [];

  const relevancies = ManufacturerRepo.getRelevancies(locale.country, vehicleTypes);

  let availableManufacturers = ManufacturerRepo.getAll();
  if (relevancies.length > 0) {
    availableManufacturers = availableManufacturers.filter((manufacturer) =>
      relevancies.includes(manufacturer.internationalManufacturerCode)
    );
  }
  availableManufacturers.unshift(AllManufacturersManufacturer);

  if (!workshops.length) {
    return null;
  }

  const { filtered, shownWorkshops } = filterWorkshops(workshops, filters, more);

  const refLastResult = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    function handleScroll() {
      const viewportHeight = window.innerHeight;
      if (refLastResult.current) {
        const elementTop = refLastResult.current.getBoundingClientRect().top;
        if (elementTop < viewportHeight) {
          showMore();
        }
      } else {
        const scrollTop = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
        const scrollHeight =
          (document.documentElement && document.documentElement.scrollHeight) || document.body.scrollHeight;
        const scrolledToBottom = scrollTop + window.innerHeight + 200 >= scrollHeight;
        if (scrolledToBottom && shownWorkshops.length < filtered.length) {
          showMore();
        }
      }
    }
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [more]);

  useEffect(() => {
    setMore(0);
  }, [filtered.length]);

  const shownServiceTypes: WorkshopServiceType[] = [];

  shownWorkshops
    .map((w) => w.workshopServiceRatings ?? [])
    .forEach((ratings) =>
      ratings
        .map((rating) => rating.serviceType)
        .forEach((type) => {
          !shownServiceTypes.includes(type) && shownServiceTypes.push(type);
        })
    );

  shownServiceTypes.sort(CompareWorkshopServiceType);

  return (
    <div className='results'>
      <div className='extra-filters'>
        <label>
          Workshop service
          <select
            className='if select single-select service-select dropdown-select'
            data-cy='selectService'
            data-selected={workshopServiceType}
            disabled={hasServiceTypeInTerms}
            value={termsServiceType || workshopServiceType}
            onChange={(e) => {
              setWorkshopServiceType(e.target.value as WorkshopServiceType);
            }}
          >
            <option value=''>&mdash;</option>
            {Object.keys(CategorizedWorkshopServiceTypes).map((category) => (
              <optgroup label={category} key={category}>
                {CategorizedWorkshopServiceTypes[category].map((type, i) => (
                  <option value={type} key={i}>
                    {formatServiceType(type as WorkshopServiceType)}
                  </option>
                ))}
              </optgroup>
            ))}
          </select>
        </label>
        <label>
          Vehicle manufacturer
          <select
            className='if select single-select service-select dropdown-select'
            data-cy='selectManufacturer'
            data-selected={manufacturer}
            disabled={hasManufacturerInTerms}
            value={termsManufacturer || manufacturer}
            onChange={(e) => setManufacturer(e.target.value)}
          >
            <option value=''>&mdash;</option>
            {availableManufacturers.map((mf, i) => (
              <option value={mf.internationalManufacturerCode} key={i}>
                {mf.name}
              </option>
            ))}
          </select>
        </label>
        <label>
          Contact
          <select
            className='if select single-select service-select dropdown-select'
            data-cy='selectContact'
            value={rawContact}
            onChange={({ target }) => setRawContact(target.value)}
          >
            <option value=''>&mdash;</option>
            <optgroup label='Agreement responsible'>
              {unique(workshops.map((w) => w.responsiblePersonUserId))
                .map(getCachedIfContact)
                .sort(compareByEmail)
                .map((user) =>
                  user ? (
                    <option value={'responsiblePersonUserId;' + user.mailNickname} key={user.mail}>
                      {user.mail}
                    </option>
                  ) : null
                )}
            </optgroup>
            <optgroup label='Inspector'>
              {unique(workshops.map((w) => w.surveyorUserId))
                .map(getCachedIfContact)
                .sort(compareByEmail)
                .map((user) =>
                  user ? (
                    <option value={'surveyorUserId;' + user.mailNickname} key={user.mail}>
                      {user.mail}
                    </option>
                  ) : null
                )}
            </optgroup>
            <optgroup label='Group e-mail'>
              {unique(workshops.map((w) => w.groupEmail)).map((email) => (
                <option value={'groupEmail;' + email} key={email}>
                  {email}
                </option>
              ))}
            </optgroup>
          </select>
        </label>
      </div>
      <Explanation filters={filters} count={filtered.length} />
      <ResultsMap key={formValues.termsRaw + workshopServiceType + manufacturer + rawContact} workshops={filtered} />
      {shownWorkshops.map((w, i) => (
        <WorkshopResult key={w.id} workshop={w} ref={i === shownWorkshops.length - 1 ? refLastResult : undefined} />
      ))}
      <div className='show-more'>
        {shownWorkshops.length} / {filtered.length} shown
        {shownWorkshops.length === 200 && <> (maximum)</>}
        {shownWorkshops.length < filtered.length && shownWorkshops.length < 200 && (
          <Button
            text='Show more'
            cy='btnShowMore'
            onClick={(e) => {
              e.preventDefault();
              showMore();
            }}
          />
        )}
      </div>
    </div>
  );
}
