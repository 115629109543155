import React from 'react';

interface Props {
  breadcrumbs: { path: string; text: string }[];
  currentBreadcrumb: string;
}

const Breadcrumbs = ({ breadcrumbs, currentBreadcrumb }: Props): JSX.Element => (
  <ul className='if breadcrumbs' data-cy='breadcrumbs'>
    {breadcrumbs &&
      breadcrumbs.map((breadcrumb, key) => (
        <li key={key} className='if breadcrumb link'>
          <a href={breadcrumb.path}>{breadcrumb.text}</a>
        </li>
      ))}
    <li className='if breadcrumb'>{currentBreadcrumb}</li>
  </ul>
);

export default Breadcrumbs;
