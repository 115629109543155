import React from 'react';
import './ManufacturerRating.scss';
import {
  ManufacturerRepo,
  Render,
  WorkshopHandler,
  ManufacturerRating as ManufacturerRatingType,
  WorkshopServiceRating,
  ContractType,
  PriorityType,
} from 'types';
import { ContractPriority } from './ContractPriority';
import { useStateSelector } from 'store';
import classnames from 'classnames';
import { useManufacturerRatingField, useWorkshopField, useWorkshopServiceRatingField } from 'types/formik';
import { useTooltip, CloseButton } from 'components/shared';
import { useField } from 'formik';
import { IconDisplay } from '../../shared/IconDisplay';
import { IdsIconSpriteDefinition } from '@ids/react-icon';

// eslint-disable-next-line complexity
export function ManufacturerRating(props: {
  service: number;
  ratingIndex: number;
  rating?: ManufacturerRatingType;
  viewOnly?: boolean;
}): Render {
  const [{ value: workshopCountryCode }] = useWorkshopField('countryCode');
  const [{ value: formCountryCode }] = useField('countryCode');
  const countryCode = workshopCountryCode ?? formCountryCode;
  const [{ value: excludeList }, , { setValue: setExcludeList }] = useWorkshopField(
    'excludeCustomerReceptionForInternationalManufacturerCodes'
  );
  const editing = useStateSelector((state) => state.workshop.editing);
  const [{ value: formRating }, , { setValue: setRating, setTouched: setRatingTouched }] = useManufacturerRatingField(
    props.service,
    props.ratingIndex
  );
  const [{ value: serviceRating }, , { setValue: setServiceRating }] = useWorkshopServiceRatingField(props.service);
  const { tooltipElement, onTooltip } = useTooltip();

  const rating = editing ? formRating : props.rating ?? formRating;

  const excludeCustomerReception = excludeList?.includes(rating.internationalManufacturerCode!) === true;

  function deleteRating() {
    const newRatings = [...serviceRating.manufacturerRatings!];
    newRatings.splice(props.ratingIndex, 1);
    setServiceRating({
      ...serviceRating,
      manufacturerRatings: newRatings,
    });
  }

  const key = `${rating.id}${rating.internationalManufacturerCode}`;

  const hasNationalCode = ManufacturerRepo.hasNationalCode(countryCode, rating.internationalManufacturerCode!);
  const name = ManufacturerRepo.getName(rating);

  const starIconProps = { group: 'symbol', size: 24, icon: 'star' } as IdsIconSpriteDefinition;
  const peopleIconProps = { group: 'symbol', size: 24, icon: 'people' } as IdsIconSpriteDefinition;
  const crossIconProps = { group: 'ui', size: 24, icon: 'cross' } as IdsIconSpriteDefinition;

  return (
    <div className='manufacturer-rating' data-cy={'service' + rating.internationalManufacturerCode}>
      {tooltipElement}
      {editing && (
        <CloseButton
          cy='ManufacturerRating'
          {...onTooltip('Remove rating for ' + rating.name)}
          onClick={(e) => {
            e.stopPropagation();
            deleteRating();
          }}
        />
      )}
      <ContractPriority rating={rating} setRating={setRating} />
      <h4>
        {!hasNationalCode && <span className='hidden-in-search'>(</span>}
        {name}
        {!hasNationalCode && <span className='hidden-in-search'>)</span>}
        {!hasNationalCode && (
          <span
            {...onTooltip('No national code mapping exists, automatic steering not possible')}
            className='if workshop-icon warning hidden-in-search'
          />
        )}
      </h4>
      <div className='header-spacer hidden-in-search' />
      <div className='steering-campaign hidden-in-search'>
        <input
          data-cy='steeringCampaign'
          id={key + '-steering-campaign'}
          type='checkbox'
          onChange={(e) => {
            if (!editing) return;
            setRatingTouched(true);
            setRating({
              ...rating,
              steeringCampaign: e.target.checked,
            });
          }}
        />
        <label
          className={classnames({ editing })}
          htmlFor={key + '-steering-campaign'}
          data-cy-label='steeringCampaign'
        >
          <span {...onTooltip('Steering campaign for ' + rating.name)}>
            <IconDisplay iconProp={starIconProps} additionalClassName={rating.steeringCampaign ? 'blue' : ''} />
          </span>
        </label>
      </div>
      <div className='exclude-customer-reception hidden-in-search'>
        <input
          id={key + '-exclude-customer-reception'}
          defaultChecked={excludeCustomerReception}
          type='checkbox'
          data-cy='excludeCustomerReception'
        />
        <label
          data-cy-label='excludeCustomerReception'
          className={classnames({ editing })}
          htmlFor={key + '-exclude-customer-reception'}
          onClick={() => {
            if (!editing) return;
            setExcludeList(
              WorkshopHandler.toggleExcludeCustomerReception(
                excludeList,
                rating.internationalManufacturerCode!,
                !excludeCustomerReception
              )
            );
          }}
        >
          <span
            {...onTooltip('Customer reception for ' + rating.name)}
            className={classnames('if icon symbol people', excludeCustomerReception && 'on')}
          >
            <IconDisplay
              iconProp={peopleIconProps}
              additionalClassName={excludeCustomerReception ? 'on' : ''}
            ></IconDisplay>
          </span>
          <span>
            <IconDisplay
              iconProp={crossIconProps}
              additionalClassName={excludeCustomerReception ? 'close on' : 'close'}
            />
          </span>
        </label>
      </div>
    </div>
  );
}

export function AllManufacturersRating(props: {
  index: number;
  service?: WorkshopServiceRating;
  viewOnly?: boolean;
}): Render {
  const editing = useStateSelector((state) => state.workshop.editing);

  const [{ value: indexServiceRating }, , { setValue: setServiceRating, setTouched }] = useWorkshopServiceRatingField(
    props.index
  );
  const { tooltipElement, onTooltip } = useTooltip();

  const serviceRating = props.service ?? indexServiceRating;

  if (!serviceRating?.allManufacturers) return null;

  function deleteRating() {
    setServiceRating({
      ...serviceRating,
      allManufacturers: false,
      contractType: ContractType.NotDecided,
      priorityType: PriorityType.NotPrioritized,
    });
  }

  return (
    <div className='manufacturer-rating' data-cy='serviceAll'>
      {tooltipElement}
      {editing && (
        <CloseButton
          cy='ManufacturerRating'
          {...onTooltip('Remove rating for All manufacturers')}
          onClick={(e) => {
            e.stopPropagation();
            deleteRating();
            setTouched(true, true);
          }}
        />
      )}
      <ContractPriority rating={serviceRating} setRating={setServiceRating} />
      <h4>All manufacturers</h4>
    </div>
  );
}
