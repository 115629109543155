import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import './styles/index.scss';
import '@ids/style-bundle';
import '@ids/style-css-helpers';
import App from 'App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import store from 'store';
import { createRoot } from 'react-dom/client';

const container = document.getElementById('root');
if (container) {
  const root = createRoot(container);

  root.render(
    <Provider store={store}>
      <App />
    </Provider>
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
