import React from 'react';
import { useField } from 'formik';
import classnames from 'classnames';

import { Render, WorkshopProperty } from 'types';
import { formatLabelFromProperty } from 'util/index';
import { AdminContentWrapper } from 'components/AdminContent';
import { Icon } from '@ids/react-icon';

// eslint-disable-next-line complexity
function _EditField(props: { label?: string; property: WorkshopProperty }): Render {
  const property = 'workshop.' + props.property;
  const [{ value: fieldValue }, { touched, error }, { setValue }] = useField(property);
  const hasError = touched && error;

  const value = fieldValue ?? '';

  return (
    <div className='if form-group component workshop-field checkbox'>
      <div className='if checkbox'>
        <input
          checked={value}
          onChange={() => {
            setValue(!value);
          }}
          type='checkbox'
          className={classnames('if checkbox-input', hasError && 'is-invalid')}
          data-cy={property}
        />
        <label
          className='if label'
          htmlFor={'edit-workshop.' + props.property}
          onClick={() => {
            setValue(!value);
          }}
        >
          {props.label ?? formatLabelFromProperty(props.property)}
        </label>
        {hasError && <div className='if input-error'>{error}</div>}
      </div>
    </div>
  );
}
const EditField = AdminContentWrapper(_EditField);

const ReadField = (props: { label?: string; property: WorkshopProperty }): Render => {
  const [{ value }] = useField('workshop.' + props.property);

  return (
    <div className='component field'>
      <div className='if label'>{props.label ?? formatLabelFromProperty(props.property)}</div>
      <div data-cy={'workshop.' + props.property}>
        {value ? (
          <>
            <span className='if icon ui check-circle' /> Yes
          </>
        ) : (
          <>
            <Icon className='if close-icon' group='ui' size={16} icon='cross' /> No
          </>
        )}
      </div>
    </div>
  );
};

export function WorkshopCheckbox(editing: boolean): typeof EditField | typeof ReadField {
  return editing ? EditField : ReadField;
}
