import React, { ButtonHTMLAttributes, DOMAttributes, HTMLAttributes } from 'react';
import classnames from 'classnames';
import { Render } from 'types';
import { IdsIconSpriteDefinition } from '@ids/react-icon';
import { IconDisplay } from './IconDisplay';

type ButtonProps = {
  text: string;
  cy: string;
  type?: 'outlined' | string;
  icon?: IdsIconSpriteDefinition;
  hide?: boolean;
} & DOMAttributes<HTMLButtonElement> &
  Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'type'>;
export function Button(props: ButtonProps): Render {
  if (props.hide) {
    return null;
  }
  const type = props.type ?? 'filled';

  const passthrough: Partial<ButtonProps> = { ...props };

  passthrough.text = passthrough.cy = passthrough.type = passthrough.icon = passthrough.hide = undefined;

  return (
    <button
      {...(passthrough as Omit<ButtonProps, 'type'>)}
      className={classnames('component if button', type)}
      data-cy={'button' + props.cy.charAt(0).toUpperCase() + props.cy.slice(1)}
      type='button'
    >
      {props.icon && <IconDisplay iconProp={props.icon} />}
      {props.text}
    </button>
  );
}

export function CloseButton(
  props: {
    cy: string;
  } & DOMAttributes<HTMLButtonElement> &
    HTMLAttributes<HTMLButtonElement>
): Render {
  const iconProps = { group: 'ui', size: 16, icon: 'cross' } as IdsIconSpriteDefinition;
  return (
    <Button
      {...props}
      text=''
      cy={'delete' + props.cy.charAt(0).toUpperCase() + props.cy.slice(1)}
      icon={iconProps}
      type='text delete-button'
    />
  );
}
