import React, { useRef } from 'react';
import './WorkshopCustomerServices.scss';
import { useStateSelector } from 'store';
import { Render, WorkshopProperty, CustomerServiceType } from 'types';
import { useWorkshopField } from 'types/formik';
import { WorkshopField } from './shared/WorkshopField';
import { formatCustomerServiceType, formatCustomerServiceTypeTooltip } from 'util/index';
import { WorkshopSection } from './shared/WorkshopSection';
import { Button, CloseButton, useTooltip } from 'components/shared';
import { CustomerService } from '../../api-clients/WorkshopsApi';
import { Icon, IdsIconSpriteDefinition } from '@ids/react-icon';

export const WorkshopCustomerServices = (): Render => {
  const editing = useStateSelector((state) => state.workshop.editing);
  const [{ value }, , { setValue: setCustomerServices, setTouched: setCustomerServiceTouched }] =
    useWorkshopField('customerServices');
  const Field = WorkshopField(editing);
  const { tooltipElement, onTooltip } = useTooltip();

  const possibleFields: Record<CustomerServiceType, (keyof CustomerService)[]> = {
    [CustomerServiceType.PreInspection]: ['externalId'],
    [CustomerServiceType.RentalCarCentral]: ['rentalCarBrand', 'email'],
    [CustomerServiceType.RentalCarLocal]: ['rentalCarBrand', 'email'],
    [CustomerServiceType.RentalCarOwner]: ['rentalCarBrand', 'email'],
    [CustomerServiceType.PhotoInspection]: ['email', 'disabledStartDate', 'disabledEndDate'],
    [CustomerServiceType.GlassPhotoInspection]: ['email', 'disabledStartDate', 'disabledEndDate'],
    [CustomerServiceType.WorkshopLetter]: ['email'],
    [CustomerServiceType.Unspecified]: [],
  };

  const refServiceType = useRef<HTMLSelectElement>(null);
  const services = value ?? [];

  const serviceTypes = Object.values(CustomerServiceType).filter(typeIsNotUsed).sort();

  const iconProps = { group: 'product', size: 24, icon: 'handout' } as IdsIconSpriteDefinition;

  const buttonIconProps = { group: 'ui', size: 24, icon: 'plus' } as IdsIconSpriteDefinition;

  // if (!serviceTypes.length) return null;

  function typeIsNotUsed(type: CustomerServiceType) {
    return !services.find((r) => r.serviceType === type) && type !== CustomerServiceType.Unspecified;
  }

  function addServiceType() {
    if (!refServiceType.current) return;
    const serviceType = refServiceType.current.value as CustomerServiceType;
    setCustomerServices([
      ...services,
      {
        serviceType,
        id: undefined as any,
      },
    ]);
  }

  function deleteService(index: number) {
    const newServices = [...services];
    newServices.splice(index, 1);
    setCustomerServices(newServices);
  }

  return (
    <WorkshopSection
      title='Customer Services'
      icon={iconProps}
      contentClass='customer-services'
      anchorTag='customer-services'
    >
      {tooltipElement}
      {services.map((service, i) => {
        function csField(property: keyof CustomerService) {
          const show = possibleFields[service.serviceType].includes(property);
          if (!show) return null;
          return <Field property={`customerServices[${i}].${property}` as WorkshopProperty} />;
        }

        const showDisabledDates =
          service.serviceType == CustomerServiceType.GlassPhotoInspection ||
          service.serviceType == CustomerServiceType.PhotoInspection;

        return (
          <div
            key={service.serviceType}
            className='customer-service'
            data-cy={'customerService' + service.serviceType}
            {...onTooltip(formatCustomerServiceTypeTooltip(service.serviceType))}
          >
            <div className='flex-row'>
              {editing && (
                <CloseButton
                  cy='CustomerService'
                  {...onTooltip(`Delete ${formatCustomerServiceType(service.serviceType)} service`)}
                  onClick={(e) => {
                    e.stopPropagation();
                    deleteService(i);
                    setCustomerServiceTouched(true);
                  }}
                />
              )}
              <h4 className='if heading small'>
                {CustomerServiceIcon(service.serviceType)} {formatCustomerServiceType(service.serviceType)}
              </h4>
            </div>
            {csField('email')}
            {csField('rentalCarBrand')}
            {csField('externalId')}
            {showDisabledDates === true ? csField('disabledStartDate') : null}
            {showDisabledDates === true ? csField('disabledEndDate') : null}
          </div>
        );
      })}
      {services.length === 0 && <span className='no-content-text'>No customer services</span>}
      {editing && serviceTypes.length > 0 && (
        <div className='add-service' data-cy='addCustomerService'>
          <h3 className='if heading small'>Add</h3>
          <select
            className='if select single-select type-select dropdown-select'
            ref={refServiceType}
            data-cy='selectCustomerServiceType'
          >
            {serviceTypes.map((serviceType, i) => (
              <option value={serviceType} key={i}>
                {formatCustomerServiceType(serviceType)}
              </option>
            ))}
          </select>
          <span data-cy-only-label='selectCustomerServiceType' />
          <Button text='Add' cy='add' icon={buttonIconProps} type='text add-service-button' onClick={addServiceType} />
        </div>
      )}
    </WorkshopSection>
  );
};

const customerServiceTypeIcon = {
  [CustomerServiceType.PreInspection]: { group: 'ui', size: 32, icon: 'calendar' },
  [CustomerServiceType.RentalCarCentral]: { group: 'coverage', size: 32, icon: 'rental-car' },
  [CustomerServiceType.RentalCarLocal]: { group: 'coverage', size: 32, icon: 'rental-car' },
  [CustomerServiceType.RentalCarOwner]: { group: 'coverage', size: 32, icon: 'rental-car' },
  [CustomerServiceType.PhotoInspection]: { group: 'ui', size: 32, icon: 'camera' },
  [CustomerServiceType.GlassPhotoInspection]: { group: 'product', size: 32, icon: 'smartphone' },
  [CustomerServiceType.WorkshopLetter]: { group: 'ui', size: 32, icon: 'email' },
} as Record<CustomerServiceType, IdsIconSpriteDefinition>;
export function CustomerServiceIcon(type: CustomerServiceType): Render {
  const iconClasses = customerServiceTypeIcon[type];
  if (!iconClasses) return null;
  return <Icon className='if workshop-icon' {...iconClasses} />;
}
