import React from 'react';
import { useStateSelector } from 'store';
import { Render } from 'types';
import { WorkshopCheckbox } from './shared/WorkshopCheckbox';
import { WorkshopField } from './shared/WorkshopField';
import { WorkshopSection } from './shared/WorkshopSection';
import { IdsIconSpriteDefinition } from '@ids/react-icon';

export const WorkshopAdditionalInfo = (): Render => {
  const editing = useStateSelector((state) => state.workshop.editing);

  const Checkbox = WorkshopCheckbox(editing);
  const Field = WorkshopField(editing);
  const iconProps = { group: 'ui', size: 24, icon: 'cockade' } as IdsIconSpriteDefinition;

  return (
    <WorkshopSection title='Additional' icon={iconProps} contentClass='flex-row' anchorTag='additional'>
      <Field property='maxHeight' label='Max height (mm)' />
      <Checkbox property='isIfCertified' label='If selected workshop' />
      <Checkbox property='excludeForTowers' />
    </WorkshopSection>
  );
};
