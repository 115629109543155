import { ContractType, PriorityType } from 'api-clients/WorkshopsApi';

export const ContractPriorities: [string, [ContractType, PriorityType]][] = [];

Object.values(ContractType).forEach((contract) =>
  Object.values(PriorityType).forEach((priority) => {
    if (priority === PriorityType.NotPrioritized) {
      return;
    }
    if (contract === ContractType.NotDecided) {
      return;
    }
    //can't use format* from util, because of circular deps
    const name = ContractType[contract] + PriorityType[priority].replace('Prio', '');

    ContractPriorities.push([name, [contract, priority]]);
  })
);
ContractPriorities.push(['--', [ContractType.NotDecided, PriorityType.NotPrioritized]]);
ContractPriorities.reverse();
