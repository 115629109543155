export interface User {
  userRoles: string[];
  name?: string;
  username?: string;
}

export const adminRoles: Record<string, string> = {
  no: 'WorkshopAdminNO',
  fi: 'WorkshopAdminFI',
  se: 'WorkshopAdminSE',
};
