import { Form, Formik } from 'formik';
import React, { Dispatch, SetStateAction, useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { GeocodePrecision, ProblemDetails, ValidationProblemDetails, Workshop } from 'api-clients/WorkshopsApi';
import { AppContext, RouteParams } from 'App';
import { setWorkshop } from 'store/reducers/workshop';
import { getWorkshopsApiClient } from 'util/apiClients';
import { ErrorDisplay } from 'components/shared/ErrorDisplay';
import CodeField from './sections/CodeField';
import SubmitButton from './SubmitButton';
import { TextInputField } from './TextInputField';

interface Props {
  setCreated: Dispatch<SetStateAction<boolean>>;
  setNewWorkshopCode: Dispatch<SetStateAction<string>>;
}

const CreateWorkshopForm = ({ setCreated, setNewWorkshopCode }: Props): JSX.Element => {
  const params = useParams<RouteParams>();
  const {
    locale: { country: contextCountry },
  } = useContext(AppContext);

  const country = params.countryCode || contextCountry;

  const dispatch = useDispatch();

  const [code, setCode] = useState('');

  const [codeValid, setCodeValid] = useState(true);

  const [error, setError] = useState<ValidationProblemDetails | ProblemDetails | undefined>(undefined);

  const initialValues: Workshop = {
    id: '',
    name: '',
    countryCode: '',
    code: '',
    organizationNumber: undefined,
    phone: undefined,
    email: undefined,
    street: undefined,
    postalCode: undefined,
    city: undefined,
    latitude: undefined,
    longitude: undefined,
    geocodePrecision: GeocodePrecision.Unknown,
    isIfCertified: false,
    cabasId: undefined,
    maxHeight: undefined,
    contactPerson: undefined,
    responsiblePersonUserId: undefined,
    surveyorUserId: undefined,
    workshopServiceRatings: undefined,
    customerServices: undefined,
    dateCreated: undefined,
    dateModified: undefined,
    excludeCustomerReceptionForInternationalManufacturerCodes: undefined,
    excludeForTowers: false,
  };

  async function create(newWorkshop: Workshop) {
    newWorkshop.id = country.toUpperCase() + code;
    newWorkshop.countryCode = country.toUpperCase();
    newWorkshop.code = code;

    const client = await getWorkshopsApiClient();

    let workshop: Workshop | null = null;
    try {
      workshop = await client.create(newWorkshop);
    } catch (e: any) {
      setError(e);
    }

    if (workshop) {
      dispatch(setWorkshop(workshop));
      setNewWorkshopCode(workshop.code);
      setCreated(true);
    }
  }

  return (
    <>
      <div className='ws-form-wrapper create'>
        <Formik
          initialValues={initialValues}
          onSubmit={async (values, { setSubmitting }): Promise<void> => {
            setSubmitting(false);
            await create(values);
          }}
        >
          <Form className='if container ws-form create-container'>
            <h2 className='if heading small'>Create {country} Workshop</h2>
            <div className='if form-column'>
              <TextInputField
                id='name'
                type='text'
                name='name'
                label='Name'
                autoComplete='off'
                data-cy='newWorkshopName'
              />
              <CodeField code={code} setCode={setCode} setCodeValid={setCodeValid} />
            </div>
            <SubmitButton buttonText='Create' disabled={!codeValid} />
          </Form>
        </Formik>
      </div>
      {error && <ErrorDisplay error={error} />}
    </>
  );
};

export default CreateWorkshopForm;
