import React, { useState, MouseEvent, RefObject } from 'react';
import './Tooltip.scss';
import { Render } from 'types';

type TooltipText = string | (() => string);

function Tooltip(props: { top: number; left: number; text: string; show: boolean }): Render {
  const left = props.left + 'px';
  const top = props.top + 'px';
  if (!props.show) return null;
  return (
    <div className='if is-open popover' style={{ top, left }}>
      <span className='if popover-text' id=''>
        {props.text}
      </span>
    </div>
  );
}

export function useTooltip(
  useParent: boolean | RefObject<HTMLElement> = false,
  topOffset = 0
): { tooltipElement: Render; onTooltip: (text: TooltipText) => React.DOMAttributes<unknown> } {
  const [tooltipState, setTooltipState] = useState({
    show: false,
    top: 0,
    left: 0,
    text: '',
  });

  function showTooltip(textOrGetter: TooltipText) {
    return (e: MouseEvent) => {
      let el = e.target as Element;
      if (useParent === true) {
        el = el.parentElement!;
      }
      if (typeof useParent !== 'boolean' && useParent.current) {
        el = useParent.current;
      }
      const rect = el.getBoundingClientRect();

      const text = typeof textOrGetter === 'string' ? textOrGetter : textOrGetter();

      setTooltipState({
        show: true,
        left: rect.left,
        top: rect.top + topOffset,
        text,
      });
    };
  }

  function hideTooltip() {
    setTooltipState({ ...tooltipState, show: false });
  }

  const tooltipElement = <Tooltip {...tooltipState} />;

  function attributeMaker(text: TooltipText) {
    return { onMouseEnter: showTooltip(text), onMouseLeave: hideTooltip } as React.DOMAttributes<unknown>;
  }
  return { tooltipElement, onTooltip: attributeMaker };
}
