import React, { useEffect, useRef } from 'react';
import { useStateSelector } from 'store';
import { Render } from 'types';
import { ErrorDisplay } from '../shared';

export function WorkshopError(): Render {
  const error = useStateSelector((state) => state.workshop.error);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
    }
  }, [ref, error]);

  if (error) {
    return (
      <div ref={ref}>
        <ErrorDisplay error={error} />
      </div>
    );
  }
  return null;
}
