import React from 'react';
import { useStateSelector } from 'store';
import { Render } from 'types';
import { WorkshopField } from './shared/WorkshopField';
import { WorkshopSection } from './shared/WorkshopSection';
import { useWorkshopField } from '../../types/formik';
import { IdsIconSpriteDefinition } from '@ids/react-icon';

export function WorkshopInformation(): Render {
  const editing = useStateSelector((state) => state.workshop.editing);
  const [{ value: name }] = useWorkshopField('name');

  const Field = WorkshopField(editing);
  const iconProps = { group: 'ui', size: 24, icon: 'info' } as IdsIconSpriteDefinition;

  return (
    <WorkshopSection title='Information' icon={iconProps} contentClass='flex-row' header={name} anchorTag='information'>
      <Field property='code' readonly={true} />
      <Field property='name' />
      <Field property='cabasId' />
      <Field property='organizationNumber' />
      <Field property='phone' label='Phone number' />
      <Field property='email' />
      <Field property='glassWebAddress' />
    </WorkshopSection>
  );
}
