import React from 'react';
import { ContractPriorities, Render, SearchFilters } from 'types';

export function Explanation({ filters, count }: { filters: SearchFilters; count: number }): Render {
  const explanation: JSX.Element[] = [];
  explanation.push(<>{count} results</>);
  if (filters.minRatingRaw > 0) {
    explanation.push(
      <>
        minimum rating of <strong>{ContractPriorities[filters.minRatingRaw][0]}</strong>
      </>
    );
  }
  if (filters.maxRatingRaw < ContractPriorities.length - 1) {
    explanation.push(
      <>
        maximum rating of <strong>{ContractPriorities[filters.maxRatingRaw][0]}</strong>
      </>
    );
  }
  // if (filters.workshopServiceTypes.length) {
  //   explanation.push(
  //     <>
  //       <strong>{filters.workshopServiceTypes.join(', ')}</strong> service
  //     </>
  //   );
  // }
  // if (filters.manufacturers.length) {
  //   explanation.push(
  //     <>
  //       <strong>{filters.manufacturers.map((m) => m.name).join(', ')}</strong> manufacturer
  //     </>
  //   );
  // }
  if (filters.terms.length) {
    explanation.push(
      <>
        <strong>{filters.terms.join(' ')}</strong> in code, name or address
      </>
    );
  }
  if (filters.badGeocodePrecision) explanation.push(<>only with bad geocode precision</>);
  if (filters.hasAddress) explanation.push(<>only that have an address</>);
  if (filters.mixedRatings) explanation.push(<>has [All manufacturers] and manufacturer ratings</>);

  return (
    <div className='explanation'>
      {explanation.map((x, i) => (
        <span key={i} data-cy='resultCount'>
          {x}
        </span>
      ))}
    </div>
  );
}
