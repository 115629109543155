import React from 'react';
import { Render, WorkshopFormState } from 'types';
import { useFormField } from 'types/formik';
import { useAppDispatch, useStateSelector } from 'store';
import { setSaving, updateWorkshop } from 'store/reducers/workshop';
import { Button } from 'components/shared';
import { Field, useFormikContext } from 'formik';
import { SaveSummary } from './SaveSummary';

export function SaveDialog(): Render {
  const date = new Date();

  const today =
    date.getUTCFullYear().toString().padStart(4, '0') +
    '-' +
    date.getUTCMonth().toString().padStart(2, '0') +
    '-' +
    date.getUTCDate().toString().padStart(2, '0');

  const signedUser = useStateSelector((state) => state.user);

  const dispatch = useAppDispatch();
  const show = useStateSelector((state) => state.workshop.saving);
  const [{ value: userDescription }] = useFormField('userDescription');
  const formik = useFormikContext<WorkshopFormState>();
  if (!show) return null;

  const values: WorkshopFormState = JSON.parse(JSON.stringify(formik.values));

  return (
    <div className='pop-up-bg'>
      <div className='pop-up'>
        <div className='pop-up-content'>
          <h2 className='if heading small'>Save changes</h2>
          <div className='signed-user'>
            <div className='flex-column'>
              <div className='if label'>{signedUser.name}</div>
              <div>{signedUser.username}</div>
            </div>
            <div>{today}</div>
          </div>
          <div className='if form-group full-width'>
            <label className='if label mb-16' htmlFor='userDescription'>
              User description
            </label>
            <Field
              id='userDescription'
              name='userDescription'
              className='if input-field textarea'
              label='User description'
              component='textarea'
              placeholder='A mandatory reason for changes'
            />
          </div>
          <SaveSummary />
          <div className='bottom-buttons-wrapper'>
            <Button
              disabled={!userDescription}
              text='Save'
              cy='save'
              onClick={(e) => {
                e.preventDefault();
                dispatch(updateWorkshop(values));
              }}
            />
            <Button text='Cancel' cy='cancelSave' type='outlined' onClick={() => dispatch(setSaving(false))} />
          </div>
        </div>
      </div>
    </div>
  );
}
