import React from 'react';

interface Props {
  mainText?: string;
  linkUrl?: string;
  linkText?: string;
  show: boolean;
}

const InputError = ({ mainText, linkUrl, linkText, show }: Props): JSX.Element => {
  return show ? (
    <div className='if form-group input-error'>
      {mainText}
      <a href={linkUrl}>{linkText}</a>
    </div>
  ) : (
    <></>
  );
};

export default InputError;
