import {
  ContactPerson,
  ContractType,
  CustomerService,
  CustomerServiceType,
  GeocodePrecision,
  ManufacturerRating,
  PriorityType,
  Workshop,
  WorkshopServiceRating,
  WorkshopServiceType,
} from 'types';
import {
  array,
  boolean,
  mixed,
  number,
  object,
  SchemaOf,
  string as yupString,
  date as yupDate,
  StringSchema,
  ValidationError,
} from 'yup';

// https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address
// except a-zA-Z replaced with \p{Letter} (i.e. support utf8)
const rxEmail =
  // eslint-disable-next-line
  "[\\p{Letter}0-9.!#$%&'*+\\/=?^_`{|}~-]+@[\\p{Letter}0-9](?:[\\p{Letter}0-9-]{0,61}[\\p{Letter}0-9])?(?:\\.[\\p{Letter}0-9](?:[\\p{Letter}0-9-]{0,61}[\\p{Letter}0-9])?)*";
const multipleEmails = new RegExp(`^${rxEmail}(\\s*;\\s*${rxEmail})*$`, 'u');
const singleEmail = new RegExp(rxEmail, 'u');

export const messages = {
  required: 'Required',
  invalidFormat: 'Invalid format',
  tooShort: 'Too short',
  tooLong: 'Too long',
  notSet: '${path} is not set',
  validEmail: '"${value}" is not a valid email',
  digitsOnly: '${path} must contain digits only',
};

// __outputType = string | null | undefined
function string(min: number | null = null, max: number | null = null, matches: null | RegExp = null) {
  let schema = yupString().nullable();

  if (min) schema = schema.min(min, messages.tooShort);
  if (max) schema = schema.max(max, messages.tooLong);

  if (matches) schema = schema.matches(matches);

  return schema as StringSchema<string | undefined>;
}

// __outputType = string
function requiredString(min: number | null = null, max: number | null = null, matches: null | RegExp = null) {
  let schema = yupString().required(messages.required).defined();

  if (min) schema = schema.min(min, messages.tooShort);
  if (max) schema = schema.max(max, messages.tooLong);

  if (matches) schema = schema.matches(matches);

  return schema;
}

const workshopServiceTypeSchema = mixed<WorkshopServiceType>()
  .oneOf(Object.values(WorkshopServiceType))
  .required(messages.required);

const customerServiceTypeSchema = mixed<CustomerServiceType>().oneOf(
  Object.values(CustomerServiceType).filter((t) => t != CustomerServiceType.Unspecified)
);
const contractTypeSchema = mixed<ContractType>().oneOf(Object.values(ContractType));
const priorityTypeSchema = mixed<PriorityType>().oneOf(Object.values(PriorityType));
const validContractType = mixed<ContractType>()
  .oneOf(Object.values(ContractType).filter((t) => t != ContractType.NotDecided))
  .required(messages.required);
const validPriorityType = mixed<PriorityType>()
  .oneOf(Object.values(PriorityType).filter((t) => t != PriorityType.NotPrioritized))
  .required(messages.required);

const contactPersonSchema: SchemaOf<Record<keyof ContactPerson, unknown>> = object({
  id: string(),
  name: string(1, 200),
  email: string(3, 256).matches(singleEmail, messages.validEmail),
  mobileNumber: string(null, 30),
  phoneNumber: string(null, 30),
});

const manufacturerServiceRatingSchema: SchemaOf<Record<keyof ManufacturerRating, unknown>> = object({
  id: string(),
  name: requiredString(1, 200),
  code: string(1, 6, /^([a-öA-Ö][0-9a-öA-Ö\-#*\/?+][a-öA-Ö]?)|(\d+)$/),
  internationalManufacturerCode: string(1, 256, /^[a-z]+$/),
  contractType: validContractType,
  priorityType: validPriorityType,
  steeringCampaign: boolean().required(messages.required),
});

const workshopServiceRatingSchema: SchemaOf<Record<keyof WorkshopServiceRating, unknown>> = object({
  id: string(),
  serviceType: workshopServiceTypeSchema.required(messages.required),
  allManufacturers: boolean().required(messages.required),
  contractType: contractTypeSchema.when('allManufacturers', {
    is: true,
    then: () => validContractType,
  }),
  priorityType: priorityTypeSchema.when('allManufacturers', {
    is: true,
    then: () => validPriorityType,
  }),
  manufacturerRatings: array().of(manufacturerServiceRatingSchema),
});

try {
  workshopServiceRatingSchema.validateSync(
    {
      serviceType: WorkshopServiceType.Body,
      allManufacturers: true,
      priorityType: PriorityType.NotPrioritized,
      contractType: ContractType.NotDecided,
    } as WorkshopServiceRating,
    { abortEarly: false, strict: false }
  );
  console.error('this should not pass');
} catch (e) {
  if ((e as ValidationError).errors.length != 2) {
    console.error('there should be two errors');
  }
}

const customerServicesSchema: SchemaOf<Record<keyof CustomerService, any>> = object({
  id: string(),
  serviceType: customerServiceTypeSchema.required(messages.required),
  rentalCarBrand: string().when('serviceType', {
    is: isRentalCarService,
    then: requiredString(3, 200),
    otherwise: string().nullable(),
  }),
  email: string()
    .nullable()
    .when({
      is: (val: string) => !!val,
      then: (schema) => schema.matches(multipleEmails, messages.validEmail),
    })
    .when('serviceType', {
      is: isEmailRequiredService,
      then: (schema) => schema.required('Email required'),
    }),
  externalId: string().when('serviceType', {
    is: CustomerServiceType.PreInspection,
    then: requiredString(0, 256),
    otherwise: string().nullable(),
  }),
  disabledStartDate: yupDate().nullable(),
  disabledEndDate: yupDate()
    .nullable()
    .test('', 'End date must be after the start date', function (endDate) {
      const { disabledStartDate } = this.parent;
      if (!disabledStartDate || !endDate) {
        return true;
      }
      return new Date(endDate) > new Date(disabledStartDate);
    }),
});

export const WorkshopSchema: SchemaOf<Record<keyof Workshop, unknown>> = object({
  id: string(),
  name: requiredString(2, 200).matches(
    /^[\p{Letter} 0-9&\/()\-,.:!+">'_]+$/u,
    '"${value}" is not a valid workshop name'
  ),
  countryCode: requiredString(2, 2),
  code: requiredString(4, 7),
  organizationNumber: string()
    .when('countryCode', {
      is: 'SE',
      //SE 123456-7890
      then: string(10, 11).matches(/^\d{6}-?\d{4}$/),
    })
    .when('countryCode', {
      is: 'FI',
      //FI 1234567-8
      then: string(8, 9).matches(/^\d{7}-?\d$/),
    })
    .when('countryCode', {
      is: 'NO',
      //NO 123 456 789
      then: string(9, 11).matches(/^\d{3} ?\d{3} ?\d{3}$/),
    }),
  phone: string(0, 30),
  email: string(3, 256).matches(singleEmail, messages.validEmail),
  street: string(0, 50),
  postalCode: string(4, 6, /^[0-9]{3}\s?[0-9]{1,3}$/),
  city: string(0, 50),
  latitude: number(),
  longitude: number(),
  geocodePrecision: mixed<GeocodePrecision>().oneOf(Object.values(GeocodePrecision)).required(messages.required),
  isIfCertified: boolean().required(messages.required),
  cabasId: string(8, 15),
  maxHeight: number().integer().nullable(),
  contactPerson: contactPersonSchema.nullable(),
  responsiblePersonUserId: string(2, 256),
  surveyorUserId: string(2, 256),
  workshopServiceRatings: array().of(workshopServiceRatingSchema),
  customerServices: array().of(customerServicesSchema),
  dateCreated: string(),
  dateModified: string(),
  excludeCustomerReceptionForManufacturerCodes: array().of(string()),
  excludeCustomerReceptionForInternationalManufacturerCodes: array().of(string()),
  excludeForTowers: boolean().required(messages.required),
  glassWebAddress: string(),
  groupEmail: string(),
});

export const formSchema = object({
  workshop: WorkshopSchema,
  lastAddressGeocoded: object(),
  userDescription: requiredString(),
});

export function validateWorkshop(workshop: Workshop): string[] | undefined {
  function filterError(err: string) {
    if (err.includes('priorityType must be one')) return 'has invalid rating priorities';
    if (err.includes('contractType must be one')) return 'has invalid rating contracts';
    return err;
  }
  try {
    WorkshopSchema.validateSync(workshop, { abortEarly: false, strict: false });
  } catch (e) {
    const filtered = (e as ValidationError).errors.map(filterError);
    return [...new Set(filtered)];
  }
}

export function validateWorkshops(workshops: Workshop[]): Record<string, string[]> {
  const results: Record<string, string[]> = {};

  for (const workshop of workshops) {
    const errors = validateWorkshop(workshop);
    if (errors) results[workshop.id] = errors;
  }

  return results;
}

function isRentalCarService(customerServiceType: CustomerServiceType) {
  return [
    CustomerServiceType.RentalCarCentral,
    CustomerServiceType.RentalCarLocal,
    CustomerServiceType.RentalCarOwner,
  ].includes(customerServiceType);
}

function isEmailRequiredService(customerServiceType: CustomerServiceType) {
  return [
    CustomerServiceType.PhotoInspection,
    CustomerServiceType.WorkshopLetter,
    CustomerServiceType.GlassPhotoInspection,
  ].includes(customerServiceType);
}
