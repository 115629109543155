import { Configuration, LogLevel, PublicClientApplication, SilentRequest } from '@azure/msal-browser';
import { environment } from './environment';

const msalConfig: Configuration = {
  auth: {
    authority: environment.authority,
    clientId: environment.clientId,
    redirectUri: environment.redirectUrl,
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: 'localStorage',
  },
};

export const msal = new PublicClientApplication(msalConfig);
(window as any).msal = msal;

export const defaultRequest: SilentRequest = {
  scopes: ['User.Read', environment.authScope],
};

export function parseRoles(accessToken: string) {
  return JSON.parse(atob(accessToken.split('.')[1])).roles ?? [];
}

export async function getScopeAccessToken(scope: string): Promise<string> {
  const accounts = msal.getAllAccounts();
  const account = accounts[0];
  const tokenResponse = await msal.acquireTokenSilent({
    scopes: [scope],
    account,
  });
  return tokenResponse.accessToken;
}

export async function getADAccessToken(): Promise<string> {
  return getScopeAccessToken('User.Read');
}
