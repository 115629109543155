import React from 'react';
import { Render } from 'types';
import { AdminContentWrapper } from '../AdminContent';
import { useAppDispatch, useStateSelector } from 'store';
import { deleteWorkshop } from 'store/reducers/workshop';
import { Button, useConfirmModal } from 'components/shared';
import { IdsIconSpriteDefinition } from '@ids/react-icon';

const _DeleteButton = (): Render => {
  const id = useStateSelector((state) => state.workshop.workshop!.id);
  const dispatch = useAppDispatch();
  const { confirmElement, confirm } = useConfirmModal();
  const iconProps = { group: 'ui', size: 16, icon: 'delete' } as IdsIconSpriteDefinition;

  return (
    <>
      {confirmElement}
      <Button
        type='filled red'
        text='Delete'
        cy='delete'
        icon={iconProps}
        onClick={(e) => {
          e.preventDefault();
          confirm(
            'Delete workshop',
            'Are you sure you want to delete this workshop? This action can not be undone.',
            () => {
              dispatch(deleteWorkshop({ workshopId: id, redirectUrl: '/' }));
            }
          );
        }}
      />
    </>
  );
};

export const DeleteButton = AdminContentWrapper(_DeleteButton);
