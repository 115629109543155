import React, { useState } from 'react';
import { Render, StringOrGetter } from 'types';
import { getText } from 'util/index';
import './Confirm.scss';

function ConfirmModal(props: {
  title: string;
  question: string;
  show?: boolean;
  okCallback: () => void;
  cancel: () => void;
}): Render {
  if (!props.show) return null;
  return (
    <>
      <div className='if backdrop is-open' />
      <div className='if modal'>
        <div className='if title'>{props.title}</div>
        <div className='if content'>
          <p className='if'>{props.question}</p>
        </div>
        <div className='if footer'>
          <button
            data-cy='confirmModalButtonYes'
            type='button'
            className='if button filled'
            onClick={() => {
              props.okCallback();
            }}
          >
            Yes
          </button>
          <button
            type='button'
            className='if button outlined'
            onClick={() => props.cancel()}
            data-cy='cancelModalButton'
          >
            Cancel
          </button>
        </div>
      </div>
    </>
  );
}

export function useConfirmModal(): {
  confirmElement: Render;
  confirm: (titleText: StringOrGetter, questionText: StringOrGetter, okCallback: () => void) => void;
} {
  const [confirmState, setConfirmState] = useState({
    show: false,
    title: '',
    question: '',
    okCallback: () => {
      /**/
    },
  });

  function confirm(titleText: StringOrGetter, questionText: StringOrGetter, okCallback: () => void) {
    setConfirmState({
      show: true,
      title: getText(titleText),
      question: getText(questionText),
      okCallback: () => {
        setConfirmState((s) => ({ ...s, show: false }));
        okCallback();
      },
    });
  }

  const confirmElement = (
    <ConfirmModal {...confirmState} cancel={() => setConfirmState({ ...confirmState, show: false })} />
  );

  return { confirmElement, confirm };
}
