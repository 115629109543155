import { AuthClient as VehicleInfoAuthClient, VehicleInfoApiClient } from 'api-clients/VehicleInfoApi';
import { AuthClient, WorkshopsApiClient } from 'api-clients/WorkshopsApi';
import { environment } from './environment';
import { getScopeAccessToken } from './auth';
import { WorkshopLocatorApiClient, AuthClient as WorkshopLocatorAuthClient } from 'api-clients/WorkshopLocatorApi';

export async function getWorkshopsApiClient(): Promise<WorkshopsApiClient> {
  return new WorkshopsApiClient(new AuthClient(await getScopeAccessToken(environment.authScope)), environment.wsApiUrl);
}

export async function getVehicleInfoApiClient(): Promise<VehicleInfoApiClient> {
  return new VehicleInfoApiClient(
    new VehicleInfoAuthClient(await getScopeAccessToken(environment.vehicleInfoApiScope)),
    environment.vehicleInfoApiUrl
  );
}

export async function getWorkshopLocatorApiClient(): Promise<WorkshopLocatorApiClient> {
  return new WorkshopLocatorApiClient(
    new WorkshopLocatorAuthClient(await getScopeAccessToken(environment.workshopLocatorApiScope)),
    environment.workshopLocatorApiUrl
  );
}
