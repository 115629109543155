import React from 'react';

interface Props {
  buttonText: string;
  disabled: boolean;
}
const SubmitButton = ({ buttonText, disabled }: Props): JSX.Element => (
  <button disabled={disabled} data-cy='submitButton' className='if button filled create-ws-btn' type='submit'>
    {buttonText}
  </button>
);

export default SubmitButton;
