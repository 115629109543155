import React, { useRef } from 'react';
import './ServiceRatings.scss';
import { ContractType, PriorityType, Render, WorkshopServiceRating, WorkshopServiceType } from 'types';
import { useWorkshopField } from 'types/formik';
import { formatServiceType } from 'util/index';
import { useStateSelector } from 'store';
import { ServiceRating } from './ServiceRating';
import { WorkshopSection } from '../shared/WorkshopSection';
import { Button } from 'components/shared';
import { IdsIconSpriteDefinition } from '@ids/react-icon';

export function ServiceRatings(props: { ratings?: WorkshopServiceRating[] }): Render {
  const editing = useStateSelector((state) => state.workshop.editing);
  const [{ value }] = useWorkshopField('workshopServiceRatings');
  const ratings = props.ratings ?? value ?? [];

  const ratingsWithOriginalIndex: [WorkshopServiceRating, number][] = ratings.map((x, i) => [x, i]);

  const iconProps = { group: 'ui', size: 24, icon: 'recipe' } as IdsIconSpriteDefinition;

  ratingsWithOriginalIndex.sort(([{ serviceType: a }], [{ serviceType: b }]) => CompareWorkshopServiceType(a, b));

  return (
    <WorkshopSection
      title='Workshop Services'
      icon={iconProps}
      contentClass='service-ratings'
      anchorTag='workshop-services'
    >
      {ratingsWithOriginalIndex.map(([, index]) => (
        <ServiceRating index={index} key={index} rating={props.ratings?.[index]} />
      ))}

      {ratings.length === 0 && <span className='no-content-text'>No workshop services</span>}
      {editing && <AddServiceRating />}
    </WorkshopSection>
  );
}

const AddServiceRating = () => {
  const refServiceType = useRef<HTMLSelectElement>(null);
  const [{ value }, , { setValue: setRatings, setTouched }] = useWorkshopField('workshopServiceRatings');
  const ratings = value ?? [];
  const iconProps = { group: 'ui', size: 16, icon: 'plus' } as IdsIconSpriteDefinition;

  function typeIsNotUsed(type: WorkshopServiceType) {
    return !ratings.find((r) => r.serviceType === type);
  }

  function addServiceType() {
    if (!refServiceType.current) return;
    setTouched(true);
    const serviceType = refServiceType.current.value as WorkshopServiceType;
    setRatings([
      ...ratings,
      {
        serviceType,
        contractType: ContractType.NotDecided,
        priorityType: PriorityType.NotPrioritized,
        id: undefined as any,
        manufacturerRatings: [],
        allManufacturers: false,
      },
    ]);
  }

  // if all service types are used, don't show the component at all
  if (!Object.values(WorkshopServiceType).filter(typeIsNotUsed).length) {
    return null;
  }

  return (
    <div className='add-service' data-cy='addWorkshopService'>
      <h3 className='if heading small'>Add</h3>
      <select
        className='if select single-select type-select dropdown-select'
        ref={refServiceType}
        data-cy='selectWorkshopServiceType'
      >
        {Object.keys(CategorizedWorkshopServiceTypes).map((category) => {
          //if all types in a category are hidden, don't show the category
          if (CategorizedWorkshopServiceTypes[category].every((service) => !typeIsNotUsed(service))) {
            return null;
          }
          return (
            <optgroup label={category} key={category}>
              {CategorizedWorkshopServiceTypes[category].map((serviceType, i) => {
                //if service is already used, don't show it
                if (!typeIsNotUsed(serviceType)) {
                  return null;
                }
                return (
                  <option value={serviceType} key={i}>
                    {formatServiceType(serviceType)}
                  </option>
                );
              })}
            </optgroup>
          );
        })}
      </select>
      <span data-cy-only-label='selectWorkshopServiceType' />
      <Button text='Add' cy='add' icon={iconProps} onClick={() => addServiceType()} type='text add-service-button' />
    </div>
  );
};

export const CategorizedWorkshopServiceTypes: Record<string, WorkshopServiceType[]> = {
  Car: [
    WorkshopServiceType.Body,
    WorkshopServiceType.SmallDentRepair,
    WorkshopServiceType.Glass,
    WorkshopServiceType.Service,
    WorkshopServiceType.CashPayment,
    WorkshopServiceType.VintageVehicle,
    WorkshopServiceType.ElectricVehicle,
    WorkshopServiceType.Sanitation,
  ],
  // prettier-ignore
  Motorcycle: [
    WorkshopServiceType.Mc,
    WorkshopServiceType.Moped,
    WorkshopServiceType.MopedCar,
    WorkshopServiceType.MopedCarService,
  ],
  // prettier-ignore
  ATV: [
    WorkshopServiceType.Atv,
    WorkshopServiceType.Snowmobile,
  ],
  // prettier-ignore
  Camper: [
    WorkshopServiceType.Camper,
    WorkshopServiceType.Caravan,
    WorkshopServiceType.Trailer,
  ],
  Heavy: [
    WorkshopServiceType.HeavyTrailer,
    WorkshopServiceType.TruckBody,
    WorkshopServiceType.TruckService,
    WorkshopServiceType.BusBody,
    WorkshopServiceType.BusService,
    WorkshopServiceType.Construction,
    WorkshopServiceType.Agricultural,
    WorkshopServiceType.Forestry,
    WorkshopServiceType.HeavyGlass,
    WorkshopServiceType.AuxiliaryEquipment,
  ],
};

export function CompareWorkshopServiceType(a: WorkshopServiceType, b: WorkshopServiceType): number {
  const order = [
    //cars/lorries
    WorkshopServiceType.Body,
    WorkshopServiceType.SmallDentRepair,
    WorkshopServiceType.Glass,
    WorkshopServiceType.Service,
    WorkshopServiceType.CashPayment,
    WorkshopServiceType.VintageVehicle,
    WorkshopServiceType.ElectricVehicle,
    //Motorcycles
    WorkshopServiceType.Mc,
    WorkshopServiceType.Moped,
    WorkshopServiceType.MopedCar,
    WorkshopServiceType.MopedCarService,
    //ATVs
    WorkshopServiceType.Atv,
    WorkshopServiceType.Snowmobile,
    //Campers
    WorkshopServiceType.Camper,
    WorkshopServiceType.Caravan,
    WorkshopServiceType.Trailer,
    //Heavy
    WorkshopServiceType.HeavyTrailer,
    WorkshopServiceType.TruckBody,
    WorkshopServiceType.TruckService,
    WorkshopServiceType.BusBody,
    WorkshopServiceType.BusService,
    WorkshopServiceType.Construction,
    WorkshopServiceType.Agricultural,
    WorkshopServiceType.Forestry,
    WorkshopServiceType.HeavyGlass,
    WorkshopServiceType.AuxiliaryEquipment,
  ];
  if (order.indexOf(a) == -1) console.error(a + ' does not have a defined order');
  if (order.indexOf(b) == -1) console.error(b + ' does not have a defined order');
  return order.indexOf(a) - order.indexOf(b);
}
