import { Form, Formik } from 'formik';
import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import './WorkshopView.scss';
import { AppContext, RouteParams } from 'App';
import { useAppDispatch, useStateSelector } from 'store';
import { loadWorkshop } from 'store/reducers/workshop';

import { Render, WorkshopHandler, WorkshopState } from 'types';
import { perfMetrics } from 'types/perfMetrics';
import Loader from 'components/UI/Loader';
import {
  DeleteButton,
  DirtyPrompt,
  EditButtons,
  SaveDialog,
  ServiceRatings,
  WorkshopAdditionalInfo,
  WorkshopAddress,
  WorkshopContactPerson,
  WorkshopCustomerServices,
  WorkshopIfContacts,
  WorkshopInformation,
} from 'components/workshop';
import { WorkshopError } from '../workshop/WorkshopError';
import { formSchema, validateWorkshop } from '../../types/workshopValidationSchema';
import { ErrorDisplay } from '../shared';
import { HashNavigator } from 'components/shared/HashNavigator';

export const WorkshopView = (): Render => {
  const params = useParams<RouteParams>();
  const workshopId = (params.countryCode + params.workshopCode).toUpperCase();

  const context = useContext(AppContext);

  const dispatch = useAppDispatch();

  const workshopState: WorkshopState = useStateSelector((state) => state.workshop);
  const workshop = workshopState.workshop;

  useEffect(() => {
    if (workshopState.loading) {
      return;
    }
    dispatch(loadWorkshop(workshopId));
  }, [workshopId]);

  if (!workshopState.loading && workshopState.error) {
    return <ErrorDisplay error={workshopState.error} />;
  }

  if (workshopState.loading || workshop?.id !== workshopId) {
    return (
      <div className='if container workshop'>
        <Loader dotColor='#0054f0' loaderText={`Loading workshop ${workshopId}...`} style={{ margin: '0 2em' }} />
      </div>
    );
  }

  context.setTitle(`${workshop.id} ${workshop.name}`);
  perfMetrics.workshop = perfMetrics.workshop ?? new Date();

  const errors = validateWorkshop(workshop);
  if (errors) console.debug('workshop has errors:', errors);

  return (
    <div className='if container workshop'>
      <WorkshopError />
      <span data-cy='editing' data-cy-value={workshopState.editing} />
      <Formik
        key={workshopId + workshopState.key}
        initialValues={{
          userDescription: '',
          workshop: workshop,
          lastAddressGeocoded: WorkshopHandler.addressOf(workshop),
        }}
        validationSchema={formSchema}
        onSubmit={() => {
          void 0;
        }}
      >
        <Form className='if container' autoComplete='chrome-off'>
          <DirtyPrompt />
          <div className='screen-width-container'>
            <div className='flex-row'>
              <div className='left-page-container'>
                <WorkshopInformation />
                <WorkshopAddress />
                <WorkshopContactPerson />
                <WorkshopIfContacts />
                <WorkshopAdditionalInfo />
                <ServiceRatings />
                <WorkshopCustomerServices />
                <div className='if section'>
                  <br />
                  <br />
                  <br />
                  <div className='if section-content'>
                    <div className='if grid no-gap'>
                      <div className='if col-1 md-col-2 lg-col-2'></div>
                      <div className='if col-2 md-col-8 lg-col-8'>
                        <DeleteButton />
                      </div>
                      <div className='if col-1 md-col-2 lg-col-2'></div>
                    </div>
                  </div>
                </div>
              </div>
              <EditButtons />
              <SaveDialog />
            </div>
          </div>
        </Form>
      </Formik>
      <HashNavigator />
    </div>
  );
};
